/* eslint-disable id-length */
/**
 * 테넌트별 Report URL 및 확장필드 프로퍼티 값이 다르게 생성되어
 * 클라이언트에서 맾핑 시켜주기위해 사용되는 모듈입니다.
 * 추가되는 확장필드는 extProps 오브젝트 안에 추가 해주시기 바랍니다.
 * @param {*} pid ByDesign Tenant ID
 */

const mappingTable = {
  my341545: {
    reports: {
      bsg_identified_stock: {
        url: "RPZDB65037A81573B5F8D1B9BQueryResults"
      },
      bsg_logistics_area_resources_information: {
        url: "RPZB89D7A4B99239F5E34FB33QueryResults",
        extProps: {
          extLogisticsArea: "C1IJ3DCD1AEYNSNSBVE2LB7YPFW" // 공급물류영역
        }
      },
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0003QueryResults",
        extProps: {
          shipToLocationID: "C1HND0TUU2QV0ZYYB8AJKA4MAPI" // 납품처 ID
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          description: "C1QRLPUKR3T15SREWJ01P3WE47L", // 자재 내역 풀네임,
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB", // 동종재고 생산일
          location: "C1R8WUF8PJQKJQMH04TFD551TX2", // 자재 location
          dimension: "C1R615Y8VRL3VZG6GNI1GOHL1RK", // 자재 규격
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF", // 동종재고 만료일
          salesOrderID: "C1M990BCP04URJAG5CIX9S001F0" // 동종재고 판매오더 ID
        }
      },
      sap_confirmation_journal_report: {
        url: "RPSCMCFJU01_Q0001QueryResults",
        extProps: {
          fullName: "C1QRLPUKR3T15SREWJ01P3WE47L",
          dimension: "C1R615Y8VRL3VZG6GNI1GOHL1RK",
          location: "C1R8WUF8PJQKJQMH04TFD551TX2",
          salesOrderID: "Cs1ANs5A99DDBA14C2E5A",
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB",
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF"
        }
      },
      bsg_salesorder_monitoring_report: {
        url: "RPZD881F2F7179C34BA2A2923QueryResults"
      },
      bsg_custom_inbound_delivery_notification_report: {
        url: "RPZ7F8849F0BEA0D51BFE5F07QueryResults",
        extProps: {
          contractStatus: "Cs1ANsB6E30415B2819E5", // 유상여부
          constractStatusText: "Ts1ANsB6E30415B2819E5", // 유상여부 텍스트,
          itemOrderDate: "C1PO_ITM_UUIDsITM_DLV_STDT",
          // 주문일  Document References > PO Item ID > Delivery Date From (Select)
          itemObjectID: "CDEL_ITEM_UUID_01",
          deliverSite: "C1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          deliverSiteText: "T1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          supplier: "T1PO_ITM_UUIDsSELLER",
          // Document References > PO Item ID > Supplier (2개중 두번째것 사용)
          supplierID: "C1PO_ITM_UUIDsSELLER"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ073DB6746017E98DD98757QueryResults",
        extProps: {}
      },
      bsg_material_supply_and_demand_report: {
        url: "RPZA86759C9FE75E257970A53QueryResults",
        extProps: {}
      },
      bsg_purchaseorder_monitoring_report: {
        url: "RPZD3FB9471703A31C0623928QueryResults",
        extProps: {
          confirmStatus: "Cs1ANs01C783386A4C1E9", // 수락여부ID
          confirmStatusText: "Ts1ANs01C783386A4C1E9", // 수락여부 텍스트
          contractStatus: "Cs1ANsB6E30415B2819E5", // 유상여부
          constractStatusText: "Ts1ANsB6E30415B2819E5", // 유상여부 텍스트,
          identifiedStockType: "C1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 (Report 에서 추가한 필드)
          identifiedStockTypeText: "T1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 텍스트 (Report 에서 추가한 필드)
          ItemproductStandard: "Cs1ANs94FB3E94D81C1C4", // 제조사
          Itemmanufacturer: "Cs1ANsAD9899FA55D1259", // 규격
          Itemsupplier: "Cs1ANsAF40D0443B5C51C", // 공급사
          salesOrderID: "Cs1ANsFDB5DF096A5407A" // 판매오더 ID
        }
      },
      bsg_productionorder_bom: {
        url: "RPZ13CCA74AFC6026912A6924QueryResults",
        extProps: {
          toleranceMin: "Cs1ANsDB58175980AEC0F",
          toleranceMax: "Cs1ANsD1E9E5689ECE353"
        }
      },
      bsg_productionorder: {
        url: "bsg_productionorder",
        extProps: {
          tempSave: "Weighting_KUT",
          salesOrderID: "SOID_01_KUT"
        }
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest",
        extProps: {
          salesOrderID: "SOID_02_KUT",
          productionOrderID: "PRODUCT_ORDER_KUT"
        }
      }
    }
  },
  my348037: {
    reports: {
      bsg_identified_stock: {
        url: "RPZ36E66338521C77B590DC53QueryResults"
      },
      bsg_logistics_area_resources_information: {
        url: "RPZ42A2F52A18DB4BE898D0D5QueryResults",
        extProps: {
          extLogisticsArea: "Cs1ANsF27196D3301F129" // 공급물류영역
        }
      },
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0003QueryResults",
        extProps: {
          shipToLocationID: "C1HND0TUU2QV0ZYYB8AJKA4MAPI" // 납품처 ID
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          description: "C1PUM32FIIWRGVQAF4BD5HUK4HA", // 자재 내역 풀네임,
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB", // 동종재고 생산일
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR", // 자재 Location
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N", // 자재 규격
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF", // 동종재고 만료일
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2" // 동종재고 판매오더 ID
        }
      },
      sap_confirmation_journal_report: {
        url: "RPSCMCFJU01_Q0001QueryResults",
        extProps: {
          fullName: "C1PUM32FIIWRGVQAF4BD5HUK4HA",
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N",
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR",
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2",
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB",
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF",
          notInspection: "C1N6061KGKRRDY27JVXB8IBB48I"
        }
      },

      bsg_salesorder_monitoring_report: {
        url: "RPZC61B702804869659902E41QueryResults"
      },
      bsg_custom_inbound_delivery_notification_report: {
        url: "RPZDA454B1F5E1730BACADE98QueryResults",
        extProps: {
          contractStatus: "Cs1ANsFCDAFF9494A29AF", // 유상여부
          constractStatusText: "Ts1ANsFCDAFF9494A29AF", // 유상여부 텍스트,
          itemOrderDate: "C1PO_ITM_UUIDsITM_DLV_STDT",
          // 주문일  Document References > PO Item ID > Delivery Date From (Select)
          itemObjectID: "CDEL_ITEM_UUID",
          deliverSite: "C1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          deliverSiteText: "T1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          supplier: "T1PO_ITM_UUIDsSELLER",
          // Document References > PO Item ID > Supplier (2개중 두번째것 사용)
          supplierID: "C1PO_ITM_UUIDsSELLER",
          fullName: "C1RZ6I6GEXMQXQFKKCU7HO4D9TC"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ5658043746D8DCE1C4FE7EQueryResults",
        extProps: {
          deliveryClose: "Cs1ANsB90CDEA3661B110" // 납품종료
        }
      },
      bsg_material_supply_and_demand_report: {
        url: "RPZF91725D6F76A2127D715AFQueryResults",
        extProps: {}
      },
      bsg_purchaseorder_monitoring_report: {
        url: "RPZFBB137CCE65EE5DA41CD5BQueryResults",
        extProps: {
          confirmStatus: "Cs1ANsEC74DE6DA8A3250", // 수락여부ID
          confirmStatusText: "Ts1ANsEC74DE6DA8A3250", // 수락여부 텍스트
          contractStatus: "Cs1ANs7BF18A7B5625345", // 유상여부
          constractStatusText: "Ts1ANs7BF18A7B5625345", // 유상여부 텍스트,
          identifiedStockType: "C1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 (Report 에서 추가한 필드)
          identifiedStockTypeText: "T1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 텍스트 (Report 에서 추가한 필드)
          ItemproductStandard: "Cs1ANs7A1F81DA457C79C", // 제조사
          Itemmanufacturer: "Cs1ANsB50C53DF3706127", // 규격
          Itemsupplier: "Cs1ANs05ACC7AC76F84A9", // 공급사
          salesOrderID: "Cs1ANs270FD41046F1D9D", // 판매오더 ID
          fullName: "C1RZ6I6GEXMQXQFKKCU7HO4D9TC"
        }
      },
      bsg_productionorder_bom: {
        url: "RPZ521EEBD6109E5B7A100920QueryResults",
        extProps: {
          toleranceMin: "Cs1ANs4EF9227FF0E6ADE",
          toleranceMax: "Cs1ANs9CC96211129527B"
        }
      },
      bsg_productionorder: {
        url: "bsg_productionorder",
        extProps: {
          tempSave: "Weighting_KUT",
          salesOrderID: "SOID_01_KUT"
        }
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest",
        extProps: {
          salesOrderID: "SOID_02_KUT",
          productionOrderID: "PRODUCT_ORDER_KUT"
        }
      }
    }
  },
  my349266: {
    reports: {
      bsg_identified_stock: {
        url: "RPZ36E66338521C77B590DC53QueryResults"
      },
      bsg_logistics_area_resources_information: {
        url: "RPZ42A2F52A18DB4BE898D0D5QueryResults",
        extProps: {
          extLogisticsArea: "Cs1ANsF27196D3301F129" // 공급물류영역
        }
      },
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0003QueryResults",
        extProps: {
          shipToLocationID: "C1HND0TUU2QV0ZYYB8AJKA4MAPI" // 납품처 ID
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          description: "C1PUM32FIIWRGVQAF4BD5HUK4HA", // 자재 내역 풀네임,
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB", // 동종재고 생산일
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR", // 자재 Location
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N", // 자재 규격
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF", // 동종재고 만료일
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2" // 동종재고 판매오더 ID
        }
      },
      sap_confirmation_journal_report: {
        url: "RPSCMCFJU01_Q0001QueryResults",
        extProps: {
          fullName: "C1PUM32FIIWRGVQAF4BD5HUK4HA",
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N",
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR",
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2",
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB",
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF",
          notInspection: "C1N6061KGKRRDY27JVXB8IBB48I"
        }
      },

      bsg_salesorder_monitoring_report: {
        url: "RPZC61B702804869659902E41QueryResults"
      },
      bsg_custom_inbound_delivery_notification_report: {
        url: "RPZDA454B1F5E1730BACADE98QueryResults",
        extProps: {
          contractStatus: "Cs1ANsFCDAFF9494A29AF", // 유상여부
          constractStatusText: "Ts1ANsFCDAFF9494A29AF", // 유상여부 텍스트,
          itemOrderDate: "C1PO_ITM_UUIDsITM_DLV_STDT",
          // 주문일  Document References > PO Item ID > Delivery Date From (Select)
          itemObjectID: "CDEL_ITEM_UUID",
          deliverSite: "C1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          deliverSiteText: "T1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          supplier: "T1PO_ITM_UUIDsSELLER",
          // Document References > PO Item ID > Supplier (2개중 두번째것 사용)
          supplierID: "C1PO_ITM_UUIDsSELLER",
          fullName: "C1RZ6I6GEXMQXQFKKCU7HO4D9TC"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ5658043746D8DCE1C4FE7EQueryResults",
        extProps: {
          deliveryClose: "Cs1ANsB90CDEA3661B110" // 납품종료
        }
      },
      bsg_material_supply_and_demand_report: {
        url: "RPZF91725D6F76A2127D715AFQueryResults",
        extProps: {}
      },
      bsg_purchaseorder_monitoring_report: {
        url: "RPZFBB137CCE65EE5DA41CD5BQueryResults",
        extProps: {
          confirmStatus: "Cs1ANsEC74DE6DA8A3250", // 수락여부ID
          confirmStatusText: "Ts1ANsEC74DE6DA8A3250", // 수락여부 텍스트
          contractStatus: "Cs1ANs7BF18A7B5625345", // 유상여부
          constractStatusText: "Ts1ANs7BF18A7B5625345", // 유상여부 텍스트,
          identifiedStockType: "C1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 (Report 에서 추가한 필드)
          identifiedStockTypeText: "T1JYVUP89Y9YKOXJW5QN5MBZNDK",
          // 동종재고 유형 텍스트 (Report 에서 추가한 필드)
          ItemproductStandard: "Cs1ANs7A1F81DA457C79C", // 제조사
          Itemmanufacturer: "Cs1ANsB50C53DF3706127", // 규격
          Itemsupplier: "Cs1ANs05ACC7AC76F84A9", // 공급사
          salesOrderID: "Cs1ANs270FD41046F1D9D", // 판매오더 ID
          fullName: "C1RZ6I6GEXMQXQFKKCU7HO4D9TC"
        }
      },
      bsg_productionorder_bom: {
        url: "RPZ521EEBD6109E5B7A100920QueryResults",
        extProps: {
          toleranceMin: "Cs1ANs4EF9227FF0E6ADE",
          toleranceMax: "Cs1ANs9CC96211129527B"
        }
      },
      bsg_productionorder: {
        url: "bsg_productionorder",
        extProps: {
          tempSave: "Weighting_KUT",
          salesOrderID: "SOID_01_KUT"
        }
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest",
        extProps: {
          salesOrderID: "SOID_02_KUT",
          productionOrderID: "PRODUCT_ORDER_KUT"
        }
      }
    }
  },
  my349075: {
    reports: {
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0005QueryResults",
        extProps: {
          location: "C1J3MW20B8P51YVWTQBM4WR5X6X"
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      bsg_salesorder_monitoring_report: {
        url: "RPZC61B702804869659902E41QueryResults",
        extProps: {
          location: "Cs1ANs35F5B794F7161E0"
        }
      },
      bsg_purchaseorder_monitoring_report: {
        url: "RPZ93081DFB61F1303FF6CC17QueryResults",
        extProps: {
          markID: "Cs1ANs8AEA3862B9C391D",
          markText: "Ts1ANs8AEA3862B9C391D",
          LCID: "Cs1ANs04EF39DAD7C1A78",
          contractNumber: "Cs1ANs481930405938BC6", // 계약번호,*
          flightNumber: "Cs1ANsABFB5CA99F521FE" // 항차번호*
        }
      },
      bsg_material: {
        url: "",
        extProps: {
          length: "LENGTHcontent_KUT",
          lengthUnitCode: "LENGTHunitCode_KUT",
          lengthUnitText: "LENGTHunitCode_KUTText",
          thick: "THICK01_KUT",
          width: "WIDTHcontent_KUT",
          widthUnitCode: "WIDTHunitCode_KUT",
          widthUnitText: "WIDTHunitCode_KUTText",
          weight: "WEIGHTcontent_KUT",
          weightUnitCode: "WEIGHTunitCode_KUT",
          weigthUnitText: "WEIGHTunitCode_KUTText"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ4EC83054C8B8EE9E561839QueryResults",
        extProps: {
          maker: "Cs1ANs925E47A0A723FD9",
          vessel: "Cs1ANs814C44F9F31BB65",
          contractNumber: "C1F6CG89YN8XPJCGDXN66ZVIOVV", // 계약번호 ID
          usage: "Cs1ANs741D7E682E8B85C", // 계약시용도
          clientID: "C1LK7RJTY7BNG3ID9IUB6DN7HJL", // 고객사 ID
          flightNumber: "C1NLLTZXQ7E5UB5AGZFUTZ9WPBB" // 항차번호
        }
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest"
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          itemID: "C1Q4VC87XO4Q5QIF2USSTPSW19E",
          specID: "C1QHMWQTDMPSP6S6BCO4OB17SWC",
          thick: "C1O9B3B1FCZI762SVLNS9X0CZKQ", // 두께
          length: "C1K5ID37GTI6CHAM9FGKK3MPW8I",
          weight: "C1MJNYUUUGY9ATJA7DU47S6XX03",
          width: "C1L1T1FRLTOD36KJ2V9NH85O2IB",
          batchManagement: "C1OESIO7P3LJ3D5QICISW3AGQ4A"
        }
      },
      bsg_inbound_list_report: {
        url: "RPZ0E7A73244D610EA84CCF68QueryResults",
        extProps: {
          flightNumber: "Cs1ANs73B2ACF05E01FB8",
          clientID: "Cs1ANsCBBBB2F2BBBE376", // 고객사 ID
          maker: "Cs1ANs6FACE42BF9105B8", // maker id
          makerText: "Ts1ANs6FACE42BF9105B8", // maker 텍스트
          vessel: "Cs1ANs0FA882AA4C9AF9C",
          usage: "Cs1ANsA90EDA9E665807A", // 계약시용도
          usageText: "Ts1ANsA90EDA9E665807A", // 계약시용도 텍스트
          contractNumber: "Cs1ANs531E8A4FC4CD57A" // 계약번호ID
        }
      },
      bsg_custom_inbound_list: {
        url: "RPZ27CF88410C2B936710ED97QueryResults",
        extProps: {
          flightNumber: "Cs1ANs73B2ACF05E01FB8",
          clientID: "Cs1ANsCBBBB2F2BBBE376", // 고객사 ID
          maker: "Cs1ANs6FACE42BF9105B8", // maker id
          makerText: "Ts1ANs6FACE42BF9105B8", // maker 텍스트
          vessel: "Cs1ANs0FA882AA4C9AF9C",
          usage: "Cs1ANsA90EDA9E665807A", // 계약시용도
          usageText: "Ts1ANsA90EDA9E665807A", // 계약시용도 텍스트
          contractNumber: "Cs1ANs531E8A4FC4CD57A" // 계약번호ID
        }
      },
      bsg_model_list: {
        url: "",
        extProps: {
          thick: "THICK01_KUT",
          weight: "WEIGHTcontent_KUT",
          weightUnitCode: "WEIGHTunitCode_KUT",
          weightText: "WEIGHTunitCode_KUTText",
          width: "WIDTHcontent_KUT",
          widthUnitCode: "WIDTHunitCode_KUT",
          widthText: "WIDTHunitCode_KUTText",
          length: "LENGTHcontent_KUT",
          lengthUnitCode: "LENGTHunitCode_KUT",
          lengthText: "LENGTHunitCode_KUTText",
          productionSystem: "PROMETH_KUT",
          systemText: "PROMETH_KUTText"
        }
      },
      bsg_shipment_list: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {}
      },
      bsg_custom_materialOutput_list: {
        url: "RPZAA2F77C58FBE2546D177F0QueryResults",
        extProps: {}
      },
      bsg_custom_materialInput_list: {
        url: "RPZ6FCEA1E540AC04AE65569DQueryResults",
        extProps: {}
      },
      bsg_custom_materialInput_depth1: {
        url: "",
        extProps: {}
      },
      bsg_custom_materialInput_depth2: {
        url: "",
        extProps: {}
      }
    }
  },
  my349238: {
    reports: {
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0003QueryResults",
        extProps: {
          location: "C1J3MW20B8P51YVWTQBM4WR5X6X"
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      bsg_salesorder_monitoring_report: {
        url: "RPZC61B702804869659902E41QueryResults",
        extProps: {
          location: "Cs1ANs35F5B794F7161E0"
        }
      },
      bsg_purchaseorder_monitoring_report: {
        url: "RPZ84879629439B7910235A81QueryResults",
        extProps: {
          markID: "Cs1ANs8AEA3862B9C391D",
          markText: "Ts1ANs8AEA3862B9C391D",
          LCID: "Cs1ANs04EF39DAD7C1A78",
          contractNumber: "Cs1ANs481930405938BC6", // 계약번호*
          flightNumber: "Cs1ANsABFB5CA99F521FE" // 항차번호*
        }
      },
      bsg_material: {
        url: "",
        extProps: {
          length: "LENGTHcontent_KUT",
          lengthUnitCode: "LENGTHunitCode_KUT",
          lengthUnitText: "LENGTHunitCode_KUTText",
          thick: "THICK01_KUT",
          width: "WIDTHcontent_KUT",
          widthUnitCode: "WIDTHunitCode_KUT",
          widthUnitText: "WIDTHunitCode_KUTText",
          weight: "WEIGHTcontent_KUT",
          weightUnitCode: "WEIGHTunitCode_KUT",
          weigthUnitText: "WEIGHTunitCode_KUTText"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ5EAFA4787FB514238716DEQueryResults"
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest"
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          itemID: "C1Q4VC87XO4Q5QIF2USSTPSW19E",
          specID: "C1QHMWQTDMPSP6S6BCO4OB17SWC",
          thick: "C1O9B3B1FCZI762SVLNS9X0CZKQ", // 두께
          length: "C1K5ID37GTI6CHAM9FGKK3MPW8I",
          weight: "C1MJNYUUUGY9ATJA7DU47S6XX03",
          width: "C1L1T1FRLTOD36KJ2V9NH85O2IB",
          batchManagement: "C1OESIO7P3LJ3D5QICISW3AGQ4A"
        }
      },
      bsg_custom_inbound_list: {
        url: "RPZC6F32083A7B1438C2F101CQueryResults",
        extProps: {
          flightNumber: "Cs1ANs73B2ACF05E01FB8",
          clientID: "Cs1ANsCBBBB2F2BBBE376", // 고객사 ID
          maker: "Cs1ANs6FACE42BF9105B8", // maker id
          makerText: "Ts1ANs6FACE42BF9105B8", // maker 텍스트
          vessel: "Cs1ANs0FA882AA4C9AF9C",
          usage: "Cs1ANsA90EDA9E665807A", // 계약시용도
          usageText: "Ts1ANsA90EDA9E665807A", // 계약시용도 텍스트
          contractNumber: "Cs1ANs531E8A4FC4CD57A" // 계약번호ID
        }
      },
      bsg_model_list: {
        url: "",
        extProps: {
          thick: "THICK01_KUT",
          weight: "WEIGHTcontent_KUT",
          weightUnitCode: "WEIGHTunitCode_KUT",
          weightText: "WEIGHTunitCode_KUTText",
          width: "WIDTHcontent_KUT",
          widthUnitCode: "WIDTHunitCode_KUT",
          widthText: "WIDTHunitCode_KUTText",
          length: "LENGTHcontent_KUT",
          lengthUnitCode: "LENGTHunitCode_KUT",
          lengthText: "LENGTHunitCode_KUTText",
          productionSystem: "PROMETH_KUT",
          systemText: "PROMETH_KUTText"
        }
      },
      bsg_shipment_list: {
        // url: "RPSCMOBDB04_Q0001QueryResults",
        url: "RPZ6A068401FC348CEA0E6126QueryResults",
        extProps: {}
      },
      bsg_custom_materialOutput_list: {
        url: "RPZ7E229E7CDF78B66188E62EQueryResults",
        extProps: {}
      },
      bsg_custom_materialInput_list: {
        url: "RPZ345302B23B8A7FDBB6D060QueryResults",
        extProps: {}
      },
      bsg_custom_materialInput_depth1: {
        url: "RPZEAEE7E44FC16E64D12EB18QueryResults",
        extProps: {}
      },
      bsg_custom_materialInput_depth2: {
        url: "RPZ6E9345BF337B70DB31310CQueryResults",
        extProps: {}
      },
      bsg_custom_inboundList: {
        // url: "RPSCMCIDB03_Q0001QueryResults",
        url: "RPZ1C32C81BACF14BF59CCCA2QueryResults",
        extProps: {}
      }
    }
  },
  my351075: {
    // 셀맥 추가
    reports: {
      bsg_identified_stock: {
        url: "RPZF566B6F43C99A1D369E7C0QueryResults"
      },
      bsg_logistics_area_resources_information: {
        url: "RPZABF4AB9EF264D6915C58C5QueryResults",
        extProps: {
          extLogisticsArea: "C1HZOKL6FLHX2KUGGTCC752VTO5" // 공급물류영역
        }
      },
      sap_outbound_delivery_performance_report: {
        url: "RPSCMODPB01_Q0003QueryResults",
        extProps: {
          shipToLocationID: "C1HND0TUU2QV0ZYYB8AJKA4MAPI" // 납품처 ID
        }
      },
      sap_outbound_delivery_detail_report: {
        url: "RPSCMOBDB04_Q0001QueryResults",
        extProps: {
          invoicePrintName: "C1NZRA7IRRSFRHV641JPEFTENGV",
          lengthUnitCode: "C1Q1BVHRDKTYQBG74QSECB22WFP",
          weightUnitCode: "C1FV9OGG0BK5JEG20B9QC9Y1NN1",
          productCategoryCode: "C1QSY3FAD0FGOE2AC9LNJ6JQU5W"
        }
      },
      sap_inventory_report: {
        url: "RPSCMINVV02_Q0001QueryResults",
        extProps: {
          description: "C1PUM32FIIWRGVQAF4BD5HUK4HA", // 자재 내역 풀네임,
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB", // 동종재고 생산일
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR", // 자재 Location
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N", // 자재 규격
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF", // 동종재고 만료일
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2" // 동종재고 판매오더 ID
        }
      },
      sap_confirmation_journal_report: {
        url: "RPSCMCFJU01_Q0001QueryResults",
        extProps: {
          fullName: "C1PUM32FIIWRGVQAF4BD5HUK4HA",
          dimension: "C1JL6MAGXOLKCYOP3D40IJ9YE4N",
          location: "C1RZQHIP34EBWSL8BRX91Q2QKTR",
          salesOrderID: "C1MCHSNNBHY65C7RTD61WZST9G2",
          productionDate: "C1JIRO2XWH7FAXM1IPAR3Z0Q5AB",
          dateValidUntil: "C1G1DFS0CSE2SVHEUB49TN4KCSF",
          notInspection: "C1N6061KGKRRDY27JVXB8IBB48I"
        }
      },
      bsg_salesorder_monitoring_report: {
        url: "RPZC61B702804869659902E41QueryResults"
      },
      bsg_custom_inbound_delivery_notification_report: {
        url: "RPZ1E0DBB26E1328C79695A0DQueryResults",
        extProps: {
          contractStatus: "Cs1ANs7BF18A7B5625345", // 유상여부
          constractStatusText: "Ts1ANs7BF18A7B5625345", // 유상여부 텍스트,
          itemOrderDate: "C1PO_ITM_UUIDsITM_DLV_STDT",
          // 주문일  Document References > PO Item ID > Delivery Date From (Select)
          itemObjectID: "CDEL_ITEM_UUID",
          deliverSite: "C1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          deliverSiteText: "T1PO_ITM_UUIDsSHIP_TO_LOC",
          // Document References > PO Item ID > Ship-to/Service Location
          supplier: "T1PO_ITM_UUIDsSELLER",
          // Document References > PO Item ID > Supplier (2개중 두번째것 사용)
          supplierID: "C1PO_ITM_UUIDsSELLER",
          fullName: "C1RZ6I6GEXMQXQFKKCU7HO4D9TC"
        }
      },
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZ5658043746D8DCE1C4FE7EQueryResults",
        extProps: {
          deliveryClose: "Cs1ANsB90CDEA3661B110" // 납품종료
        }
      },
      bsg_material_supply_and_demand_report: {
        url: "RPZF91725D6F76A2127D715AFQueryResults",
        extProps: {}
      },
      bsg_purchaseorder_monitoring_report: {
        // 20.06.11 셀맥 확인 후 수정
        url: "RPZDB7C03A4C3E179F3AE4871QueryResults",
        extProps: {
          confirmStatus: "Cs1ANsC241B14817AC385", // 수락여부ID
          confirmStatusText: "Ts1ANsC241B14817AC385", // 수락여부 텍스트
          contractStatus: "Cs1ANs9CABF4F937D076C", // 유상여부
          constractStatusText: "Ts1ANs9CABF4F937D076C" // 유상여부 텍스트
        }
      },
      bsg_productionorder_bom: {
        url: "RPZ521EEBD6109E5B7A100920QueryResults",
        extProps: {
          toleranceMin: "Cs1ANs4EF9227FF0E6ADE",
          toleranceMax: "Cs1ANs9CC96211129527B"
        }
      },
      // config.bsg_productionorder.node.root.nodename = "PO"
      // config.bsg_productionorder.node.root.id = "InternalID"
      // const config = store.config.bsg_productionorder.node;

      // {
      //   id: order[config.root.id]
      //   extension: Object.keys(config.root.extension).reduce((acc,key)=>{
      //     acc[key] = order[key];
      //     return acc;
      //   },{})
      // }

      // expand:
      // [getNodeForExpand(config, "root"),
      // getNodeForExpand(config, "sellerParty")]
      bsg_productionorder: {
        url: "bsg_productionorder",
        //   node: {
        //     root: {
        //       nodename: "PO",
        //       id: "InternalID",
        //       extension: {
        //         driver: "Driver_KUT"
        //       }
        //     },
        //     item: {
        //       nodename: "POItems",
        //       id: "ItemID",
        //       node: {
        //         sellerParty: {
        //           nodename: "SellerParty",
        //         }
        //       }
        //     }
        //   },
        extProps: {
          tempSave: "Weighting_KUT",
          salesOrderID: "SOID_01_KUT"
        }
      },
      bsg_inbounddeliveryrequest: {
        url: "bsg_inbounddeliveryrequest",
        extProps: {
          salesOrderID: "SOID_02_KUT",
          productionOrderID: "PRODUCT_ORDER_KUT"
        }
      }
    }
  },
  my344504: {
    // 제일 테스트 추가
    reports: {
      bsg_standard_inbound_delivery_notification_report: {
        url: "RPZD2DCE665E3EF56201754A2QueryResults"
      }
    }
  }
};

export default pid => {
  if (!mappingTable[pid]) {
    throw new Error("테넌트 정보가 존재하지 않습니다.");
  }

  return mappingTable[pid];
};
