/**
 * @overview 생산계획일정 프로젝트의 일정관리 부분으로, 생산오더 및 생산제안과 관련된 기능 등,
 * 확장필드를 이용한 스케쥴관리 등의 액션.
 * @author Hanmuk OH
 */

import { unitCodeToKor } from "lib/convertUOM";
import moment from "moment";
import { newProcess, fetchToken } from "ws-process";
import { convURL } from "actions/apiV2";
import { stockNumber, stockPrefix } from "lib/stockName";
// import UOM from "lib/convertUOM";
import { byId } from "ui5-lib-rc";
import extMap from "lib/urlMap";
import propMap from "lib/propMap";
// prettier-ignore
import QueryMaterialIn 
  from "actions/production_plan/service/soap_queryMaterialIn";
// prettier-ignore
import productionBillOfMaterialManagementIn from 
  "actions/production_plan/service/soap_productionBillOfMaterialManagementIn";
// prettier-ignore
import manageProdModelIn 
  from "actions/production_plan/service/soap_manageProdModelIn";
import {
  readProductionOrder,
  maintainBundle_V1
} from "actions/production_plan/service/soap_manageProductionOrderIn";
// prettier-ignore
import { createBundle } 
  from "actions/production_plan/service/soap_manageProductionProposalIn";
// prettier-ignore
import { productionOrderQueryByOverview } 
  from "actions/production_plan/service/soap_queryProductionOrderIn";
// prettier-ignore
import queryProductionLotISIIn 
  from "actions/production_plan/service/soap_queryProductionLotISIIn";
// prettier-ignore
import queryIdentifiedStocksIn 
  from "actions/production_plan/service/soap_queryIdentifiedStocksIn";
// prettier-ignore
import manageIdentifiedStocksIn 
  from "actions/production_plan/service/soap_manageIdentifiedStocksIn";
// prettier-ignore
import manageLogisticsLayoutExecutionViewIn from 
  "actions/production_plan/service/soap_manageLogisticsLayoutExecutionViewIn";
// prettier-ignore
import { getProductionOrders } 
  from "actions/production_plan/service/odata_bsg_productionorder";
// prettier-ignore
import { getSalesOrders } 
  from "actions/production_plan/service/odata_bsg_salesorder";
// prettier-ignore
import { query as queryProductionBom } 
  from "@bsgp/lib-api/production_bom_view/odata";
import { odata } from "@bsgp/lib-api";
import { tryit } from "@bsgp/lib-core";

export const actions = {
  PP_SCHEDULE_GET_SUPPLY_PLANNING_AREAS:
    "[PRODUCTION SCHEDULE] GET_SUPPLY_PLANNING_AREAS",
  PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS:
    "[PRODUCTION SCHEDULE] GET_PRODUCTION_PROPOSALS_AND_ORDERS",
  PP_SCHEDULE_CLEAR_PRODUCTION_PROPOSALS_AND_ORDER:
    "[PRODUCTION SCHEDULE] CLEAR_PRODUCTION_PROPOSALS_AND_ORDER",
  PP_SCHEDULE_GET_PRODUCTION_SCHEDULE_QUERY:
    "[PRODUCTION SCHEDULE] GET_PRODUCTION_SCHEDULE_QUERY",
  PP_SCHEDULE_GET_PRODUCTION_RESOURCES:
    "[PRODUCTION SCHEDULE] PP_SCHEDULE_GET_PRODUCTION_RESOURCES"
};

/**
 * 사이트 리스트를 불러옵니다.
 * @param {object} params
 * @param {*} callback
 */

export const getSupplyPlanningArea = (params, callback) => {
  return (dispatch, getState) => {
    getSupplyPlanningAreaProcess()
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;

        dispatch({
          type: actions.PP_SCHEDULE_GET_SUPPLY_PLANNING_AREAS,
          payload: response.map((value, index) => {
            return {
              key: value.CSHIP_TO_LOC,
              text: value.TSHIP_TO_LOC
            };
          })
        });

        return callback
          ? callback.afterSucceed(true, "조회가 완료되었습니다.")
          : "";
      })
      .catch(error => {
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      });
  };
};

/**
 * 생산제안과 생산오더를 불러옵니다.
 * @param {*} params
 * @param {*} callback
 */

export const getProductionOrderAndProposal = (params, callback) => {
  return (dispatch, getState) => {
    const searchType = parseInt(params.searchType);
    const currentPID = getState().user.currentUser.pid;

    let isQueryBySalesOrderID = false;

    if (params.keyword) {
      isQueryBySalesOrderID = searchType === 2;
    }

    // 설비 리소스정보 필터링을 위해, 조회 조건을 저장함

    dispatch({
      type: actions.PP_SCHEDULE_GET_PRODUCTION_SCHEDULE_QUERY,
      payload: params
    });

    let items = [];
    const proposalIDs = [];
    const productionOrderIDs = [];

    // 생산 제안 조회

    getProductionProposalProcess(params)
      .then(async res => {
        const response = res ? res.data.jsonResponse.body.d.results : [];

        items = response.map((item, index) => {
          proposalIDs.push(item.ProposalID);

          return {
            productionProposalObjectID: item.ParentObjectID,
            productionOrderObjectID: "",
            siteID: item.SupplyPlanningAreaID, // SupplyPlanningArea
            siteText: item.SupplyPlanningAreaName,
            productID: item.InternalID, // MaterialID(ProductID)
            quantityUnitText: item.unitCode
              ? unitCodeToKor(item.unitCode).text
              : item.unitCodeText,
            quantityUnit: item.unitCode, // Production Order
            salesOrderID: item.SOID_01_KUT, // Extension Field
            productionProposalID: item.ProposalID, // ProductionPlanningOrder
            productionOrderID: "", // ProductionOrder

            // ProductionOrder
            productionOrderStatusID: item.LifeCycleStatusCode,

            // ProductionOrder
            productionOrderStatusText: `(${item.LifeCycleStatusCodeText})`,
            orderQuantity: parseFloat(item.Quantity).toFixed(2), // Quantity
            productionQuantity: "",
            productionStartDate: moment(item.StartDateTime).format(
              "YYYY-MM-DD"
            ),
            productDescription: item.FULL_NAME_KUT || item.ItemDescription,
            materialAvailabilityDateTime: moment(
              item.AvailabilityDateTime
            ).format("YYYY-MM-DD"),
            shipmentDate: "",
            resourceID: "",
            resourceText: "",
            supervisor: "",
            productCategoryID: "",
            specification: "",
            resourceList: [{ resourceID: "A1", resourceText: "강남지사" }],
            operation: [],
            inputMaterials: [],
            editable: (() => {
              if (parseInt(item.LifeCycleStatusCode) === 1) {
                return true;
              }

              return false;
            })(),

            ...((
              startDateTime,
              schedule = item.Schedule_KUT,
              itemStartDateTime = moment(item.StartDateTime).format(
                "YYYY-MM-DD"
              ),
              itemOrderQuantity = Number(parseFloat(item.Quantity).toFixed(2))
            ) => {
              startDateTime =
                params.productionStartDate || item.StartDateTime
                  ? moment(item.StartDateTime).format("YYYY-MM-DD")
                  : null;

              let returnObj = {
                resourceID: "",
                data: {}
              };

              if (!moment(startDateTime).isValid())
                throw new Error(`스케줄의 시작일 정보를 조회할 수 없습니다.`);

              Array.from({ length: 31 }, (value, index) => {
                const startDate = moment(startDateTime, "YYYY-MM-DD")
                  .add(index, "days")
                  .format("YYYY-MM-DD");
                return (returnObj.data[`${startDate}`] = {
                  quantity: "",
                  numResource: ""
                });
              });

              let hasSchedule = false;
              if (schedule) {
                try {
                  const scheduleData = JSON.parse(schedule);
                  returnObj = {
                    resourceID: scheduleData.resourceID,
                    data: { ...returnObj.data, ...scheduleData.data }
                  };

                  // 생산제안에 JSON이 저장되어 있더라도 실제 스케줄링된 날짜가 없을 수 있으므로 검사
                  const jsonScheduleDates = Object.keys(returnObj.data);
                  if (
                    jsonScheduleDates.find(
                      dateKey => returnObj.data[dateKey].quantity
                    )
                  ) {
                    hasSchedule = true;
                  }
                } catch (error) {
                  console.error("Fail Spreading Schedule_KUT");
                  console.error(error);
                }
              }

              // 스케줄 데이터가 없는 경우, 생산시작일에 해당하는 날짜에 지시수량을 표시해 준다.

              if (!hasSchedule) {
                const startDate = moment(
                  itemStartDateTime,
                  "YYYY-MM-DD"
                ).format("YYYY-MM-DD");
                returnObj = { ...returnObj };

                if (returnObj.data.hasOwnProperty(startDate)) {
                  returnObj.data[`${startDate}`] = {
                    quantity: itemOrderQuantity,
                    numResource: ""
                  };
                }
              }

              return {
                scheduleData: returnObj
              };
            })()
          };
        });

        // 판매오더가 참조하고 있는 생산오더 ID
        if (isQueryBySalesOrderID) {
          // 판매오더 ID로 검색시, getProductionProposalProcess 으로 아이템을 찾았다면
          // 더이상 불필요한 조회를 하지 않고 return 한다.

          // production order odata 를 판매오더ID 기준으로 쿼리해서 생산오더ID를 가져온다.

          await getProductionOrders({
            salesOrderID: params.keyword,
            searchType: params.searchType
          }).then(res => {
            const response = res.data.jsonResponse.body.d.results;
            productionOrderIDs.push(
              ...response.map(item => item.ID || "").filter(Boolean)
            );
          });

          // 생산제안, 생산오더에서 판매오더 번호로 조회 실패 시, 검색 결과 없음
          if (
            parseInt(params.searchType) === 2 &&
            productionOrderIDs.length < 1 &&
            items.length < 1
          ) {
            throw new Error("검색 결과가 없습니다.");
          }
        }

        if (searchType === 0) {
          // 제안ID로 검색 시 생산제안 조회에서 제안을 찾은 경우, 추가 조회할 필요 없다.
          if (items.length > 0) return;
          // 생산제안 조회에서 찾지 못한 경우(이미 릴리즈된 제안인 경우),
          // 생산오더 조회에서 찾기 위해 제안 ID를 배열에 넣어준다.
          proposalIDs.push(params.keyword);
        }

        // 조회된 생산제안ID(= 생산요청ID)로 생산오더 조회

        const query = {
          ...params,
          proposalIDs,
          productionOrderIDs,
          includeProductionReqSelection: true
        };
        return getProductionOrderByID(query);
      })
      .then(async res => {
        const response = res ? res.data.jsonResponse : undefined;

        const poIDs = [];
        let productionOrders = response ? response.ProductionOrder : [];

        // 생산오더 기준 조회
        // 생산 시작일이 바뀌었을 수 있으므로, 생산오더의 생산 시작일로 추가 조회를 한다.

        // 생산제안은 이전 단계에서 이미 조회되었으므로 추가 조회 X
        if (parseInt(params.searchType) !== 0) {
          if (productionOrderIDs.length > 0) {
            const query = {
              ...params,
              proposalIDs,
              productionOrderIDs,
              includeProductionReqSelection: false
            };
            const extraPOs = await getProductionOrderByID(query);
            const extraRes = extraPOs
              ? extraPOs.data.jsonResponse.ProductionOrder
              : [];
            if (extraRes.length > 0) {
              productionOrders = [...productionOrders, ...extraRes];
            }
          }

          if (parseInt(params.searchType) === 2 && proposalIDs.length > 0) {
            // 판매오더 ID는 생산제안에서 가져올 때가 있으므로, 판매오더 ID로 조회시 제안을 조건으로 추가조회 한다.
            const extraPOsByProposalID = await getProductionOrderByID({
              ...params,
              proposalIDs,
              // productionOrderIDs,
              includeProductionReqSelection: true
            });
            const extraPOsByProposalIDRes = extraPOsByProposalID
              ? extraPOsByProposalID.data.jsonResponse.ProductionOrder
              : [];
            if (extraPOsByProposalIDRes.length > 0) {
              productionOrders = [
                ...productionOrders,
                ...extraPOsByProposalIDRes
              ];
            }
          }
        }

        // 생산오더 조회 결과와 생산제안 조회 결과가 모두 없으면 결과 없음
        if (productionOrders.length <= 0 && items.length <= 0) {
          throw new Error("검색 결과가 없습니다.");
        }

        for (const order of productionOrders) {
          const pdoID = order.ProductionOrderID._value_1;
          const reqID = order.ProductionOrderRequestID._value_1;

          let fItemIdx = items.findIndex(
            item => `${item.productionProposalID}` === `${reqID}`
          );

          if (fItemIdx < 0) {
            fItemIdx = items.push({}) - 1;
          }

          if (parseInt(order.ProductionOrderStatus) === 6) {
            // 취소됨 상태의 경우 데이터를 삭제하고 따로 조회하지 않음
            items.splice(fItemIdx, 1);
          } else {
            // 추가 정보 조회를 위해 Array에 생산오더 ID를 추가한다.
            poIDs.push(pdoID);
            // 생산오더의 정보를 기존 데이터에 머지한다.
            items[fItemIdx] = {
              ...items[fItemIdx],
              ...{
                productionProposalID: reqID,
                // MaterialID(ProductID)
                productionOrderID: pdoID,
                productID: (() => {
                  const output = order.ProductionOrderMaterialOutput;
                  if (output && output.length > 0) {
                    return output[0].ProductionOrderOutputProductID._value_1;
                  }
                })(),
                productionOrderStatusID: parseInt(order.ProductionOrderStatus),
                productionOrderStatusText: ((
                  statusCode = parseInt(order.ProductionOrderStatus)
                ) => {
                  switch (statusCode) {
                    case 1:
                      return "준비중";
                    case 2:
                      return "릴리즈됨";
                    case 3:
                      return "시작됨";
                    case 4:
                    case 5:
                      return "종료됨";
                    case 6:
                      return "취소됨";
                    default:
                      return statusCode;
                  }
                })(),
                productionNo: order.PRODUCT_NO,
                supervisor: order.SUPERVISER,
                specification: order.SPECIFICATION,
                salesOrderID: order.SOID_01,
                scheduleData: order.Schedule,
                stNum: order.ST_NUM_1,
                bomFixedDate: order.BOM_FIXED_DATE_1,
                bomVersion: order.BOM_VERSION_1,
                customerName: order.CUSTOMER_NAME
              }
            };
          }
        }

        if (poIDs.length <= 0) {
          return;
        }

        // 추가 정보 조회
        // queryProdcutionOrderIn 에서 조회하지 못한 정보를 manageProdcutionOrderIn 으로 조회

        return readProductionOrder({ productionOrderIds: poIDs });
      })
      .then(async res => {
        const response = res ? res.data.jsonResponse : {};
        const pdo = response.ProductionOrder;

        // 오더 별 지시자 조회
        const indicatorData = await odata.pdo
          .query({
            IDs: {
              list: pdo.map(ord => ord.ProductionOrderID._value_1),
              keyField: "ID"
            },
            params: { $select: "ID,EXT6_KUT,EXT6_KUTText,EXT8_KUTText" },
            ignoreStore: true,
            reduceCallbackRes: true
          })
          .then(res => res.odata.list);

        // 지시자 세팅이 안되어 있는 경우 공장코드와 같게 설정 (22.04.21)
        const service = "bsg_productionorder/ProductionOrder";
        const supervisors = await odata.common
          .query({ entity: `${service}EXT6_KUTCollection` })
          .then(res => res.data.cbData);
        const manufactures = await odata.common
          .query({ entity: `${service}EXT8_KUTCollection` })
          .then(res => res.data.cbData);

        if (pdo) {
          for (const order of pdo) {
            const pdoID = order.ProductionOrderID._value_1;
            const fItemIdx = items.findIndex(value => {
              return `${value.productionOrderID}` === `${pdoID}`;
            });
            if (fItemIdx < 0) {
              return;
            }
            const fSupervisorData = indicatorData.find(ord => ord.id === pdoID);
            const siteID = order.SiteID._value_1;
            const defaultSV = supervisors.find(sv => sv.Code === siteID);
            const defaultMF = manufactures.find(mf => mf.Code === siteID);
            const extension = fSupervisorData.extension;
            const supervisorCode =
              extension.supervisor.id || (defaultSV && defaultSV.Code);
            const supervisorText =
              extension.supervisor.text || (defaultSV && defaultSV.Description);
            const manufactureIndicator =
              extension.manufactureIndicator.text ||
              (defaultMF && defaultMF.Description);
            items[fItemIdx] = {
              ...items[fItemIdx],
              supervisorCode,
              supervisorText,
              manufactureIndicator,
              productionProposalObjectID: order.ProductionRequestUUID
                ? order.ProductionRequestUUID._value_1
                    .replace(/-/gi, "")
                    .toUpperCase()
                : "",
              productionOrderObjectID: order.ProductionOrderUUID
                ? order.ProductionOrderUUID._value_1
                    .replace(/-/gi, "")
                    .toUpperCase()
                : "",
              // SupplyPlanningArea
              siteID: order.SiteID ? order.SiteID._value_1 : "",
              productionStartDate: moment(
                order.Dates.LatestStartDateTime
              ).format("YYYY-MM-DD"),
              quantityUnitText:
                order.Quantities && order.Quantities.PlannedQuantity
                  ? unitCodeToKor(order.Quantities.PlannedQuantity.unitCode)
                      .text
                  : "",
              quantityUnit:
                order.Quantities && order.Quantities.PlannedQuantity
                  ? order.Quantities.PlannedQuantity.unitCode
                  : "", // Production Order
              orderQuantity:
                order.Quantities && order.Quantities.PlannedQuantity
                  ? order.Quantities.PlannedQuantity._value_1
                  : "",
              productionQuantity:
                order.Quantities && order.Quantities.FulfilledQuantity
                  ? order.Quantities.FulfilledQuantity._value_1
                  : "",
              shipmentDate: moment(order.Dates.EarliestEndDateTime).format(
                "YYYY-MM-DD"
              ),
              resourceID: (() => {
                const resources = order.ProductionOrderResources;
                if (order.Dates.FIXED_RESOURCE) {
                  return order.Dates.FIXED_RESOURCE;
                }
                for (const resource of resources) {
                  if (resource.ResourceID) {
                    return resource.ResourceID._value_1;
                  }
                }
                return "";
              })(),
              resourceList: [],
              operation: order.Operation,
              bomModel: order.ReleasedExecutionProductionModelID
                ? order.ReleasedExecutionProductionModelID._value_1
                : "",
              inputMaterials: (() => {
                console.log(order);
                let inputProducts = [];
                order.Operation.forEach(op => {
                  op.ProductionOrderOperationActivity.forEach(ac => {
                    inputProducts = [
                      ...inputProducts,
                      ...ac.ProductionOrderInputProducts
                    ];
                  });
                });
                return inputProducts;
              })(),
              outputMaterials: (() => {
                let outputProducts = [];
                order.Operation.forEach(op => {
                  op.ProductionOrderOperationActivity.forEach(ac => {
                    outputProducts = [
                      ...outputProducts,
                      ...ac.ProductionOrderOutputProducts
                    ];
                  });
                });
                return outputProducts;
              })(),
              editable: (() => {
                if (parseInt(order.ProductionOrderStatusCode) === 1) {
                  return true;
                }
                return false;
              })(),
              // schedule
              // eslint-disable-next-line no-loop-func
              ...((
                startDateTime = params.productionStartDate,
                schedule = items[fItemIdx].scheduleData
              ) => {
                startDateTime =
                  startDateTime || order.Dates
                    ? order.Dates.EarliestStartDateTime
                    : null;
                const productionStartDate = moment(
                  order.Dates.LatestStartDateTime
                ).format("YYYY-MM-DD"); // 생산시작일
                const orderQuantity =
                  order.Quantities && order.Quantities.PlannedQuantity
                    ? order.Quantities.PlannedQuantity._value_1
                    : ""; // 지시수량
                let returnObj = {
                  resourceID: "",
                  data: {}
                };
                if (!moment(startDateTime).isValid())
                  throw new Error(`스케줄의 시작일 정보를 조회할 수 없습니다.`);

                Array.from({ length: 31 }, (value, index) => {
                  const startDate = moment(startDateTime, "YYYY-MM-DD")
                    .add(index, "days")
                    .format("YYYY-MM-DD");
                  return (returnObj.data[`${startDate}`] = {
                    quantity: "",
                    numResource: ""
                  });
                });

                let hasSchedule = false;
                if (schedule) {
                  try {
                    const scheduleData = JSON.parse(schedule);
                    if (scheduleData.data.hasOwnProperty("Invalid date")) {
                      throw new Error(
                        "스케줄 데이터의 날짜 형식이 잘못되었습니다."
                      );
                    }
                    returnObj = {
                      resourceID: scheduleData.resourceID,
                      data: { ...returnObj.data, ...scheduleData.data }
                    };
                    // 생산오더 확장필드에 저장된 JSON이 있어도 실제 스케줄링된 날짜가 없을 수 있으므로 검사
                    const jsonScheduleDates = Object.keys(returnObj.data);
                    if (
                      jsonScheduleDates.find(
                        dateKey => returnObj.data[dateKey].quantity
                      )
                    ) {
                      hasSchedule = true;
                    }
                  } catch (error) {
                    console.error(`Fail Spreading Schedule_KUT ${error}`);
                  }
                }

                // 스케줄 데이터 없는 경우 스케줄의 생산일에 지시수량을 표시해준다. (화면에만 표시)
                // 화면에 생산일에 해당하는 날짜가 표시되어 있는 경우에만 지시수량 표시해준다.
                if (
                  !hasSchedule &&
                  returnObj.data.hasOwnProperty(productionStartDate)
                ) {
                  returnObj.data[productionStartDate].quantity = orderQuantity;
                }
                return {
                  scheduleData: returnObj
                };
              })()
            };
          }
        }

        // 자재별 카테고리

        if (items.length > 0) {
          return QueryMaterialIn.findByElements({
            internalIDs: [
              ...new Set(
                items
                  .map(item => {
                    return item.productID;
                  })
                  .filter(Boolean)
              )
            ]
          });
        }

        return new Promise(resolve => resolve());
      })
      .then(res => {
        const response = res && res.data ? res.data.jsonResponse.Material : [];

        items.forEach((item, index) => {
          const fItem = response.find(sItem => {
            return sItem.InternalID._value_1 === item.productID;
          });

          if (fItem) {
            items[index].productCategoryID = fItem.ProductCategoryID;
            items[index] = {
              ...items[index],
              ...{
                productCategoryID: fItem.ProductCategoryID,
                identifiedStockTypeCode: fItem.IdentifiedStockTypeCode
                  ? fItem.IdentifiedStockTypeCode._value_1
                  : "",
                productDescription: (() => {
                  if (fItem.FULL_NAME) {
                    return fItem.FULL_NAME;
                  }

                  if (fItem.Description.length > 0) {
                    return fItem.Description[0].Description._value_1;
                  }
                })()
              }
            };
          }
        });

        // 사이트별 리소스 유무 확인 후 조회

        const currentResources = getState().productionPlan.pp_schedule
          .productionResources;
        if (currentResources.length <= 0) {
          return getProductionResourcesBySite();
        }
      })
      .then(res => {
        // 사이트별 리소스 정보 저장
        const response = res ? res : [];
        if (response.length > 0) {
          dispatch({
            type: actions.PP_SCHEDULE_GET_PRODUCTION_RESOURCES,
            payload: response
          });
        }

        // 판매오더 수량 조회

        const salesOrderIDs = items.map(item => {
          if (item.salesOrderID && item.salesOrderID.indexOf("-") > 0) {
            return item.salesOrderID.split("-")[0];
          }
          return item.salesOrderID;
        });

        if (salesOrderIDs.length < 1)
          return new Promise((resolve, reject) => {
            resolve();
          });
        return getSalesOrders({ salesOrderID: salesOrderIDs });
      })
      .then(res => {
        const oSalesOrders = res ? res.data.jsonResponse.body.d.results : [];
        const { bulkOnly, nonBulkOnly } = params;

        // oSalesOrders와 item 매핑
        if (oSalesOrders.length > 0) {
          items.forEach((item, idx) => {
            if (!item.salesOrderID) return;

            // 참조 판매오더 ID에 - 안들어가는 기존코드 처리를 위한 flag
            const isOld = !item.salesOrderID.includes("-");

            const splittedID = !isOld ? item.salesOrderID.split("-") : null;
            const salesOrderID = !isOld ? splittedID[0] : item.salesOrderID;
            const itemID = !isOld ? splittedID[1] : null;

            // 일치하는 판매오더 찾기

            const matchingSalesOrder = oSalesOrders.find(
              sOrder => sOrder.ID === salesOrderID
            );

            if (
              !matchingSalesOrder ||
              !matchingSalesOrder.SalesOrderSalesItem
            ) {
              console.warn(
                `제품 ${item.productID}에 대한 판매오더를 찾을 수 없습니다.`
              );
              return;
            }

            // 판매오더의 아이템 항목들 중 일치하는 아이템 찾기

            let matchingItem;
            if (isOld) {
              // 기존 항목의 경우 판매오더에 제품명이 찍혀있는 아이템만(BOM 최상위 아이템) 수량 찍어줌
              matchingItem = matchingSalesOrder.SalesOrderSalesItem.find(
                orderItem => orderItem.ProductInternalID === item.productID
              );
              if (!matchingItem) {
                console.warn(
                  `판매 오더 ${item.salesOrderID}에서 
                  아이템 ${item.productID}을 찾을 수 없습니다.`
                );
                return;
              }
            } else {
              // 제품명과 관계없이 항목ID가 같으면 다 같은 수량 찍어줄 것이기 때문에 항목 ID만 비교
              matchingItem = matchingSalesOrder.SalesOrderSalesItem.find(
                orderItem => orderItem.ID === itemID
              );
              if (!matchingItem) {
                console.warn(
                  `판매 오더 ${salesOrderID}에 항목번호 ${itemID}가 존재하지 않습니다.`
                );
                return;
              }
            }
            item.salesOrderQuantity = matchingItem.RequestedQuantity
              ? Number(parseFloat(matchingItem.RequestedQuantity).toFixed(2))
              : "";
          });
        }

        // 벌크only 필터

        if (bulkOnly && !nonBulkOnly) {
          items = items.filter(item =>
            propMap(currentPID).bulkCategoryIDs.includes(item.productCategoryID)
          );
        } else if (!bulkOnly && nonBulkOnly) {
          items = items.filter(
            item =>
              !propMap(currentPID).bulkCategoryIDs.includes(
                item.productCategoryID
              )
          );
        }

        setTimeout(() => {
          dispatch({
            type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
            payload: items
          });
        }, 0);

        return callback
          ? callback.afterSucceed(true, "조회가 완료되었습니다.")
          : "";
      })
      .catch(error => {
        byId("app").setBusy(false);
        console.error(error);
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : window.sap.m.MessageToast.show(error, {
              duration: 3000
            });
      });
  };
};

/**
 * 생산제안을 릴리즈 합니다.
 * @param {*} params
 * @param {*} callback
 */

export const postReleaseProductionProposal = (params, callback) => {
  return (dispatch, getState) => {
    const query = getState().productionPlan.pp_schedule.productionScheduleQuery;
    const items = getState().productionPlan.pp_schedule.productionSchedule;

    return postReleaseProductionProposalProcess({
      proposalUUIDs: params.proposalUUIDs,
      dispatch: dispatch
    })
      .then(res => {
        const requestIDs = res.map(value => {
          const response = value.data.jsonResponse.body.d.results;
          return response.ID;
        });

        // Assume ProposalID equals to RequestID

        return productionOrderQueryByOverview({
          proposalIDs: requestIDs,
          orderIDs: []
        });
      })
      .then(async res => {
        const poIDs = [];
        const response = res.data.jsonResponse;
        const productionOrders = response.ProductionOrder;

        for (const order of productionOrders) {
          const fItemIdx = items.findIndex(item => {
            return (
              `${item.productionProposalID}` ===
              `${order.ProductionOrderRequestID._value_1}`
            );
          });

          if (fItemIdx >= 0) {
            // 생산오더 상태코드가 4 이상은 종료됨 혹은 취소됨 상태

            if (parseInt(order.ProductionOrderStatus) >= 4) {
              // 종료됨 혹은 취소됨 상태의 경우 데이터를 삭제하고 따로 조회하지 않음

              items.splice(fItemIdx, 1);
            } else {
              // 추가 정보 조회를 위해 Array에 생산오더 ID를 추가한다.

              poIDs.push(order.ProductionOrderID._value_1);

              // 생산오더의 정보를 기존 데이터에 머지한다.

              items[fItemIdx] = {
                ...items[fItemIdx],
                ...{
                  productionOrderID: order.ProductionOrderID._value_1,
                  productionOrderStatusID: parseInt(
                    order.ProductionOrderStatus
                  ),
                  productionOrderStatusText: ((
                    statusCode = parseInt(order.ProductionOrderStatus)
                  ) => {
                    switch (statusCode) {
                      case 1:
                        return "준비중";
                      case 2:
                        return "릴리즈됨";
                      case 3:
                        return "시작됨";
                      case 4:
                      case 5:
                        return "종료됨";
                      case 6:
                        return "취소됨";
                      default:
                        return statusCode;
                    }
                  })(),
                  supervisor: order.SUPERVISER,
                  specification: order.SPECIFICATION
                },
                ...{
                  scheduleData: ((
                    startDateTime = query.productionStartDate,
                    schedule = order.Schedule
                  ) => {
                    let returnObj = {};

                    Array.from({ length: 15 }, (value, index) => {
                      const startDate = moment(startDateTime, "YYYY-MM-DD")
                        .add(index, "days")
                        .format("YYYY-MM-DD");
                      return (returnObj[`${startDate}`] = {
                        quantity: "",
                        numResource: ""
                      });
                    });

                    if (schedule) {
                      try {
                        const scheduleData = JSON.parse(schedule);
                        returnObj = {
                          resourceID: scheduleData.resourceID,
                          data: { ...returnObj.data, ...scheduleData.data }
                        };
                      } catch (error) {
                        console.error(error);
                      }
                    }
                    return returnObj;
                  })()
                }
              };
            }
          }
        }

        // Force wait fo 2 seconds for BYD processing bo
        await new Promise(resolve => setTimeout(resolve, 2000));

        return readProductionOrder({ productionOrderIds: poIDs });
      })
      .then(res => {
        const response = res.data.jsonResponse;
        const productionOrders = response.ProductionOrder;

        if (productionOrders) {
          for (const order of productionOrders) {
            const fItemIdx = items.findIndex(value => {
              return (
                `${value.productionOrderID}` ===
                `${order.ProductionOrderID._value_1}`
              );
            });

            if (fItemIdx >= 0) {
              items[fItemIdx] = {
                ...items[fItemIdx],
                ...{
                  productionQuantity: order.Quantities
                    ? order.Quantities.FulfilledQuantity._value_1
                    : "",
                  productionOrderObjectID: order.ProductionOrderUUID._value_1
                    .replace(/-/gi, "")
                    .toUpperCase(),
                  shipmentDate: moment(order.Dates.EarliestEndDateTime).format(
                    "YYYY-MM-DD"
                  ),
                  resourceID: (() => {
                    const resources = order.ProductionOrderResources;
                    for (const resource of resources) {
                      if (resource.resourceID) {
                        return resource.resourceID;
                      }
                    }

                    return "";
                  })(),
                  ...{
                    scheduleData: ((
                      startDateTime = query.productionStartDate,
                      schedule = order.Dates.Schedule
                    ) => {
                      let returnObj = {};

                      Array.from({ length: 15 }, (value, index) => {
                        const startDate = moment(startDateTime, "YYYY-MM-DD")
                          .add(index, "days")
                          .format("YYYY-MM-DD");
                        return (returnObj[`${startDate}`] = {
                          quantity: "",
                          numResource: ""
                        });
                      });

                      if (schedule) {
                        try {
                          returnObj = { ...returnObj, ...JSON.parse(schedule) };
                        } catch (error) {
                          console.error(error);
                        }
                      }
                      return returnObj;
                    })()
                  },
                  resourceList: order.ProductionOrderResources
                    ? order.ProductionOrderResources.map(resource => {
                        if (
                          resource.ResourceID &&
                          resource.ResourceID !== null &&
                          resource.ResourceDescription._value_1
                        ) {
                          if (
                            resource.ResourceID._value_1 ===
                            resource.ResourceDescription._value_1
                          ) {
                            return undefined;
                          } // 리소스ID 와 설명이 동일한 경우, 사용자가 리소스를 선택할 수 없음.

                          return {
                            resourceID: resource.ResourceID._value_1,
                            resourceText: resource.ResourceDescription._value_1,
                            enabled: false
                          };
                        }

                        return undefined;
                      })
                    : [],
                  operation: order.Operation,
                  editable: (() => {
                    if (parseInt(order.ProductionOrderStatusCode) === 1) {
                      return true;
                    }

                    return false;
                  })()
                }
              };
            }
          }
        }

        const orderItemsStr = productionOrders
          .map(item => {
            return item.ProductionOrderID._value_1;
          })
          .join(",");

        dispatch({
          type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
          payload: items
        });

        const message = `생산오더(${orderItemsStr})가 생성되었습니다.`;
        return callback
          ? callback.afterSucceed(true, message)
          : Promise.resolve(true);
      })
      .catch(error => {
        console.error(error);
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : Promise.reject(error);
      });
  };
};

/**
 * 생산오더 (다중) 릴리즈
 * @param {*} params (params.productionOrders)
 * @param {*} callback
 */

export const postReleaseProductionOrders = (params, callback) => {
  return async (dispatch, getState) => {
    const productionOrders =
      params && params.productionOrders ? params.productionOrders : [];

    if (!Array.isArray(productionOrders) || productionOrders.length <= 0) {
      const error = "릴리즈할 생산오더가 없거나, 데이터가 잘못되었습니다.";
      return callback && callback.afterFailed
        ? callback.afterFailed(false, error)
        : console.error(error);
    }

    const currentPID = getState().user.currentUser.pid;
    const query = getState().productionPlan.pp_schedule.productionScheduleQuery;
    const items = getState().productionPlan.pp_schedule.productionSchedule;
    const releasedOrderIDs = [];

    // 투입 벌크제품의 LOT 확인

    const checkInputBulkIdentfiedStockIDs = async orders => {
      const checkIDs = [];

      orders.forEach(order => {
        if (!order.releaseUIData || !order.releaseUIData.inputBulkItemList)
          return;
        const bulkItems = order.releaseUIData.inputBulkItemList;
        bulkItems.forEach(bulk => {
          checkIDs.push(bulk.productionNumberCustom);
        });
      });

      if (checkIDs.length < 1) return;

      // prettier-ignore
      const identifiedStockIDCheckRes = 
      await queryIdentifiedStocksIn.findByElements(
        {
          identifiedStockIDs: checkIDs
        }
      );

      const identifiedStockIDCheck =
        identifiedStockIDCheckRes.data.jsonResponse.IdentifiedStock;

      orders.forEach(order => {
        if (!order.releaseUIData || !order.releaseUIData.inputBulkItemList)
          return;
        order.releaseUIData.inputBulkItemList.forEach(item => {
          const matTypeCode = item.identifiedStockTypeCode
            ? parseInt(item.identifiedStockTypeCode)
            : undefined;
          if (matTypeCode && matTypeCode === 4) {
            // 필수지정 동종재고, 사용자가 입력한 동종재고 유효성 검사

            const fItem = identifiedStockIDCheck.find(sItem => {
              return (
                sItem.IdentifiedStockID._value_1 ===
                  item.productionNumberCustom &&
                sItem.ProductID._value_1 === item.materialID &&
                parseInt(sItem.LifeCycleStatusCode.LifeCycleStatusCode) === 2
              ); // 동종재고 Active, 동종재고ID 일치여부, 제품ID 일치여부 확인
            });

            if (!fItem && item.productionNumberCustom) {
              console.error(item);
              throw new Error(
                `${item.productionNumberCustom}은 유효하지 않은 제조번호(LOT)입니다.`
              );
            }
          }
        });
      });
    };

    // 동종재고 체크 및 생성

    const identifiedStockCheck = async order => {
      // 벌크인 경우 동종재고 생성
      // 벌크가 아닌 경우 착인사양 입력시 동종재고 생성

      const productCategoryID = order.productCategoryID;
      if (
        propMap(currentPID).bulkCategoryIDs.includes(productCategoryID) &&
        order.identifiedStockTypeCode
      ) {
        // 벌크 제품인 경우, 동종재고를 검색하고, 재고를 신규번호를 부여하여 생성.

        // 산출제품에 동종재고가 이미 부여되어 있는 경우 릴리즈 중단

        if (await isReleasedOrder(order.productionOrderID)) {
          throw new Error(
            `생산오더 ${order.productionOrderID}는 이미 릴리즈 되었거나, 
            산출제품에 동종재고가 부여된 오더입니다.`
          );
        }

        const numResources = await getLastIdentifiedStock();
        const numResourcesRes = numResources.data.jsonResponse.body.d.results;
        let currCount = 0;

        if (numResourcesRes.length > 0) {
          currCount =
            parseInt(numResourcesRes[0].CISTOCK_ID.substr(2, 4), 16) + 1;
        }
        const newStockNumber = stockNumber(currCount);

        await manageIdentifiedStocksIn.maintainBundle({
          payload: {
            BasicMessageHeader: {},
            IdentifiedStockMaintainBundle: {
              actionCode: "01",
              activateIdentifiedStockIndicator: "true",
              IdentifiedStockID: newStockNumber,
              ProductID: order.productID
            }
          }
        });

        order.productNo = newStockNumber; // 제조번호 = 동종재고ID
      } else if (order.specification && order.identifiedStockTypeCode) {
        // 완제품인 경우 착인사양의 입력에 따라서 동종재고 생성여부 결정

        // 산출제품에 동종재고가 이미 부여되어 있는 경우 릴리즈 중단

        if (await isReleasedOrder(order.productionOrderID)) {
          throw new Error(
            `생산오더 ${order.productionOrderID}는 이미 릴리즈 되었거나, 
            산출제품에 동종재고가 부여된 오더입니다.`
          );
        }

        const numResources = await getLastIdentifiedStock();
        const numResourcesRes = numResources.data.jsonResponse.body.d.results;
        let currCount = 0;

        if (numResourcesRes.length > 0) {
          currCount =
            parseInt(numResourcesRes[0].CISTOCK_ID.substr(2, 4), 16) + 1;
        }
        const newStockNumber = stockNumber(currCount);

        await manageIdentifiedStocksIn.maintainBundle({
          payload: {
            BasicMessageHeader: {},
            IdentifiedStockMaintainBundle: {
              actionCode: "01",
              activateIdentifiedStockIndicator: "true",
              IdentifiedStockID: newStockNumber,
              ProductID: order.productID,
              Description: {
                actionCode: "04",
                Description: {
                  languageCode: "KO",
                  _value_1: order.specification
                }
              }
            }
          }
        });

        order.productNo = newStockNumber; // 제조번호 = 동종재고ID
      }

      // prettier-ignore
      const identifiedStockIDCheckRes = 
        await queryIdentifiedStocksIn.findByElements(
        {
          identifiedStockIDs: (() => {
            const productCategoryID = order.productCategoryID;
            if (
              propMap(currentPID).bulkCategoryIDs.includes(productCategoryID) &&
              order.identifiedStockTypeCode
            ) {
              // 벌크제품인 경우, 자동생성된 동종재고 유효성확인

              return [order.productNo];
            } else if (order.specification) {
              // 벌크제품이 아니면서 착인사양이 입력된경우, 투입 벌크제품 LOT 과 생성된 LOT을 확인

              const checkIDs = order.releaseUIData.inputBulkItemList
                .map(item => {
                  return item.productionNumberCustom;
                })
                .filter(Boolean);

              if (order.identifiedStockTypeCode) {
                checkIDs.push(order.productNo);
              }

              return checkIDs;
            } else {
              // 벌크제품이 아니면서 착인사양이 입력되지 않은경우, 투입 벌크제품 LOT을 확인
              return order.releaseUIData.inputBulkItemList
                .map(item => {
                  return item.productionNumberCustom;
                })
                .filter(Boolean);
            }
          })()
        }
      );

      const identifiedStockIDCheck =
        identifiedStockIDCheckRes.data.jsonResponse.IdentifiedStock;

      if (propMap(currentPID).bulkCategoryIDs.includes(productCategoryID)) {
        console.error("RELEASE PC1");

        // 벌크제품일 경우일 경우, 산출자재 동종재고 체크

        if (order.identifiedStockTypeCode) {
          const fItem = identifiedStockIDCheck.find(sItem => {
            return (
              sItem.IdentifiedStockID._value_1 === order.productNo &&
              sItem.ProductID._value_1 === order.productID &&
              parseInt(sItem.LifeCycleStatusCode.LifeCycleStatusCode) === 2
            ); // 동종재고 Active, 동종재고ID 일치여부, 제품ID 일치여부 확인
          });

          if (!fItem) {
            console.error(order.productNo);
            throw new Error(
              `${order.productNo}은 유효하지 않은 제조번호(LOT)입니다.`
            );
          }
        }
      } else if (order.specification) {
        console.error("RELEASE PC2");
        // 벌크제품이 아니나, 착인사양이 입력된경우, 산출자재 동종재고 및 하위 벌크자제 체크

        if (order.identifiedStockTypeCode) {
          const fItem = identifiedStockIDCheck.find(sItem => {
            return (
              sItem.IdentifiedStockID._value_1 === order.productNo &&
              sItem.ProductID._value_1 === order.productID &&
              parseInt(sItem.LifeCycleStatusCode.LifeCycleStatusCode) === 2
            ); // 동종재고 Active, 동종재고ID 일치여부, 제품ID 일치여부 확인
          });

          if (!fItem) {
            console.error(order.productNo);
            throw new Error(
              `${order.productNo}은 유효하지 않은 제조번호(LOT)입니다.`
            );
          }
        }

        order.releaseUIData.inputBulkItemList.forEach(item => {
          const matTypeCode = item.IdentifiedStockTypeCode
            ? parseInt(item.IdentifiedStockTypeCode._value_1)
            : undefined;
          if (matTypeCode && matTypeCode === 4) {
            // 필수지정 동종재고, 사용자가 입력한 동종재고 유효성 검사

            const fItem = identifiedStockIDCheck.find(sItem => {
              return (
                sItem.IdentifiedStockID._value_1 ===
                  item.productionNumberCustom &&
                sItem.ProductID._value_1 === item.materialID &&
                parseInt(sItem.LifeCycleStatusCode.LifeCycleStatusCode) === 2
              ); // 동종재고 Active, 동종재고ID 일치여부, 제품ID 일치여부 확인
            });

            if (!fItem && item.productionNumberCustom) {
              console.error(item);
              throw new Error(
                `${item.productionNumberCustom}은 유효하지 않은 제조번호(LOT)입니다.`
              );
            }
          }
        });
      } else {
        console.error("RELEASE PC3");
        // 벌크제품이 아닐경우, 하위벌크자재 동종재고 체크

        order.releaseUIData.inputBulkItemList.forEach(item => {
          const matTypeCode = item.identifiedStockTypeCode
            ? parseInt(item.identifiedStockTypeCode)
            : undefined;
          if (matTypeCode && matTypeCode === 4) {
            // 필수지정 동종재고, 사용자가 입력한 동종재고 유효성 검사

            const fItem = identifiedStockIDCheck.find(sItem => {
              return (
                sItem.IdentifiedStockID._value_1 ===
                  item.productionNumberCustom &&
                sItem.ProductID._value_1 === item.materialID &&
                parseInt(sItem.LifeCycleStatusCode.LifeCycleStatusCode) === 2
              ); // 동종재고 Active, 동종재고ID 일치여부, 제품ID 일치여부 확인
            });

            if (!fItem && item.productionNumberCustom) {
              console.error(item);
              throw new Error(
                `${item.productionNumberCustom}은 유효하지 않은 제조번호(LOT)입니다.`
              );
            }
          }
        });
      }
    };

    // 오더 산출자재 및 데이터 저장

    const updateLogisticsAeaAndMaterials = async order => {
      // 0088 생산오더 릴리즈시 투입자재의 물류영역을 리소스 물류영역으로 변경 (리소스 확장필드 참조),
      // 확장필드에 데이터가 없으면 물류영역을 바꿉니다.

      const productCategoryID = order.productCategoryID;
      const targetResourceData = await getProductionResourcesBySite({
        resourceID: order.resourceID
      });

      const targetResource = targetResourceData ? targetResourceData[0] : [];

      const user = getState().user.currentUser;
      const report = extMap(user.pid).reports
        .bsg_logistics_area_resources_information;
      const ext = report.extProps;
      let extLogisticsArea = "";

      if (targetResource) {
        extLogisticsArea = targetResource[ext.extLogisticsArea];
      }

      if (propMap(currentPID).bulkCategoryIDs.includes(productCategoryID)) {
        const mainOutputMaterial = order.outputMaterials.find(item => {
          return item.ProductID._value_1 === order.productID;
        });

        return maintainBundle_V1({
          payload: {
            BasicMessageHeader: "",
            ProductionOrder: {
              ProductionOrderUUID: order.productionOrderObjectID.replace(
                /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                "$1-$2-$3-$4-$5"
              ),
              ...(() => {
                if (order.identifiedStockTypeCode) {
                  return {
                    MaterialOutput: {
                      MaterialOutputUUID:
                        mainOutputMaterial.MaterialOutputUUID._value_1,
                      IdentifiedStockID: order.productNo
                    }
                  };
                }
              })(),
              ...(() => {
                if (extLogisticsArea) {
                  return {
                    MaterialInput: order.inputMaterials.map(item => {
                      return {
                        MaterialInputUUID: item.MaterialInputUUID._value_1,
                        LogisticsAreaID: extLogisticsArea
                      };
                    })
                  };
                }
              })()
            }
          }
        });
      } else if (order.specification) {
        const mainOutputMaterial = order.outputMaterials.find(item => {
          return item.ProductID._value_1 === order.productID;
        });

        await maintainBundle_V1({
          payload: {
            BasicMessageHeader: "",
            ProductionOrder: {
              ProductionOrderUUID: order.productionOrderObjectID.replace(
                /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                "$1-$2-$3-$4-$5"
              ),
              ...(() => {
                if (order.identifiedStockTypeCode) {
                  return {
                    MaterialOutput: {
                      MaterialOutputUUID:
                        mainOutputMaterial.MaterialOutputUUID._value_1,
                      IdentifiedStockID: order.productNo
                    }
                  };
                }
              })(),
              ...(() => {
                const bomBulkItems = order.releaseUIData.inputBulkItemList;
                const materialToUpdate = [];

                order.inputMaterials.forEach(item => {
                  const isBulkItem = bomBulkItems.find(sItem => {
                    return (
                      // item.ProductID._value_1 === sItem.InternalID._value_1
                      item.ProductID._value_1 === sItem.materialID
                    );
                  });

                  if (!isBulkItem) {
                    if (extLogisticsArea) {
                      materialToUpdate.push({
                        MaterialInputUUID: item.MaterialInputUUID._value_1,
                        LogisticsAreaID: extLogisticsArea
                      });
                    }
                  }
                });

                return { MaterialInput: materialToUpdate };
              })()
            }
          }
        });

        return maintainBundle_V1({
          payload: {
            BasicMessageHeader: "",
            ProductionOrder: {
              ProductionOrderUUID: order.productionOrderObjectID.replace(
                /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                "$1-$2-$3-$4-$5"
              ),
              ...(() => {
                const bomBulkItems = order.releaseUIData.inputBulkItemList;
                const materialToUpdate = [];

                order.inputMaterials.forEach(item => {
                  const isBulkItem = bomBulkItems.find(sItem => {
                    return (
                      // item.ProductID._value_1 === sItem.InternalID._value_1
                      item.ProductID._value_1 === sItem.materialID
                    );
                  });

                  if (isBulkItem) {
                    materialToUpdate.push({
                      MaterialInputUUID: item.MaterialInputUUID._value_1,
                      ...(() => {
                        if (isBulkItem.productionNumberCustom) {
                          return {
                            IdentifiedStockID: isBulkItem.productionNumberCustom
                          };
                        }
                      })(),
                      ...(() => {
                        if (extLogisticsArea) {
                          return { LogisticsAreaID: extLogisticsArea };
                        }
                      })()
                    });
                  }
                });

                return { MaterialInput: materialToUpdate };
              })()
            }
          }
        });
      } else {
        // 투입자재중 벌크제품을 동종제고를 기입하고, 생산오더를 업데이트 합니다.
        // BYD의 알수없는 오류로, 동종재고가 있는 케이스 와 없는 케이스를 분리해서 요청을 두번 실행합니다.

        await maintainBundle_V1({
          payload: {
            BasicMessageHeader: "",
            ProductionOrder: {
              ProductionOrderUUID: order.productionOrderObjectID.replace(
                /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                "$1-$2-$3-$4-$5"
              ),
              ...(() => {
                const bomBulkItems = order.releaseUIData.inputBulkItemList;
                const materialToUpdate = [];
                order.inputMaterials.forEach(item => {
                  const isBulkItem = bomBulkItems.find(sItem => {
                    return (
                      // item.ProductID._value_1 === sItem.InternalID._value_1
                      item.ProductID._value_1 === sItem.materialID
                    );
                  });

                  if (!isBulkItem) {
                    if (extLogisticsArea) {
                      materialToUpdate.push({
                        MaterialInputUUID: item.MaterialInputUUID._value_1,
                        LogisticsAreaID: extLogisticsArea
                      });
                    }
                  }
                });

                return { MaterialInput: materialToUpdate };
              })()
            }
          }
        });

        return maintainBundle_V1({
          payload: {
            BasicMessageHeader: "",
            ProductionOrder: {
              ProductionOrderUUID: order.productionOrderObjectID.replace(
                /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                "$1-$2-$3-$4-$5"
              ),
              ...(() => {
                const bomBulkItems = order.releaseUIData.inputBulkItemList;
                const materialToUpdate = [];

                order.inputMaterials.forEach(item => {
                  const isBulkItem = bomBulkItems.find(sItem => {
                    return (
                      // item.ProductID._value_1 === sItem.InternalID._value_1
                      item.ProductID._value_1 === sItem.materialID
                    );
                  });

                  if (isBulkItem) {
                    materialToUpdate.push({
                      MaterialInputUUID: item.MaterialInputUUID._value_1,
                      IdentifiedStockID: isBulkItem.productionNumberCustom,
                      ...(() => {
                        if (extLogisticsArea) {
                          return { LogisticsAreaID: extLogisticsArea };
                        }
                      })()
                    });
                  }
                });

                return { MaterialInput: materialToUpdate };
              })()
            }
          }
        });
      }
    };

    // 오더 릴리즈 및 보충태스크 생성

    const releaseProductionOrder = async order => {
      const releaseOrderRes = await postReleaseProductionOrderProcess({
        orderUUIDs: [order.productionOrderObjectID],
        dispatch: dispatch
      });

      // 릴리즈된 오더 ID 조회

      const orderIDs = [];
      releaseOrderRes.forEach(value => {
        const response = value.data.jsonResponse.body.d.results;
        orderIDs.push(response.ID);
        releasedOrderIDs.push(response.ID);
      });

      // 생산오더 릴리즈상태가 반환 안되는 경우 있어서 강제 딜레이
      await new Promise(resolve => setTimeout(resolve, 1500));

      const productCategoryID = order.productCategoryID;
      if (
        // 벌크(201), 베이스(202), 희석액(206)이 아닐 때만 창고요청(보충 생성)
        !propMap(currentPID).inputBulkCategoryIDs.includes(productCategoryID)
      ) {
        await manageLogisticsLayoutExecutionViewIn.maintainBundle_V1({
          productionOrderIDs: orderIDs
        }); // 보충태스크 생성 요청
      }
    };

    // 릴리즈된 오더 데이터 매핑

    const mapReleasedProductionOrder = async () => {
      const releasedProductionOrdersRes = await productionOrderQueryByOverview({
        proposalIDs: [],
        orderIDs: releasedOrderIDs
      });

      const releasedProductionOrders =
        releasedProductionOrdersRes.data.jsonResponse.ProductionOrder;

      for (const order of releasedProductionOrders) {
        const fItemIdx = items.findIndex(item => {
          return (
            `${item.productionProposalID}` ===
            `${order.ProductionOrderRequestID._value_1}`
          );
        });

        if (fItemIdx >= 0) {
          // 생산오더 상태코드가 4 이상은 종료됨 혹은 취소됨 상태

          if (parseInt(order.ProductionOrderStatus) >= 4) {
            // 종료됨 혹은 취소됨 상태의 경우 데이터를 삭제하고 따로 조회하지 않음

            items.splice(fItemIdx, 1);
          } else {
            // 생산오더의 정보를 기존 데이터에 매핑한다.

            items[fItemIdx] = {
              ...items[fItemIdx],
              ...{
                productionOrderID: order.ProductionOrderID._value_1,
                productionOrderStatusID: parseInt(order.ProductionOrderStatus),
                productionOrderStatusText: ((
                  statusCode = parseInt(order.ProductionOrderStatus)
                ) => {
                  switch (statusCode) {
                    case 1:
                      return "준비중";
                    case 2:
                      return "릴리즈됨";
                    case 3:
                      return "시작됨";
                    case 4:
                    case 5:
                      return "종료됨";
                    case 6:
                      return "취소됨";
                    default:
                      return statusCode;
                  }
                })()
              },
              ...{
                editable: false // 생산오더 릴리즈 후, 수정 불가로 변경
              },
              ...{
                specification: order.SPECIFICATION,
                scheduleData: ((
                  startDateTime = query.productionStartDate,
                  schedule = order.Schedule
                ) => {
                  let returnObj = {};

                  Array.from({ length: 15 }, (value, index) => {
                    const startDate = moment(startDateTime, "YYYY-MM-DD")
                      .add(index, "days")
                      .format("YYYY-MM-DD");
                    return (returnObj[`${startDate}`] = {
                      quantity: "",
                      numResource: ""
                    });
                  });

                  if (schedule) {
                    try {
                      const scheduleData = JSON.parse(schedule);
                      returnObj = {
                        resourceID: scheduleData.resourceID,
                        data: { ...returnObj.data, ...scheduleData.data }
                      };
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  return returnObj;
                })()
              }
            };
          }
        }
      }
    };

    // 다이얼로그를 통해 입력받은 투입벌크 LOT 값 유효성 체크

    try {
      await checkInputBulkIdentfiedStockIDs(productionOrders);
    } catch (error) {
      console.error(error);
      return callback && callback.afterFailed
        ? callback.afterFailed(false, error)
        : console.error(error);
    }

    for (const [index, order] of productionOrders.entries()) {
      try {
        window.sap.m.MessageToast.show(
          `생산오더 ${order.productionOrderID} 릴리즈중  ${index + 1}/${
            productionOrders.length
          }`
        );

        // 동종재고 생성 및 유효성 검사
        await identifiedStockCheck(order);

        // 현재 오더 데이터 (스케쥴 및 리소스) 정보 저장
        await patchSingleproductionOrderProcess({ ...order, dispatch });

        // 투입자재 및 산출자재 동종재고 입력, 리소스 물류영역 변경
        await updateLogisticsAeaAndMaterials(order);

        // 생산오더 릴리즈
        await releaseProductionOrder(order);
      } catch (error) {
        console.error(error);
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      }
    }

    try {
      // 릴리즈된 데이터 재조회

      await mapReleasedProductionOrder();
      dispatch({
        type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
        payload: items
      });
    } catch (error) {
      const errorMessage = "생산오더 릴리즈중 에러가 발생하였습니다.";
      console.error(error);
      return callback && callback.afterFailed
        ? callback.afterFailed(false, errorMessage)
        : console.error(error);
    }

    if (callback && callback.afterSucceed) {
      const oderIDs = productionOrders
        .map(item => {
          return item.productionOrderID;
        })
        .join(", ");
      callback.afterSucceed(true, `생산오더 (${oderIDs})가 릴리즈되었습니다.`);
    }
  };
};

/**
 * 생산제안을 삭제합니다.
 * @param {*} params
 * @param {*} callback
 */

export const deleteProductionProposal = (params, callback) => {
  return (dispatch, getState) => {
    const items = getState().productionPlan.pp_schedule.productionSchedule;
    deleteProductionProposalProcess({ ...params, dispatch: dispatch })
      .then(() => {
        const fItemIdx = items.findIndex(item => {
          return (
            `${item.productionProposalObjectID}` ===
            `${params.productionProposalUUID}`
          );
        });

        if (fItemIdx) {
          items.splice(fItemIdx, 1);
        }

        dispatch({
          type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
          payload: items
        });

        return callback
          ? callback.afterSucceed(true, "생산제안이 삭제 되었습니다.")
          : "";
      })
      .catch(error => {
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      });
  };
};

export const patchProductionOrderInputMaterials = (params, callback) => {
  if (!params.bulkItems || params.bulkItems.length <= 0) {
    return callback
      ? callback.afterSucceed(true, "업데이트할 투입자재가 없습니다.")
      : "";
  }

  return (dispatch, getState) => {
    maintainBundle_V1({ ...params, dispatch: dispatch })
      .then(() => {
        return callback
          ? callback.afterSucceed(
              true,
              "생산오더 투입자재 업데이트가 완료되었습니다."
            )
          : "";
      })
      .catch(error => {
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      });
  };
};

/**
 * 생산오더를 삭제합니다.
 * Note. 실제 오더는 삭제되나, ByD 특성상 생산요청은 남아있게 되며, '실행 요처됨' 상태 그대로 유지되고
 * 양후에는 수동으로 생산오더를 생성하거나, 수동으로 생산요청을 삭제 해야 합니다.
 * @param {*} params
 * @param {*} callback
 */

export const deleteProductionOrder = (params, callback) => {
  return (dispatch, getState) => {
    const items = getState().productionPlan.pp_schedule.productionSchedule;
    deleteProductionOrderProcess({ ...params, dispatch: dispatch })
      .then(() => {
        const fItemIdx = items.findIndex(item => {
          return (
            `${item.productionOrderObjectID}` ===
            `${params.productionOrderUUID}`
          );
        });

        const item = items[fItemIdx];
        const proposalID = item.productionProposalID;

        return getProductionProposalProcess({
          searchType: 3,
          keyword: proposalID
        });
      })
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;
        const proposalObject = Array.isArray(response) ? response[0] : response;

        const productionSchedule = getState().productionPlan.pp_schedule
          .productionSchedule;

        const dataToDispatch = productionSchedule.map((item, value) => {
          const origProposalID = productionSchedule[value].productionProposalID;
          const targetProposalID = proposalObject.ProposalID;

          if (origProposalID === targetProposalID) {
            return {
              // num: index + 1,

              productionProposalObjectID: proposalObject.ParentObjectID,
              productionOrderObjectID: "",
              siteID: proposalObject.SupplyPlanningAreaID, // SupplyPlanningArea
              siteText: proposalObject.SupplyPlanningAreaName,

              // ProductionPlanningOrder
              productionProposalID: proposalObject.ProposalID,
              productionOrderID: "", // ProductionOrder

              // ProductionOrder
              productionOrderStatusID: proposalObject.LifeCycleStatusCode,

              // ProductionOrder
              // prettier-ignore
              productionOrderStatusText: 
                `(${proposalObject.LifeCycleStatusCodeText})`,
              salesOrderID: proposalObject.SOID_01_KUT, // Extension Field
              productID: proposalObject.InternalID, // MaterialID(ProductID)
              // prettier-ignore
              orderQuantity: 
                parseFloat(proposalObject.Quantity).toFixed(2), // Quantity
              productionQuantity: "",
              quantityUnitText: proposalObject.unitCodeText,
              quantityUnit: proposalObject.unitCode, // Production Order
              productionStartDate: moment(proposalObject.StartDateTime).format(
                "YYYY-MM-DD"
              ),
              productDescription:
                proposalObject.FULL_NAME_KUT || proposalObject.ItemDescription,
              materialAvailabilityDateTime: moment(
                proposalObject.AvailabilityDateTime
              ).format("YYYY-MM-DD"),
              shipmentDate: "",
              resourceID: "",
              resourceText: "",
              supervisor: "",
              resourceList: [{ resourceID: "", resourceText: "" }],
              operation: [],
              inputMaterials: [],
              editable: (() => {
                if (parseInt(proposalObject.LifeCycleStatusCode) === 1) {
                  return true;
                }

                return false;
              })(),
              ...((
                startDateTime = params.productionStartDate,
                schedule = proposalObject.Schedule_KUT
              ) => {
                let returnObj = {};

                Array.from({ length: 15 }, (value, index) => {
                  const startDate = moment(startDateTime, "YYYY-MM-DD")
                    .add(index, "days")
                    .format("YYYY-MM-DD");
                  return (returnObj[`${startDate}`] = {
                    quantity: "",
                    numResource: ""
                  });
                });

                if (schedule) {
                  try {
                    returnObj = { ...returnObj, ...JSON.parse(schedule) };
                  } catch (error) {
                    returnObj = {};
                  }
                }
                return {
                  scheduleData: returnObj
                };
              })()
            };
          }

          return item;
        });

        dispatch({
          type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
          payload: dataToDispatch
        });

        return callback
          ? callback.afterSucceed(true, "생산오더가 삭제 되었습니다.")
          : "";
      })
      .catch(error => {
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      });
  };
};

/**
 * 생산일정 스케쥴 데이터를 업데이트 하거나, 생산제안을 생성하는 태스크를 처리합니다.
 * 생산오더 및 생산제안은 Custom Business Object를 이용하여, Batch Update를 실행하고 있습니다.
 * @param {*} params
 * @param {*} callback
 */

export const postSaveScheduleAction = (params, callback) => {
  const {
    createProductionProposals,
    updateProductionProposals,
    updateProductionOrders
  } = params;

  return (dispatch, getState) => {
    const promiseArray = [];

    if (updateProductionOrders && updateProductionOrders.length > 0) {
      console.warn("updateProductionOrders");
      updateProductionOrders.forEach(order => {
        if (!order.productionOrderObjectID) {
          const message =
            `생산오더 ${order.productionOrderID}를 저장할 수 없습니다.` +
            "오더의 Object ID가 존재하지 않습니다.";
          console.error(message);
          throw new Error(message);
        }
      });
      // WARN : 절대로 BYD SOAP 요청으로 생산오더 확장필드를 업데이트하지 말 것, BYD 버그로 인해 수정이 안됨.
      // 스탠다드 필드 업데이트 수행 (스탠다드BO SOAP 사용)

      const updateProductionOrderPromise = updateProductionOrders.map(
        (item, index) => {
          return maintainBundle_V1({
            payload: {
              BasicMessageHeader: {},
              ProductionOrder: {
                ProductionOrderUUID: item.productionOrderObjectID.replace(
                  /(.{8})(.{4})(.{4})(.{4})(.{12})/,
                  "$1-$2-$3-$4-$5"
                ),
                PlannedQuantity: item.orderQuantity,
                EarliestStartPeriod: moment(item.productionStartDate).format(
                  "YYYY-MM-DDT09:00:00.0000000Z"
                )
              }
            }
          }).then(res => {
            const serverityCode = tryit(
              () => res.data.jsonResponse.Item[0].SeverityCode
            );
            const itemLength = tryit(() => res.data.jsonResponse.Item.length);
            if (serverityCode === "S" && itemLength === 1) {
              // succeed
              return patchProductionOrdersProcess({
                productionOrderObjects: [updateProductionOrders[index]],
                dispatch: dispatch
              });
            }
          });
        }
      );

      promiseArray.push(...updateProductionOrderPromise);
    }

    if (updateProductionProposals && updateProductionProposals.length > 0) {
      console.warn(updateProductionProposals);
      promiseArray.push(
        patchProductionProposalsProcess({
          productionProposalObjects: updateProductionProposals,
          dispatch: dispatch
        })
      );
    }

    if (createProductionProposals && createProductionProposals.length > 0) {
      console.warn("createProductionProposals");
      promiseArray.push(
        new Promise((resolve, reject) => {
          let createdProposals = [];

          postProductionProposalProcess({
            items: createProductionProposals,
            dispatch: dispatch
          })
            .then(res => {
              return getProductionProposalProcess({
                searchType: 4,
                keyword: res.proposalIDs
              });
            })
            .then(res => {
              const reqData = createProductionProposals;
              const resData = res.data.jsonResponse.body.d.results;
              const origData =
                getState().productionPlan.pp_schedule.productionSchedule || [];
              const queryData = getState().productionPlan.pp_schedule
                .productionScheduleQuery;

              reqData.forEach((reqItem, reqIdx) => {
                const origIdx = origData.findIndex(origItem => {
                  return (
                    parseInt(origItem.productionProposalID) ===
                    parseInt(reqItem.origProposalID)
                  );
                });

                if (origIdx >= 0) {
                  const resItem = resData[reqIdx];
                  const dataToInsert = {
                    // num: index + 1,
                    objectID: resItem.ObjectID,
                    productionProposalObjectID: resItem.ParentObjectID,
                    productionOrderObjectID: "",
                    siteID: resItem.SupplyPlanningAreaID, // SupplyPlanningArea
                    siteText: resItem.SupplyPlanningAreaName,

                    // ProductionPlanningOrder
                    productionProposalID: resItem.ProposalID,
                    productionOrderID: "", // ProductionOrder

                    // ProductionOrder
                    productionOrderStatusID: resItem.LifeCycleStatusCode,

                    // prettier-ignore
                    productionOrderStatusText: 
                      `(${resItem.LifeCycleStatusCodeText})`, // ProductionOrder
                    salesOrderID: resItem.SOID_01_KUT, // Extension Field
                    productID: resItem.InternalID, // MaterialID(ProductID)
                    // prettier-ignore
                    orderQuantity: 
                      parseFloat(resItem.Quantity).toFixed(2), // Quantity
                    productionQuantity: "",
                    quantityUnitText: resItem.unitCodeText,
                    quantityUnit: resItem.unitCode, // Production Order
                    productionStartDate: moment(resItem.StartDateTime).format(
                      "YYYY-MM-DD"
                    ),
                    productDescription:
                      resItem.FULL_NAME_KUT || resItem.ItemDescription,
                    materialAvailabilityDateTime: moment(
                      resItem.AvailabilityDateTime
                    ).format("YYYY-MM-DD"),
                    shipmentDate: "",
                    resourceID: "",
                    resourceText: "",
                    resourceList: [],
                    operation: [],
                    editable: (() => {
                      if (parseInt(resItem.LifeCycleStatusCode) === 1) {
                        return true;
                      }

                      return false;
                    })(),
                    ...((
                      startDateTime = queryData.productionStartDate,
                      itemStartDateTime = moment(resItem.StartDateTime).format(
                        "YYYY-MM-DD"
                      ),
                      itemOrderQuantity = parseFloat(resItem.Quantity).toFixed(
                        2
                      ),
                      schedule = resItem.Schedule_KUT
                    ) => {
                      let returnObj = {
                        resourceID: "",
                        data: {}
                      };

                      Array.from({ length: 15 }, (value, index) => {
                        const startDate = moment(startDateTime, "YYYY-MM-DD")
                          .add(index, "days")
                          .format("YYYY-MM-DD");
                        return (returnObj.data[`${startDate}`] = {
                          quantity: "",
                          numResource: ""
                        });
                      });

                      if (schedule) {
                        try {
                          const nObject = JSON.parse(schedule);

                          if (Object.keys(nObject).length > 0) {
                            returnObj = {
                              ...returnObj,
                              ...JSON.parse(schedule)
                            };
                          } else {
                            const startDate = moment(
                              itemStartDateTime,
                              "YYYY-MM-DD"
                            ).format("YYYY-MM-DD");
                            returnObj = { ...returnObj };

                            returnObj.data[`${startDate}`] = {
                              quantity: itemOrderQuantity,
                              numResource: ""
                            };
                          }
                        } catch (error) {
                          console.error(
                            "Fail Spreading Schedule_KUT at Proposal:" +
                              resItem.ProposalID
                          );
                        }
                      }

                      return {
                        scheduleData: returnObj
                      };
                    })()
                  };

                  origData.splice(origIdx + 1, 0, dataToInsert);
                }
              });

              createdProposals = [...resData];

              // 생산제안ID가 자동생성 인 항목 삭제

              dispatch({
                type: actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS,
                payload: origData.filter(item => {
                  return item.productionProposalID !== "자동생성";
                })
              });

              // 생성된 생산제안을 릴리즈
              return dispatch(
                postReleaseProductionProposal({
                  proposalUUIDs: createdProposals.map(item => item.ObjectID)
                })
              );
            })
            .then(productionOrders => {
              resolve(productionOrders);
            })
            .catch(error => {
              console.error(error);
              reject(error);
            });
        })
      );
    }

    Promise.all(promiseArray)
      .then(() => {
        return callback
          ? callback.afterSucceed(true, "저장이 완료되었습니다.")
          : Promise.resolve();
      })
      .catch(error => {
        console.error(error);
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : Promise.reject(error);
      });
  };
};

/**
 * 조회된 모든 데이터를 삭제 합니다.
 * 사이트 리스트는 삭제되지 않습니다.
 */

export const clearScheduleData = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actions.PP_SCHEDULE_CLEAR_PRODUCTION_PROPOSALS_AND_ORDER
    });
  };
};

/**
 * 생산모델 및 BOM 자재정보 조회 (단일)
 * @param {*} params
 * @param {*} callback
 */

export const getProductionBomViewAndQueryProcess = (params = {}, callback) => {
  return async (dispatch, getState) => {
    const currentPID = getState().user.currentUser.pid;

    return new Promise((resolve, reject) => {
      const outputMaterialID = params.productID; // Output Material Information
      const inputMaterialsIDs = [
        ...params.inputMaterials.map(item => {
          return item.ProductID._value_1;
        }),
        ...[outputMaterialID]
      ]; // Input Material Information

      // Return Data Object (This is where SOAP responese are stored)
      const prodItem = {};

      manageProdModelIn
        .readProductionModel({ modelIDs: [params.bomModel] })
        .then(async res => {
          const response = res.data
            ? res.data.jsonResponse.ProductionModel
            : [];

          if (response.length > 0) {
            const bom = response[0].BillOfMaterial;
            const bomID = bom.BillOfMaterialID;
            const bomVariantID = bom.BillOfMaterialVariantID;

            const bomRes = await productionBillOfMaterialManagementIn.readBOM({
              BOMIDs: [bomID]
            });
            const bomResponse = bomRes.data
              ? bomRes.data.jsonResponse.ProductionBillOfMaterial
              : [];

            if (bomResponse.length > 0) {
              const bomVariants = bomResponse[0].Variant;
              const bomVariant = bomVariants.find(item => {
                return item.VariantID === bomVariantID;
              });

              try {
                const bomInstruction =
                  bomVariant.TextCollectionTextCollection.Text[0].TextContent
                    .Text._value_1;
                params.bomInstruction = bomInstruction;
              } catch (error) {
                console.warn(
                  `생산모델 BOM: ${bomID}에 공정지시사항이 없습니다.`
                );
              }

              params.outputBomModel = bomResponse[0];
            } else {
              throw new Error("생산BOM 조회에 실패했습니다.");
            }
          } else {
            throw new Error("생산모델 조회에 실패했습니다.");
          }

          return queryProductionLotISIIn.findByElements({
            productionOrderIDs: [params.productionOrderID]
          });
        })
        .then(res => {
          const response = res.data ? res.data.jsonResponse.ProductionLot : {};
          prodItem.weighting = response;

          return readProductionOrder({
            productionOrderIds: [params.productionOrderID]
          });
        })
        .then(res => {
          const response = res.data.jsonResponse.ProductionOrder[0];

          prodItem.prodOrder = response;

          return QueryMaterialIn.findByElements({
            internalIDs: inputMaterialsIDs
          });
        })
        .then(async res => {
          const response = res.data ? res.data.jsonResponse.Material : {};

          if (response <= 0) {
            return callback
              ? callback.afterFailed(true, response)
              : "No Input Material Found";
          }

          // Store Output Material Detail

          const outputMaterial = response.find(
            item => outputMaterialID === item.InternalID._value_1
          );
          prodItem.Item = outputMaterial;

          // Store Input Material Detail

          const inputMaterial = response.filter(
            item => inputMaterialsIDs.indexOf(item.InternalID._value_1) >= 0
          );
          prodItem.materialItems = inputMaterial.filter(
            item =>
              item.InternalID._value_1 !== outputMaterial.InternalID._value_1
          );
          prodItem.bulkItems = inputMaterial.filter(
            item =>
              propMap(currentPID).bulkCategoryIDs.includes(
                item.ProductCategoryID
              ) &&
              item.InternalID._value_1 !== outputMaterial.InternalID._value_1
          );

          prodItem.instruction = params.bomInstruction;

          await queryProductionBom({
            productID: params.productID,
            params: {
              $expand: [
                "Item",
                "Product",
                "Item/ProductionPlanningOrder",
                "Item/PurchaseRequest"
              ]
            }
          });
          const prodBom = getState().getOData("ProductionBOM");
          const rootItem = prodBom[0].bomItems.find(
            item => item.materialID === params.productID
          );
          if (!rootItem) {
            throw new Error(
              `BOM 모델에서 산출품${params.productID}을 찾을 수 없습니다.`
            );
          }
          const bomItemMatIDs = prodBom[0].bomItems.map(
            item => item.materialID
          );

          const inputBulks = rootItem.inputBulks
            .map((inputBulk, index) => {
              if (!inputBulk.materialID) return null;
              inputBulk.num = index;
              if (!bomItemMatIDs.includes(inputBulk.materialID)) {
                // input bulk 중 bom items에 없는 것들은 수량을 표시하지 않는다.
                return {
                  ...inputBulk,
                  displayAmount: "",
                  displayAmountUnitCode: "",
                  chargeAmount: "",
                  chargeAmountUnitCode: ""
                };
              }
              return inputBulk;
            })
            .filter(Boolean);

          prodItem.inputBulk = inputBulks;

          return callback ? callback.afterSucceed(true, prodItem) : "";
        })
        .catch(error => {
          const errorMessage = error.Exception ? error.Exception : error;
          console.error(errorMessage);
          return callback
            ? callback.afterFailed(true, errorMessage)
            : errorMessage;
        });
    });
  };
};

/**
 * 생산오더 릴리즈 자재정보 조회 (복수)
 * @param {*} params
 * @param {*} callback
 */

export const getProductionOrderMaterials = (params = {}, callback) => {
  return async (dispatch, getState) => {
    const currentPID = getState().user.currentUser.pid;
    return new Promise(async (resolve, reject) => {
      const productionOrders = params.productionOrders;
      const materialsAll = [
        ...new Set([
          ...productionOrders.flatMap(item =>
            item.inputMaterials.map(mat => mat.ProductID._value_1)
          ),
          ...productionOrders.map(item => item.productID)
        ])
      ];

      const getQueryMaterialIn = async () => {
        const matRes = await QueryMaterialIn.findByElements({
          internalIDs: materialsAll
        });

        const matResponse = matRes.data
          ? matRes.data.jsonResponse.Material
          : {};

        if (matResponse <= 0) {
          return callback
            ? callback.afterFailed(true, matResponse)
            : "No Input Material Found";
        }

        productionOrders.forEach(targetOrder => {
          const outputMaterial = matResponse.find(
            item => targetOrder.productID === item.InternalID._value_1
          );

          const inputMaterialsIDs = [
            ...targetOrder.inputMaterials.map(item => {
              return item.ProductID._value_1;
            })
          ];

          const inputMaterial = matResponse.filter(
            item => inputMaterialsIDs.indexOf(item.InternalID._value_1) >= 0
          );

          const bulkItems = inputMaterial.filter(
            item =>
              propMap(currentPID).inputBulkCategoryIDs.includes(
                item.ProductCategoryID
              ) &&
              item.InternalID._value_1 !== outputMaterial.InternalID._value_1
          );

          targetOrder.releaseUIData = {
            num: targetOrder.num,
            productionOrderID: targetOrder.productionOrderID,
            specification: "",
            // outputMaterial:{
            //   materialID : outputMaterial.InternalID._value_1
            // },
            // inputMaterialList: inputMaterial.map(inputItem=>{
            //   return {
            //     materialID: inputItem.InternalID._value_1
            //   };
            // }),
            inputBulkItemList: bulkItems.map(bulkItem => {
              return {
                materialID: bulkItem.InternalID._value_1,
                description: bulkItem.Description[0]
                  ? bulkItem.Description[0].Description._value_1
                  : "",
                identifiedStockTypeCode:
                  bulkItem.IdentifiedStockTypeCode._value_1,
                productionNumberCustom: ""
              };
            })

            // Item: outputMaterial,
            // materialItems: inputMaterial,
            // bulkItems: bulkItems
          };
        });
      };

      try {
        await getQueryMaterialIn();

        return callback ? callback.afterSucceed(true, productionOrders) : "";
      } catch (error) {
        const errorMessage = error.Exception ? error.Exception : error;
        console.error(errorMessage);
        return callback
          ? callback.afterFailed(true, errorMessage)
          : errorMessage;
      }
    });
  };
};

export const getMaterialProcess = (params = { materialID: "" }, callback) => {
  return (dispatch, getState) => {
    return newProcess("getMaterialProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "getMaterialProcess",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom("bsg_material/MaterialCollection", {
                  $filter: `InternalID eq '${params.materialID}'`
                })
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            return [true, body];
          };
        }
      })
      .start()
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;
        return callback ? callback.afterSucceed(true, response) : "";
      })
      .catch(error => {
        return callback && callback.afterFailed
          ? callback.afterFailed(false, error)
          : console.error(error);
      });
  };
};

export const printWorkOrderReport = (params = {}, callback) => {
  return async (dispatch, getState) => {
    return newProcess("printWorkOrderReport", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "reportoutput",
              subAction: "printWorkOrderReport",
              templateID: "job_order",
              body: {
                ...params,
                outputName: `작업지시서_${params.productionOrderID}`,
                ...{ adjustPixel: false, rowPerPage: 55 }
              }
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;

            // Create Anchor and Automatically Download File via URL
            // (WARN: THIS MANIPULATES GLOBAL DOM)
            // 자동으로 앵커 태그를 생성, 클릭 이벤트를 보내 다운로드를 실행합니다.

            const link = document.createElement("a");
            link.href = body.fileURL;
            link.download = "Download.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return [true, body];
          };
        }
      })
      .start();
  };
};

export const printProdOrderReport = (params = {}, callback) => {
  return async (dispatch, getState) => {
    return newProcess("printProdOrderReport", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "reportoutput",
              subAction: "printProdOrderReport",
              templateID: "production_order",
              body: {
                ...params,
                outputName: `제조지시서_${params.productionOrderID}`,
                ...{ adjustPixel: true }
              }
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;

            // Create Anchor and Automatically Download File via URL
            // (WARN: THIS MANIPULATES GLOBAL DOM)
            // 자동으로 앵커 태그를 생성, 클릭 이벤트를 보내 다운로드를 실행합니다.

            const link = document.createElement("a");
            link.href = body.fileURL;
            link.download = "Download.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return [true, body];
          };
        }
      })
      .start();
  };
};

/**
 * 생산제안 생성 요청 SOAP & ODATA
 * @param {*} params
 */

const postProductionProposalProcess = params => {
  if (!params.items) {
    return new Promise(resolve => resolve("생산제안 항목이 없습니다."));
  }

  return new Promise((resolve, reject) => {
    createBundle(params)
      .then(res => {
        const response = res.data.jsonResponse.ProductionProposalsCreated;
        const items = params.items;
        items.forEach((item, index) => {
          item.productionProposalID =
            response[index].ProductionProposalID._value_1;
        });

        const prepareParams = items.map((item, index) => {
          const origData = items[index];
          const resData = response[index];

          return {
            productionProposalID: resData.ProductionProposalID._value_1,
            productionStartDate: moment(
              origData.productionStartDate,
              "YYYY-MM-DD"
            )
              .add(1, "days")
              .format("YYYY-MM-DD"),
            scheduleData: origData.scheduleData,
            editable: true
          };
        });

        return patchProductionProposalsProcess({
          productionProposalObjects: prepareParams,
          dispatch: params.dispatch
        });
      })
      .then(res => {
        const items = params.items;

        resolve({
          proposalIDs: items.map(item => {
            return item.productionProposalID;
          })
        });
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 생산오더 삭제 요청 ODATA (StandarodSOAP 삭제기능 누락으로 ODATA 사용)
 * @param {*} params
 */

const deleteProductionOrderProcess = params => {
  if (!params.productionOrderUUID) {
    return new Promise((resolve, reject) => reject());
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom("bsg_productionplanningorder/ProductionOrderCollection")
      )
    )();
  };

  const request = params => {
    return newProcess(
      `deleteProductionOrderProcess__${params.productionOrderUUID}`,
      {
        appIsBusy: true
      }
    )
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              // prettier-ignore
              subAction: 
                `deleteProductionOrderProcess__${params.productionOrderUUID}`,
              description: "",
              httpMethod: "DELETE",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningorder/" +
                    `ProductionOrderCollection('${params.productionOrderUUID}')`
                )
              ),
              body: {}
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return request(params);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 생산제안 삭제 요청 ODATA (StandarodSOAP 삭제기능 누락으로 ODATA 사용)
 * @param {*} params
 */

const deleteProductionProposalProcess = params => {
  console.error(params);
  if (!params.productionProposalUUID) {
    return new Promise((resolve, reject) => reject());
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/ProductionPlanningOrderCollection"
        )
      )
    )();
  };

  const request = params => {
    return newProcess(
      `deleteProductionProposalProcess__${params.productionProposalUUID}`,
      {
        appIsBusy: true
      }
    )
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction:
                "deleteProductionProposalProcess__" +
                params.productionProposalUUID,
              description: "",
              httpMethod: "DELETE",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningorder/" +
                    "ProductionPlanningOrderCollection" +
                    `('${params.productionProposalUUID}')`
                )
              ),
              body: {}
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return request(params);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 생산오더 릴리즈 요청 ODATA (StandarodSOAP 릴리즈 기능 누락으로 ODATA 사용))
 * @param {*} params
 */

// eslint-disable-next-line id-length
const postReleaseProductionOrderProcess = params => {
  if (params.orderUUIDs.length <= 0) {
    return new Promise((resolve, reject) => reject("생산오더ID가 없습니다."));
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/" +
            "ProductionPlanningOrderByProductOutputCollection"
        )
      )
    )();
  };

  const request = orderUUID => {
    return newProcess(`postReleaseProductionOrderProcess__${orderUUID}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: `postReleaseProductionOrderProcess__${orderUUID}`,
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningorder/ReleaseProductionOrder",
                  {
                    ObjectID: `'${orderUUID}'`
                  }
                )
              ),
              body: {}
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return Promise.all(
          params.orderUUIDs.map(item => {
            return request(item);
          })
        );
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 생산제안 패치 요청 ODATA (Custom BO)
 * @param {*} params
 */

const patchProductionProposalsProcess = params => {
  const toUpdateObjects = params.productionProposalObjects.filter(item => {
    return item.editable === true;
  });

  if (!toUpdateObjects || toUpdateObjects.length <= 0) {
    return new Promise((resolve, reject) => {
      resolve("저장가능한 생산제안이 없습니다.");
    });
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/" +
            "ProductionPlanningOrderByProductOutputCollection"
        )
      )
    )();
  };

  const request = toUpdateObjects => {
    return newProcess(
      `patchProductionProposalsProcess__${new Date().getTime()}`,
      {
        appIsBusy: true
      }
    )
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              // prettier-ignore
              subAction: 
                `patchProductionProposalsProcess__${new Date().getTime()}`,
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningcontrol/" +
                    "ProductionPlanningControlCollection"
                )
              ),
              body: {
                ProductionSchedule: Array.from(
                  { length: toUpdateObjects.length },
                  (val, idx) => {
                    return {
                      ProductionPlanningOrderID:
                        toUpdateObjects[idx].productionProposalID,
                      PlannedStartDate: `/Date(${moment(
                        toUpdateObjects[idx].productionStartDate,
                        "YYYY-MM-DD"
                      ).valueOf()})/`,
                      ScheduleRawData: [
                        {
                          Text:
                            JSON.stringify(toUpdateObjects[idx].scheduleData) ||
                            JSON.stringify({})
                        }
                      ]
                    };
                  }
                )
              }
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return request(toUpdateObjects);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 단일 생산오더 패치 요청 oData
 */

const patchSingleproductionOrderProcess = (params, cb) => {
  if (params.editable !== true) {
    return new Promise((resolve, reject) => {
      resolve("저장가능한 생산오더가 없습니다.");
    });
  }
  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/" +
            "ProductionPlanningOrderByProductOutputCollection"
        )
      )
    )();
  };

  const request = () => {
    return newProcess("patchSingleproductionOrderProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "patchSingleproductionOrderProcess",
              description: "",
              httpMethod: "PATCH",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionorder/" +
                    "ProductionOrderCollection" +
                    `('${params.productionOrderObjectID}')`
                )
              ),
              body: {
                ...(() => {
                  if (params.scheduleData) {
                    return {
                      Schedule_KUT: JSON.stringify(params.scheduleData)
                    };
                  }
                })(),
                ...(() => {
                  if (params.specification) {
                    return { SPECIFICATION_KUT: params.specification };
                  }
                })(),
                ...(() => {
                  // if (params.productNo && !params.specification) {
                  if (params.productNo) {
                    return { PRODUCT_NO_KUT: params.productNo };
                  }
                })(),
                ...(() => {
                  if (params.resourceID) {
                    return {
                      FIXED_RESOURCE_KUT: params.resourceID
                    };
                  }
                })()
              }
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return request(params);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 생산오더 패치 요청 ODATA (Custom BO)
 * @param {*} params
 */

const patchProductionOrdersProcess = params => {
  const toUpdateObjects = params.productionOrderObjects.filter(item => {
    return item.editable === true;
  });

  if (!toUpdateObjects || toUpdateObjects.length <= 0) {
    return new Promise((resolve, reject) => {
      resolve("저장가능한 생산오더가 없습니다.");
    });
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/" +
            "ProductionPlanningOrderByProductOutputCollection"
        )
      )
    )();
  };

  const request = () => {
    return newProcess("patchProductionOrdersProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "patchProductionOrdersProcess",
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                // 속도 문제로 인해 배치 처리를 위해 CBO 사용
                convURL.custom(
                  "bsg_productionordercontrol/ProductionOrderControlCollection"
                )
              ),
              body: {
                ProductionSchedule: Array.from(
                  { length: toUpdateObjects.length },
                  (val, idx) => {
                    return {
                      ProductionOrderID: toUpdateObjects[idx].productionOrderID,
                      ScheduleRawData: [
                        {
                          Text: JSON.stringify(
                            toUpdateObjects[idx].scheduleData
                          )
                        }
                      ],
                      FixedResource: toUpdateObjects[idx].resourceID,
                      Specification: toUpdateObjects[idx].specification,
                      ProductNo: toUpdateObjects[idx].productionNo,
                      Supervisor: toUpdateObjects[idx].supervisor,
                      SalesOrderID: toUpdateObjects[idx].salesOrderID,
                      StNum: toUpdateObjects[idx].stNum,
                      BOMFixedDate: toUpdateObjects[idx].bomFixedDate,
                      BOMVersion: toUpdateObjects[idx].bomVersion,
                      CustomerName: toUpdateObjects[idx].customerName
                    };
                  }
                )
              }
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    return getToken()
      .then(() => {
        return request(params);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * 사이트 리스트 조회 요청 REPORT (StandarodSOAP 사이트리스트 조회 기능 누락)
 * @param {*} params
 */

const getSupplyPlanningAreaProcess = params => {
  return newProcess("getSupplyPlanningAreaProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;
          const report = extMap(user.pid).reports
            .bsg_purchaseorder_monitoring_report;
          const url = report.url;

          return {
            version: "20190625",
            action: "requestodata",
            subAction: "getSupplyPlanningAreaProcess",
            description: "",
            httpMethod: "GET",
            headers: {},
            url: dispatch(
              convURL.reportANA(url, {
                $select: ["CSHIP_TO_LOC", "TSHIP_TO_LOC"]
              })
            )
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const body = jsonResponse.body;
          const results = body.d.results;

          return [true, results];
        };
      }
    })
    .start();
};

/**
 * 생산제안 조회 요청 ODATA (StandarodSOAP 생산제안 조회기능 없음)
 * @param {*} params
 */

const getProductionProposalProcess = params => {
  if (parseInt(params.searchType) !== 4) {
    if (parseInt(params.searchType) === 3 || params.key_proposal !== true) {
      // 생산오더ID로  조회시, 이 요청으로는 생산오더 조회가 불가능해서 undefined 반환

      return new Promise(resolve => resolve());
    }
  }

  return newProcess(`getProductionProposalProcess__${new Date().getTime()}`, {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        // 릴리즈 된 제안 제외 (계획 상태인 제안만 가져옵니다.)
        const filters = ["LifeCycleStatusCode eq '1'"];
        if (params.keyword) {
          switch (params.searchType && parseInt(params.searchType)) {
            case 1: // 제품 ID별 조회
              filters.push(`(InternalID eq '${params.keyword}')`);
              break;
            case 2: // 판매오더별 조회
              // filters.push(`(SOID_01_KUT eq '${params.keyword}')`);
              filters.push(`substringof('${params.keyword}', SOID_01_KUT)`);
              break;
            case 0: // 생산제안 ID별 조회
              filters.push(`(ProposalID eq '${params.keyword}')`);
              break;
            case 4: // 내부 조회용
              if (Array.isArray(params.keyword)) {
                const ids = params.keyword;
                filters.push(
                  `( ${ids
                    .map(item => {
                      return `(ProposalID eq '${item}')`;
                    })
                    .join(" or ")} )`
                );
              } else {
                filters.push(`(ProposalID eq '${params.keyword}')`);
              }
              break;

            default:
              break;
          }
        }

        // 검색 조건이 판매오더 기준이 아니거나, 판매오더 기준이면서 키워드가 없을경우
        // 판매오더로 조회시, 생산시작일 및 사이트 필터를 무시합니다.

        if (
          parseInt(params.searchType) !== 2 ||
          (parseInt(params.searchType) === 2 && !params.keyword)
        ) {
          if (params.site) {
            filters.push(
              `SupplyPlanningAreaID eq '${params.site.key ||
                params.site ||
                ""}'`
            );
          }

          if (params.productionStartDate) {
            filters.push(
              `((StartDateTime ge datetimeoffset'${moment(
                params.productionStartDate,
                "YYYY-MM-DD"
              ).format(
                "YYYY-MM-DDT00:00:00.000"
              )}Z') and (StartDateTime le datetimeoffset'${moment(
                params.productionEndDate,
                "YYYY-MM-DD"
              ).format("YYYY-MM-DDT23:59:59.000")}Z'))`
            );
          }
        }

        return (dispatch, getState) => {
          return {
            version: "20190625",
            action: "requestodata",
            subAction: `getProductionProposalProcess__${new Date().getTime()}`,
            description: "",
            httpMethod: "GET",
            headers: {},
            url: dispatch(
              convURL.custom(
                "bsg_productionplanningorder/" +
                  "ProductionPlanningOrderByProductOutputCollection",
                {
                  $filter: [filters.filter(Boolean).join(" and ")],
                  $orderby: "StartDateTime asc"
                }
              )
            )
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const body = jsonResponse.body;
          const results = body.d.results;

          // 판매오더로 조회시 substringof를 사용하기 때문에 잘못된 데이터 조회될 수 있음. 잘못된 데이터 필터링
          // 쿼리와 조회된 값은 판매오더ID가 일치하거나 판매오더ID-NNN 형태로 일치해야 함
          // ex) 1조회했는데 41까지 조회되는 경우

          if (params.searchType && parseInt(params.searchType) === 2) {
            if (results.length < 1) return;
            const querySalesOrderID = params.keyword;
            const filteredItems = results.filter(item => {
              let itemSalesOrderID;

              if (!item.SOID_01_KUT) return false;

              if (item.SOID_01_KUT.includes("-")) {
                itemSalesOrderID = item.SOID_01_KUT.split("-")[0];
              } else {
                itemSalesOrderID = item.SOID_01_KUT;
              }
              return itemSalesOrderID === querySalesOrderID;
            });

            jsonResponse.body.d.results = filteredItems;
            jsonResponse.body.d.__count = filteredItems.length;
          }

          return [true, results];
        };
      }
    })
    .start();
};

/**
 * 생산오더 릴리즈 요청 ODATA (StandarodSOAP 생산오더 릴리즈 기능 누락)
 * @param {*} params
 */

// eslint-disable-next-line id-length
const postReleaseProductionProposalProcess = params => {
  if (params.proposalUUIDs && params.proposalUUIDs.length <= 0) {
    return new Promise((resolve, reject) =>
      reject("릴리즈할 생산제안이 하나도 없습니다!")
    );
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          "bsg_productionplanningorder/" +
            "ProductionPlanningOrderByProductOutputCollection"
        )
      )
    )();
  };

  const request = proposalUUID => {
    return newProcess(`postReleaseProductionProposalProcess__${proposalUUID}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              // prettier-ignore
              subAction: 
                `postReleaseProductionProposalProcess__${proposalUUID}`,
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningorder/RequestProduction",
                  {
                    ObjectID: `'${proposalUUID}'`
                  }
                )
              ),
              body: {}
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(async () => {
        const outputData = [];

        for (const item of params.proposalUUIDs) {
          const response = await request(item);
          outputData.push(response);
        }

        return outputData;
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        try {
          const errorMessage = JSON.parse(error.data.body.ResponseError).error
            .message.value;
          reject(errorMessage);
        } catch (parseError) {
          reject(error);
        }
      });
  });
};

/**
 * 사이트별 리소스 전체 조회
 * const response = res.data.jsonResponse.body.d.results;
 * @param {*} params
 */

const getProductionResourcesBySite = (params = {}) => {
  if (!params.dataArr) {
    params.dataArr = [];
  }
  let filter;
  if (params.resourceID) {
    filter = `CRES_UUID eq '${params.resourceID}'`;
  } else {
    filter =
      "(CRESOURCE_CATEGORY_CODE eq '1' or CRESOURCE_CATEGORY_CODE eq '4')";
  }

  const request = () => {
    return newProcess("getProductionResourcesBySite", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const report = extMap(user.pid).reports
              .bsg_logistics_area_resources_information;
            const url = report.url;
            const ext = report.extProps;

            return {
              version: "20190625",
              action: "requestodata",
              subAction: "getProductionResourcesBySite",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.reportANA(url, {
                  $select: [
                    "CSITE_ID_CONTENT",
                    "CRES_UUID",
                    "TRES_UUID",
                    "CID",
                    ext.extLogisticsArea
                  ],
                  $filter: filter,
                  ...(() => {
                    const { count, dataArr } = params;
                    if (count && count > 0) {
                      return {
                        $skip: dataArr.length
                      };
                    }
                  })()
                })
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return request().then(res => {
    const response = res.data.jsonResponse.body.d.results;
    const count = res.data.jsonResponse.body.d.__count;

    params.dataArr = [...params.dataArr, ...response];

    if (params.dataArr.length >= count || response.length <= 0) {
      return new Promise(resolve => resolve(params.dataArr));
    } else {
      return getProductionResourcesBySite({
        ...params,
        ...{
          dataArr: params.dataArr,
          count: count
        }
      });
    }
  });
};

/**
 * 마지막 동종재고 번호 조회
 * @param {*} params
 */

const getLastIdentifiedStock = async (params = {}) => {
  const queryIstock = await newProcess("getLastIdentifiedStock", {
    appIsBusy: false
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;
          const report = extMap(user.pid).reports.bsg_identified_stock;
          const url = report.url;

          return {
            version: "20190625",
            action: "requestodata",
            subAction: "getLastIdentifiedStock",
            description: "",
            httpMethod: "GET",
            headers: {},
            url: dispatch(
              convURL.reportANA(`${url}`, {
                $filter: `CISTOCK_ID eq '${stockPrefix()}????'`,
                $select: "CISTOCK_ID",
                $orderby: "CISTOCK_ID desc",
                $top: 10
              })
            )
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const body = jsonResponse.body;
          const results = body.d.results;

          return [true, results];
        };
      }
    })
    .start();
  // 씨엔텍 동종재고 생성 에러 fix
  const regex = new RegExp("[0-9A-F]{4}");
  // prettier-ignore
  queryIstock.data.jsonResponse.body.d.results = 
    queryIstock.data.jsonResponse.body.d.results.filter(
      item => regex.test(item.CISTOCK_ID.substr(2, 4))
    );
  return queryIstock;
};

/**
 * 생산오더 SOAP 조회
 * 생산오더ID, 생산제안ID로 조회 가능
 * @param {*} params
 * @returns
 */

const getProductionOrderByID = params => {
  const {
    proposalIDs,
    productionOrderIDs,
    includeProductionReqSelection
  } = params;
  const searchType = parseInt(params.searchType);
  let isQueryBySalesOrderID = false;
  let isQueryByProductionOrderID = false;
  if (params.keyword) {
    isQueryBySalesOrderID = searchType === 2;
    isQueryByProductionOrderID = searchType === 3;
  }

  const selectionByProductionOrderStatus = (() => {
    // 생산오더 조회 추가 필터

    if (!isQueryBySalesOrderID && !isQueryByProductionOrderID) {
      return {
        SelectionByProductionOrderStatus: [
          (() => {
            return params.key_order_prep === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "1"
                }
              : undefined;
          })(),
          (() => {
            return params.key_order_rel === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "2"
                }
              : undefined;
          })(),
          (() => {
            return params.key_order_start === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "3"
                }
              : undefined;
          })(),
          (() => {
            return params.key_order_fin === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "4"
                }
              : undefined;
          })(),
          (() => {
            return params.key_order_fin === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "5"
                }
              : undefined;
          })(),
          (() => {
            return params.key_order_canc === false
              ? {
                  InclusionExclusionCode: "E",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryStatusCode: "6"
                }
              : undefined;
          })()
        ].filter(Boolean)
      };
    } else {
      return [];
    }
  })();
  const selectionByProductionOrderLatestStartDateTime = (() => {
    if (!isQueryBySalesOrderID && !isQueryByProductionOrderID) {
      return {
        SelectionByProductionOrderLatestStartDateTime: {
          InclusionExclusionCode: "I",
          IntervalBoundaryTypeCode: "3",
          LowerBoundaryDate: (() => {
            if (!params.productionStartDate) return "";
            return (
              moment(params.productionStartDate, "YYYY-MM-DD").format(
                "YYYY-MM-DDT00:00:00.0000000"
              ) + "Z"
            );
          })(),
          UpperBoundaryDate: (() => {
            if (!params.productionEndDate) return "";
            return (
              moment(params.productionEndDate, "YYYY-MM-DD").format(
                "YYYY-MM-DDT23:59:59.0000000"
              ) + "Z"
            );
          })()
        }
      };
    }
  })();
  const selectionByProductionOrderID = (() => {
    // 판매오더 ID값으로 조회시 해당 판매오더가 참조하는 생산오더ID 값 조건을 걸어줌

    if (
      isQueryBySalesOrderID &&
      productionOrderIDs &&
      productionOrderIDs.length > 0
    ) {
      return [...new Set(productionOrderIDs)].map(prodOrderID => {
        return {
          InclusionExclusionCode: "I",
          IntervalBoundaryTypeCode: "1",
          LowerBoundaryID: prodOrderID
        };
      });
    }

    if (parseInt(params.searchType) === 3) {
      if (params.keyword) {
        return {
          InclusionExclusionCode: "I",
          IntervalBoundaryTypeCode: "1",
          LowerBoundaryID: params.keyword
        };
      }
    }

    return undefined;
  })();
  const selectionByMaterialOutputProductID = (() => {
    if (parseInt(params.searchType) === 1 && params.keyword) {
      return {
        SelectionByMaterialOutputProductID: {
          InclusionExclusionCode: "I",
          IntervalBoundaryTypeCode: "1",
          LowerBoundaryID: params.keyword
        }
      };
    }
  })();

  const payload = {
    ProcessingConditions: {
      QueryHitsMaximumNumberValue: "300",
      QueryHitsUnlimitedIndicator: "false"
    },
    ProductionOrderSelectionByElements: {
      SelectionBySiteID: {
        InclusionExclusionCode: "I",
        IntervalBoundaryTypeCode: "1",
        LowerBoundarySiteID: params.site.key || params.site || ""
      },
      SelectionByProductionRequestID: proposalIDs.map(item => {
        return {
          InclusionExclusionCode: includeProductionReqSelection ? "I" : "E",
          IntervalBoundaryTypeCode: "1",
          LowerBoundaryID: item
        };
      }),
      SelectionByProductionOrderID: selectionByProductionOrderID,

      // prettier-ignore
      SelectionByProductionOrderLatestStartDateTime: 
        selectionByProductionOrderLatestStartDateTime,
      ...selectionByProductionOrderStatus,
      ...selectionByProductionOrderLatestStartDateTime,
      ...selectionByMaterialOutputProductID
    }
  };

  // soap 에러 방지를 위해 빈 필드 제거

  if (
    payload.ProductionOrderSelectionByElements.SelectionByProductionRequestID
      .length < 1
  ) {
    delete payload.ProductionOrderSelectionByElements
      .SelectionByProductionRequestID;
  }

  const payloadSelectionByPorderID =
    payload.ProductionOrderSelectionByElements.SelectionByProductionOrderID;
  if (
    !payloadSelectionByPorderID ||
    (Array.isArray(payloadSelectionByPorderID) &&
      payloadSelectionByPorderID.length < 1)
  ) {
    delete payload.ProductionOrderSelectionByElements
      .SelectionByProductionOrderID;
  }

  const selectionByProdOrderDateTime =
    payload.ProductionOrderSelectionByElements
      .SelectionByProductionOrderLatestStartDateTime;
  if (!selectionByProdOrderDateTime) {
    delete payload.ProductionOrderSelectionByElements
      .SelectionByProductionOrderLatestStartDateTime;
  } else {
    const lowerBoundDateCond = selectionByProdOrderDateTime.LowerBoundaryDate;
    const upperBoundDateCond = selectionByProdOrderDateTime.UpperBoundaryDate;
    if (!lowerBoundDateCond && !upperBoundDateCond) {
      delete payload.ProductionOrderSelectionByElements
        .SelectionByProductionOrderLatestStartDateTime;
    } else if (!lowerBoundDateCond) {
      delete payload.ProductionOrderSelectionByElements
        .SelectionByProductionOrderLatestStartDateTime.UpperBoundaryDate;
    } else if (!upperBoundDateCond) {
      delete payload.ProductionOrderSelectionByElements
        .SelectionByProductionOrderLatestStartDateTime.LowerBoundaryDate;
    }
  }

  if (
    !payload.ProductionOrderSelectionByElements.SelectionBySiteID
      .LowerBoundarySiteID
  ) {
    delete payload.ProductionOrderSelectionByElements.SelectionBySiteID;
  }

  return productionOrderQueryByOverview({ payload });
};

export const isReleasedProductionOrder = productionOrderID => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getProductionOrders({ productionOrderID });
      const response = res.data.jsonResponse.body.d.results[0];
      if (!response)
        resolve({
          productionOrderID,
          value: false
        });
      resolve({
        productionOrderID,
        value: parseInt(response.LifeCycleStatusCode) !== 1
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  });
};

const isReleasedOrder = productionOrderID => {
  return getProductionOrders({ productionOrderID }, false).then(res => {
    const response = res.data.jsonResponse.body.d.results[0];
    if (!response) {
      throw new Error(`생산오더 ${productionOrderID}를 찾을 수 없습니다.`);
    }

    // 동종재고가 이미 지정되어 있는지 확인
    if (response.ProductionOrderMainProductOutput.IdentifiedStockID)
      return true;

    // 상태가 릴리스됨인지 확인
    if (response.LifeCycleStatusCode === "2") return true;

    return false;
  });
};
