import React, { Component } from "react";

class VBox extends Component {
  render() {
    const { className, style, ...rest } = this.props;
    const inClassName = "react vbox ".concat(className || "").concat(" ");
    const inStyle = style || {};
    const notUseFlexGrow = "not-use-flex-grow";

    return (
      <div
        className={inClassName
          .concat(inStyle.width ? notUseFlexGrow : "")
          .concat(" ")
          .concat("renderJSX ")}
        style={inStyle}
        {...rest}
      >
        {this.props.children}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <VBox {...props} ref={ref} />);
