import React, { Component } from "react";

class Text extends Component {
  defaultStyle = {
    margin: "0"
  };
  render() {
    const { className, style, valueState, ...rest } = this.props;
    return (
      <p
        className={"text ".concat(className || "")}
        style={{ ...this.defaultStyle, ...valueState && {color: valueState.color}, ...style }}
        {...rest}
      >
        {this.props.children}
      </p>
    );
  }
}

export default React.forwardRef((props, ref) => <Text {...props} ref={ref} />);
