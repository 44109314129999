import React, { useState } from "react";
import { Input, Button, VBox, HBox, Text } from "components/ui5_react";

const Ui5ReactTest = () => {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState("");
  const increaseCount = () => {
    setCount(count + 1);
  };
  return (
    <>
      <VBox>
        <HBox>
          <Button label="버튼" onClick={increaseCount} />
          <Text>버튼 누른 횟수 : {count}</Text>
        </HBox>
        <Input value={value} onChange={event => setValue(event.target.value)} />
      </VBox>
    </>
  );
};

export default Ui5ReactTest;
