import { actions } from "actions/production_plan/pp_plan";
import moment from "moment";

const initialState = {
  salesOrder: {},
  bom: [],
  supplyPlanningArea: [],
  initQuantity: ""
};

const actionHandlers = {
  [actions.PP_PLAN_GET_SALES_ORDER_ITEMS](state, payload) {
    return { ...state, salesOrder: payload };
  },
  [actions.PP_PLAN_SELECT_SALES_ORDER_ITEM](state, payload) {
    const { salesOrder } = state;

    const targetSalesOrderItem = payload;

    salesOrder.items.forEach((item, index) => {
      if (item.num === targetSalesOrderItem.num) {
        salesOrder.items[index].selected = true;
      } else {
        salesOrder.items[index].selected = false;
      }
    });

    return { ...state, salesOrder: salesOrder };
  },
  [actions.PP_PLAN_GET_SALES_ORDER_BOM_DETAIL](state, payload) {
    const initialDate = state.salesOrder.items
      ? state.salesOrder.items[0].scheduledDate
      : undefined;

    const salesOrderItems = state.salesOrder.items
      ? state.salesOrder.items
      : [];

    payload.forEach((item, index) => {
      payload[index] = {
        _data: item,
        num: index,
        siteID: ((
          productionProposalID = item.ProductionPlanningOrder || [],
          purchaseRequestID = item.PurchaseRequest || []
        ) => {
          if (productionProposalID.length > 0) {
            return productionProposalID[0].SupplyPlanningAreaID;
          }

          if (purchaseRequestID.length > 0) {
            return purchaseRequestID[0].ShipToLocationID;
          }
          if (item.ProductCategoryInternalID === "108") {
            // 21.04.28 제품 범주가 원료(108)인 경우 평택 1공장(C1000) 설정
            return "C1000";
          }

          return item.SupplyPlanningAreaID;
        })(),
        fixed_supplier: item.fixed_supplier,
        price: item.price,
        currencyCode: item.currencyCode,
        siteList: (item.siteList || [])
          .filter(({ key }) => !key.startsWith("3") && !key.startsWith("4"))
          .sort((al, be) => al.key - be.key),
        productID: item.MaterialInternalID,
        productDescription: item.MaterialDescription,
        productSpecificationID: item.RequirementSpecificationID,
        quantity: (() => {
          return item.Quantity ? parseFloat(item.Quantity) : 0;
        })(),
        quantityUnit:
          item.SalesOrderItemQuantityUnitCode || item.QuantityUnitCode,
        quantityUnitText:
          item.SalesOrderItemQuantityUnitCodeText || item.QuantityUnitCodeText,
        procurementMethodCode: item.ProcurementMethodCode,
        procurementMethodCodeText: item.ProcurementMethodCodeText,
        productionStartDate: (() => {
          if (item.ProductionPlanningOrder.length > 0) {
            return moment(
              item.ProductionPlanningOrder[0].PlannedStartDate
            ).format("YYYY-MM-DD");
          }

          return "";
        })(),
        productionMaterialAvailDate: initialDate,
        purchaseConfirmDate: (() => {
          if (item.PurchaseRequest.length > 0) {
            return item.PurchaseRequest[0].DeliveryStartDate
              ? moment(item.PurchaseRequest[0].DeliveryStartDate).format(
                  "YYYY-MM-DD"
                )
              : "";
          }
          return "";
        })(),
        requiredQuantity: (() => {
          if (
            item.SalesOrderItemQuantity &&
            parseFloat(item.SalesOrderItemQuantity) <= 0
          ) {
            // BOM데이터가 없는 데이터는 판매오더 수량을 반환하지 않아, 판매오더 수량을 보여줌

            const fItem = salesOrderItems.find(sItem => {
              return (
                sItem.productID === item.MaterialInternalID &&
                parseInt(item.SalesOrderItemID) === parseInt(sItem.num)
              );
            });

            if (fItem) {
              return parseFloat(fItem.quantity);
            }
          }

          // BOM데이터가 있는 데이터는 판매오더수량 반환

          if (item.SalesOrderItemQuantity) {
            return parseFloat(item.SalesOrderItemQuantity);
          }

          // 판매오더에도 없는 데이터면 empty string 반환

          return "";
        })(),
        moq: parseFloat(item.MinimumLotSizeQuantity),
        currentQuantity: [], // NOTE. Parsed from Report
        requestQuantity: (() => {
          // 생산제안이 있을경우

          if (item.ProductionPlanningOrder.length > 0) {
            return parseFloat(item.ProductionPlanningOrder[0].PlannedQuantity);
          }

          // 구매요청이 있을경우

          if (item.PurchaseRequest.length > 0) {
            return parseFloat(item.PurchaseRequest[0].RequestedQuantity);
          }

          return "";
        })(),
        productionOrderID:
          item.ProductionPlanningOrder.length > 0
            ? item.ProductionPlanningOrder.map(plan => plan.ProductionOrderID)
            : [],
        productionProposalID: item.ProductionPlanningOrder || [],
        purchaseRequestID: item.PurchaseRequest || [],
        purchaseUnitID: (() => {
          if (item.PurchaseRequest.length > 0) {
            return item.PurchaseRequest[0].PurchasingUnitPartyID;
          }

          return "";
        })(),
        purchaseUnitText: "",
        companyID: item.CompanyID,
        supplyPlanningAreaLock: item.SupplyPlanningAreaReadOnly,
        locked: (() => {
          if (item.SalesOrderItemID) {
            if (
              item.ProductionPlanningOrder.length > 0 ||
              item.PurchaseRequest.length > 0
            ) {
              return true;
            }
          }
          return false;
        })(),
        stNum: item.StNum,
        bomFixedDate: item.BOMFixedDate,
        bomVersion: item.BOMVersion,
        customerName: item.customerName,
        ecoChangeDate: (() => {
          const date = item.EngineeringChangeOrderChangeDateTime;
          if (!date) return "";
          const dateString = date.slice(0, 8);
          const timeString = date.slice(8, date.length);
          const dateTime = `${dateString}T${timeString}`;
          const mDate = moment(dateTime);
          if (!mDate.isValid()) return "";
          return mDate.utc(9).format("YYYY-MM-DD HH:mm:ss");
        })(),
        productionModleReleaseDate: (() => {
          const date = item.ProductionModelReleaseDateTime;
          if (!date) return "";
          const dateString = date.slice(0, 8);
          const timeString = date.slice(8, date.length);
          const dateTime = `${dateString}T${timeString}`;
          const mDate = moment(dateTime);
          if (!mDate.isValid()) return "";
          return mDate.utc(9).format("YYYY-MM-DD HH:mm:ss");
        })()
      };
    });

    // return { ...state, bom: payload };
    return { ...state, bom: [...state.bom, ...payload] };
  },
  [actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM](state, payload) {
    const bom = state.bom;
    for (const poItem of payload) {
      switch (poItem.type) {
        case "purchaseRequest":
          // 봄에서 제품ID를 찾아서 맞는 데이터를 변경

          bom.forEach(bomItem => {
            if (
              bomItem.productID === poItem.id &&
              bomItem.siteID === poItem.siteID &&
              bomItem._data.SalesOrderItemID === poItem.salesOrderItemID
            ) {
              if (poItem.value) {
                bomItem.purchaseRequestID.push({ ID: poItem.value });
                bomItem.requestQuantity = poItem.requestQuantity;
                bomItem.locked = poItem.salesOrderID ? true : false;
              } else {
                bomItem.purchaseRequestID.push({ ID: "생성오류" });
                bomItem.locked = false;
              }
            }
          });
          break;
        case "productionProposal":
          bom.forEach(bomItem => {
            if (
              bomItem.productID === poItem.id &&
              bomItem.siteID === poItem.siteID &&
              bomItem._data.SalesOrderItemID === poItem.salesOrderItemID
            ) {
              if (poItem.value) {
                bomItem.productionProposalID.push({ ID: poItem.value });
                bomItem.productionStartDate = poItem.startDate;
                bomItem.requestQuantity = poItem.requestQuantity;
                bomItem.locked = poItem.salesOrderID ? true : false;
              } else {
                bomItem.productionProposalID.push({ ID: "생성오류" });
                bomItem.locked = false;
              }
            }
          });
          break;
        case "productionOrder":
          bom.forEach(bomItem => {
            if (
              bomItem.productID === poItem.id &&
              bomItem.siteID === poItem.siteID &&
              bomItem._data.SalesOrderItemID === poItem.salesOrderItemID
            ) {
              if (poItem.value) {
                bomItem.productionOrderID.push({ ID: poItem.value });
                bomItem.productionProposalID.push({
                  ID: poItem.productionProposalID
                });
                bomItem.productionStartDate = poItem.startDate;
                bomItem.requestQuantity = poItem.requestQuantity;
                bomItem.locked = poItem.salesOrderID ? true : false;
              } else {
                bomItem.productionOrderID.push({ ID: "생성오류" });
                bomItem.productionProposalID.push({ ID: "생성오류" });
                bomItem.locked = false;
              }
            }
          });
          break;
        default:
          break;
      }
    }

    return { ...state, bom: bom };
  },
  [actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY](
    state,
    payload
  ) {
    const bom = state.bom;
    const errItems = [];

    for (const item of bom) {
      const filteredQuantity = payload.filter(
        value => value.CPRODUCT === item.productID
      );

      if (filteredQuantity.length > 0) {
        item.currentQuantity = filteredQuantity.map(value => {
          return {
            siteID: value.CPLANNING_AREA,
            value: value.KCAVAILABLE_STK, // 가용재고
            totalSupply: value.KCTOTL_SUPPLY, // 총 공급
            totalDemand: value.KCTOTL_DMND // 총 수요
          };
        });
      } else {
        errItems.push(item.productID);
      }
    }

    if (errItems.length > 0) {
      const message = `제품${errItems.join(
        ", "
      )}이 유효하지 않습니다. 재고정보를 가져올 수 없습니다.`;
      window.sap.m.MessageToast.show(message);
      console.error(message);
    }

    return { ...state, bom: bom };
  },
  [actions.PP_PLAN_GET_SUPPLY_PLANNING_AREAS](state, payload) {
    return { ...state, supplyPlanningArea: payload };
  },
  [actions.PP_PLAN_UPDATE_BOM_ITEM_SITE](state, payload) {
    console.log("PP_PLAN_UPDATE_BOM_ITEM_SITE", { state, payload });
    return {
      ...state,
      bom: state.bom.map((item, idx) => {
        const fItem = payload.find(it => it.num === idx);
        if (fItem) {
          return fItem;
        } else return item;
      })
    };
  },
  [actions.PP_PLAN_CLEAR_SALES_ORDER_ITEMS_AND_BOM](state, payload) {
    return { ...state, salesOrder: [], bom: [] };
  },
  [actions.PP_PLAN_CLEAR_BOM](state, payload) {
    return { ...state, bom: [] };
  },
  [actions.PP_PLAN_UPDATE_INIT_QUANTITY](state, payload) {
    return { ...state, initQuantity: payload };
  },
  [actions.PP_PLAN_CLEAR_STORE](state) {
    return {
      salesOrder: {},
      bom: [],
      supplyPlanningArea: [],
      initQuantity: ""
    };
  }
};

function pp_plan_reducer(state = initialState, action) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return { ...state };
}

export default pp_plan_reducer;
