/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview bsg_productionbomview oData Service
 * @description bsg_productionbomview oData 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 */

import { newProcess, fetchToken } from "ws-process";
import { convURL } from "actions/apiV2";

const serviceName = "bsg_productionbomview";

/**
 * CBO BOM Query Data 를 이용하여 자재의 BOM 정보를 조회합니다.
 * @param {Object} params { SalesOrderID, Product: [ { InternalID, SalesOrderItemID, SalesOrderItemQuantity, SalesOrderItemQuantityUnitCode } ], dispatch }
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise} 반환 데이터 형식 =>  data: { jsonResponse: { body: { d: { results: returnData } } } }
 * @example
 * getProductionBillOfMaterialView(
 * {
 *   SalesOrderID: 1023,
 *   Product: [
 *       {
 *         InternalID: "TESTPRODUCT01",
 *         SalesOrderItemID: "01",
 *         SalesOrderItemQuantity: "30.00",
 *         SalesOrderItemQuantityUnitCode: "EA"
 *       }
 *    ],
 *   dispatch: dispatch
 * },
 * {
 *   afterSucceed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   },
 *   afterFailed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   }
 * });
 */

export const getProductionBillOfMaterialView = (params = {}, cb) => {
  const { dispatch } = params;
  const actionName = "getProductionBillOfMaterialView";
  const collectionName = "ProductionBillOfMaterialViewCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const getToken = () => {
    return fetchToken(
      dispatch(
        convURL.custom("bsg_productionplanningorder/ProductionOrderCollection")
      )
    )();
  };

  const postBOMRequest = params => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, {})
              ),
              body: params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  const getBOMRequest = objectID => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, {
                  $expand: [
                    "Product",
                    "Item",
                    "Item/PurchaseRequest",
                    "Item/ProductionPlanningOrder"
                  ].join(","),
                  $filter: [escape(`ObjectID eq '${objectID}'`)]
                })
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    return getToken()
      .then(() => {
        return postBOMRequest(params);
      })
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;
        const objectID = response.ObjectID;

        return getBOMRequest(objectID);
      })
      .then(res => {
        resolve(res);
        return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
      })
      .catch(error => {
        reject(error);
        console.error(error);
        return cb && cb.afterFailed ? cb.afterFailed(false, error) : "";
      });
  });
};

export default {
  getProductionBillOfMaterialView
};
