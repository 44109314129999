/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview ManageProductionProposalIn SOAP Service
 * @description ManageProductionProposalIn SOAP 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 * @see https://help.sap.com/doc/a2b4c7ef61d64397b2b1d052082a8a08/1905/en-US/PSM_ISI_R_II_MANAGE_PROD_PROPOSAL_IN.html
 */

import { newProcess } from "ws-process";
import moment from "moment";
import UOM from "lib/convertUOM";

const serviceName = "ManageProductionProposalIn";

/**
 * 생산제안 생성
 * @param {Object} params
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise}
 */

export const createBundle = (params = {}, cb) => {
  const operationName = "CreateBundle";

  // Empty Object Check

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  if (params.items.length <= 0) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  // SOAP Request Block

  return new Promise((resolve, reject) => {
    newProcess(`${serviceName}_${operationName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;

            const item_payload = {
              CreateProductionPlanningOrder: params.items.map((item, index) => {
                return {
                  SequenceNumber: index,
                  SupplyPlanningAreaID: item.siteID,
                  MaterialID: item.productID,
                  MaterialAvailabilityDateTime: {
                    timeZoneCode: "UTC+9",
                    _value_1: `${moment(
                      item.productionMaterialAvailDate
                    ).format("YYYY-MM-DDT09:00:00.0000000")}Z`
                  },
                  Quantity: {
                    unitCode: item.quantityUnit,
                    _value_1: item.requestQuantity
                  },
                  QuantityTypeCode: UOM(item.quantityUnit),
                  SOID_01: params.salesOrderID || item.salesOrderID || "",
                  ST_NUM_1: item.stNum || "", // 제품 표준서 번호
                  BOM_FIXED_DATE_1: item.bomFixedDate, // 최종 처방 확정일
                  BOM_VERSION_1: item.bomVersion, // 처방 Rev
                  CUSTOMER_NAME: item.customerName // 거래처명
                };
              })
            };

            const api_params = {
              pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
              wsdlFile: {
                filename: `${user.pid}_${serviceName}`,
                keyPrefix: "byd-wsdl/"
              },
              operationName: `${operationName}`,
              "sap-language": "ko",
              wsdlParams: item_payload,
              requestXmlRaw: false
            };

            return {
              version: "20190625",
              action: "requestsoap",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              "body-json": api_params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start()
      .then(res => {
        resolve(res);
        return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
      })
      .catch(err => {
        reject(err);
        return cb && cb.afterFailed ? cb.afterFailed(false, err) : "";
      });
  });
};

export default {
  createBundle
};
