/* eslint-disable id-length */
/**
 * 테넌트별로 다르게 사용되는 키값 예) 벌크코드 카테고리 등의 정보를 정리해놓은곳입니다.
 * @param {*} pid ByDesign Tenant ID
 */

// 201 벌크, 202 베이스, 306 희석액

const mappingTable = {
  my341545: {
    bulkCategoryIDs: ["R101", "206"],
    inputBulkCategoryIDs: ["201", "202", "206"]
  },
  my348037: {
    bulkCategoryIDs: ["201", "202"],
    inputBulkCategoryIDs: ["201", "202", "206"]
  },
  my349266: {
    bulkCategoryIDs: ["201", "202"],
    inputBulkCategoryIDs: ["201", "202", "206"] // 실제로는 벌크가 아니지만, 생산 투입 시 벌크로 취급되는 제품이 있음(206)
  }
};

export default pid => {
  if (!mappingTable[pid]) {
    throw new Error("테넌트 정보가 존제하지 않습니다.");
  }

  return mappingTable[pid];
};
