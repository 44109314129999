export default {
  Success: {
    description: "Success",
    color: "green"
  },
  Error: {
    description: "Error",
    color: "red"
  },
  Warning: {
    descriptioin: "Warning",
    color: "orange"
  }
};
