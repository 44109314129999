/**
 *  ws process 요청을 보냅니다.
 *  응답이 페이지네이션 되었다면 모든 페이지를 수신합니다.
 * @param {*} request
 * @param {*} params
 * @returns
 */

export const sendRequest = (request, params) => {
  return new Promise(async (resolve, reject) => {
    let count = -1;
    let returnData = [];
    try {
      while (
        count < 0 ||
        /* count >= returnData.length*/ count > returnData.length
      ) {
        if (count > 0) {
          /**
           * 다중 페이지 데이터를 한번에 가져올 수 있도록 요청을 페이지당 200개 항목씩 분할합니다.
           */
          // window.sap.m.MessageToast.show(
          //   `데이터 로드 ${returnData.length} / ${count} `
          // );
        }

        const res = await request(params, returnData.length);
        let response;
        if (!res) {
          console.warn("sendRequest의 응답 결과가 undefined 입니다.");
          console.warn(params);
          response = [];
        } else {
          response = res.data.jsonResponse.body.d.results;
          count = parseInt(res.data.jsonResponse.body.d.__count);
        }

        returnData = [...returnData, ...response];

        if (response.length <= 0) {
          break;
        }
      }

      resolve({
        data: {
          jsonResponse: {
            body: {
              // eslint-disable-next-line id-length
              d: {
                results: returnData
              }
            }
          }
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 특정 파라피터 값을 chunk 한 뒤, 각 chunk 별로 요청을 보냅니다.
 * 각 chunk는 Promise.all을 통해 비동기적으로 수행됩니다.
 * 모든 chunk에 대해 응답이 오면 결과를 반환합니다.
 * @param {*} request
 * @param {*} params
 * @param {*} chunkPropName
 * @returns
 */

export const chunkAndSendRequest = (
  request,
  params,
  chunkPropName,
  chunkSize = 50
) => {
  return new Promise(async (resolve, reject) => {
    if (!chunkPropName)
      throw new Error("chunk 기준 프로퍼티가 지정되지 않았습니다.");

    const CHUNK_SIZE = chunkSize;
    const chunkedArr = chunkArr(params[chunkPropName], CHUNK_SIZE);

    // let returnData = [];

    try {
      const promises = chunkedArr.map(chunk => {
        return new Promise(async (resolve, reject) => {
          try {
            let count = -1;
            const chunkParams = {
              ...params,
              [chunkPropName]: chunk
            };
            let chunkReturnData = [];
            while (
              count < 0 ||
              /* count >= chunkReturnData.length */ count >
                chunkReturnData.length
            ) {
              const res = await request(chunkParams, chunkReturnData.length);
              let response;
              if (!res) {
                console.warn("sendRequest의 응답 결과가 undefined 입니다.");
                console.warn(params);
                response = [];
              } else {
                response = res.data.jsonResponse.body.d.results;
                count = parseInt(res.data.jsonResponse.body.d.__count);
              }

              chunkReturnData = [...chunkReturnData, ...response];

              if (response.length <= 0) {
                break;
              }
            }

            resolve(chunkReturnData);
          } catch (error) {
            reject(error);
          }
        });
      });
      Promise.all(promises).then(resArray => {
        const returnData = resArray.flatMap(item => item);
        resolve({
          data: {
            jsonResponse: {
              body: {
                // eslint-disable-next-line id-length
                d: {
                  results: returnData
                }
              }
            }
          }
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 특정 파라피터 값을 chunk 한 뒤, 각 chunk 별로 요청을 보냅니다.
 * 각 chunk에 대한 요청은 반복문을 통해 순차적으로 수행됩니다.
 * 모든 chunk에 대해 응답이 오면 결과를 반환합니다.
 * @param {*} request
 * @param {*} params
 * @param {*} chunkPropName
 * @returns
 */

export const chunkAndSendRequestSync = (
  request,
  params,
  chunkPropName,
  chunkSize = 50
) => {
  return new Promise(async (resolve, reject) => {
    if (!chunkPropName)
      throw new Error("chunk 기준 프로퍼티가 지정되지 않았습니다.");

    const CHUNK_SIZE = chunkSize;
    const chunkedArr = chunkArr(params[chunkPropName], CHUNK_SIZE);
    let returnData = [];

    try {
      for (const chunk of chunkedArr) {
        let count = -1;
        const partialParams = {
          ...params,
          [chunkPropName]: chunk
        };
        let partialReturnData = [];

        while (count < 0 || count >= partialReturnData.length) {
          if (count > 0) {
            /**
             * 다중 페이지 데이터를 한번에 가져올 수 있도록 요청을 페이지당 200개 항목씩 분할합니다.
             */
            // window.sap.m.MessageToast.show(
            //   `데이터 로드 ${returnData.length} / ${count} `
            // );
          }

          const res = await request(partialParams, partialReturnData.length);
          let response;
          if (!res) {
            console.warn("sendRequest의 응답 결과가 undefined 입니다.");
            console.warn(params);
            response = [];
          } else {
            response = res.data.jsonResponse.body.d.results;
            count = parseInt(res.data.jsonResponse.body.d.__count);
          }

          partialReturnData = [...partialReturnData, ...response];

          if (response.length <= 0) {
            break;
          }
        }

        returnData = [...returnData, ...partialReturnData];
      }

      resolve({
        data: {
          jsonResponse: {
            body: {
              // eslint-disable-next-line id-length
              d: {
                results: returnData
              }
            }
          }
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

const chunkArr = (srcArr, chunkSize) => {
  const chunkedArr = [];
  let index = 0;
  while (index < srcArr.length) {
    chunkedArr.push(srcArr.slice(index, chunkSize + index));
    index += chunkSize;
  }
  return chunkedArr;
};

export default {
  sendRequest,
  chunkAndSendRequest,
  chunkAndSendRequestSync
};
