import FormTableTest from "./form-and-table-test";
import LcUi5Test from "@bsgp/lc-ui5";
import Ui5ReactTest from "./ui5-react-test";
// Import other categories as needed

const routesConfig = [
  {
    path: `/form-and-table`,
    component: FormTableTest,
    name: "form-and-table-test"
  },
  {
    path: `/lc-ui5`,
    component: LcUi5Test,
    name: "lc-ui5-test"
  },
  {
    path: `/ui5-react`,
    component: Ui5ReactTest,
    name: "ui5-react-test"
  }
  // Add other routes as needed
];

export default routesConfig;
