import moment from "moment";
import { tryit } from "@bsgp/lib-core";
import { addError } from "actions/ui5";
import { newProcess, fetchToken } from "ws-process";
import { byId } from "ui5-lib-rc";
import { convURL } from "actions/apiV2";
import UOM from "lib/convertUOM";
import extMap from "lib/urlMap";

// prettier-ignore
import QueryMaterialIn 
from "actions/production_plan/service/soap_queryMaterialIn";
// prettier-ignore
import QuerySalesOrderIn 
from "actions/production_plan/service/soap_querySalesOrderIn";
// prettier-ignore
import bsg_material 
from "actions/production_plan/service/odata_bsg_material";
// prettier-ignore
import bsg_productionbomview 
from "actions/production_plan/service/odata_bsg_productionbomview";
// prettier-ignore
import { postReleaseProductionProposal } 
from "actions/production_plan/service/odata_bsg_productionplanningorder";
// prettier-ignore
import { getProductionProposal } 
from "actions/production_plan/service/odata_bsg_productionplanningorder";
// prettier-ignore
import { productionOrderQueryByOverview } 
from "actions/production_plan/service/soap_queryProductionOrderIn";
// prettier-ignore
import { queryProductionBOMByElements } 
from "actions/production_plan/service/soap_queryProductionBillofMaterialsIn";

export const actions = {
  // eslint-disable-next-line id-length
  PP_PLAN_GET_SUPPLY_PLANNING_AREAS:
    "[PRODUCTION PLAN] GET_SUPPLY_PLANNING_AREAS",
  PP_PLAN_GET_SALES_ORDER_ITEMS: "[PRODUCTION PLAN] GET_SALES_ORDER_ITEMS",
  // eslint-disable-next-line id-length
  PP_PLAN_GET_SALES_ORDER_BOM_DETAIL:
    "[PRODUCTION PLAN] GET_SALES_ORDER_BOM_DETAIL",
  // eslint-disable-next-line id-length
  PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM:
    "[PRODUCTION PLAN] UPDATE_SALES_ORDER_BOM_DETAIL_ITEM",
  // eslint-disable-next-line id-length
  PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY:
    "[PRODUCTION PLAN] UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY",
  // eslint-disable-next-line id-length
  PP_PLAN_UPDATE_BOM_ITEM_SITE:
    "[PRODUCTION PLAN] PP_PLAN_UPDATE_BOM_ITEM_SITE",
  PP_PLAN_CLEAR_SALES_ORDER_ITEMS_AND_BOM:
    "[PRODUCTION PLAN] CLEAR_SALES_ORDER_ITEMS_AND_BOM",
  PP_PLAN_CLEAR_BOM: "[PRODUCTION PLAN] CLEAR_BOM",
  PP_PLAN_SELECT_SALES_ORDER_ITEM: "[PRODUCTION PLAN] SELECT_SALES_ORDER_ITEM",
  PP_PLAN_UPDATE_INIT_QUANTITY: "[PRODUCTION PLAN] UPDATE_INIT_QUANTITY",
  PP_PLAN_CLEAR_STORE: "[PRODUCTION PLAN] CLEAR_STORE"
};

export const clearProductionPlanStore = () => {
  return {
    type: actions.PP_PLAN_CLEAR_STORE
  };
};

/**
 * Production Plan Re
 * @param {*} keyword
 * @param {*} callback
 */

export const getSalesOrdeByID = (keyword = "", callback) => {
  return (dispatch, getState) => {
    QuerySalesOrderIn.findByElements({
      keyword: keyword
    })
      .then(res => {
        const response = res.data.jsonResponse;

        if (!response.SalesOrder || response.SalesOrder.length <= 0) {
          return dispatch(addError("검색 결과가 없습니다."));
        }

        const salesOrder = response.SalesOrder[0];
        const items = salesOrder.Item;

        const salesUnitParty = tryit(
          () => salesOrder.SalesUnitParty.PartyID._value_1 || "",
          ""
        );
        if (salesUnitParty.startsWith("3") || salesUnitParty.startsWith("4")) {
          return dispatch(addError("씨엔텍 판매오더가\n아닙니다."));
        }
        const payload = {
          salesOrderID: salesOrder.ID._value_1,
          items: items
            .map(item => {
              if (!item.Status) {
                console.error(
                  [
                    "판매오더 조회 중 오류가 발생했습니다.",
                    `판매오더 ${salesOrder.ID._value_1}의`,
                    `항목 ${item.ID}의 상태 코드가 존재하지 않습니다.`
                  ].join("\n")
                );
                return undefined;
              }
              return {
                num: item.ID,
                salesOrderID: salesOrder.ID._value_1,
                selected: false,
                status: item.Status.FulfilmentProcessingStatusCode,
                statusText: item.Status.FulfilmentProcessingStatusName._value_1,
                rejected: item.Status.CancellationStatusCode,
                rejectedText: item.Status.CancellationStatusName._value_1,
                productID: item.ItemProduct.ProductInternalID._value_1,
                fullName: item.FULL_NAME,
                ...((ItemScheduleLine = item.ItemScheduleLine) => {
                  const scheduleByType = ItemScheduleLine.find(
                    item => parseInt(item.TypeCode) === 1
                  );

                  if (scheduleByType) {
                    return {
                      quantity: scheduleByType.Quantity._value_1,
                      quantityUnit: scheduleByType.Quantity.unitCode,
                      quantityUnitText:
                        scheduleByType.QuantityTypeName._value_1,
                      // Type Code가 1번인것만 가져와야함
                      scheduledDate: moment(
                        scheduleByType.DateTimePeriod.StartDateTime._value_1
                      ).format("YYYY-MM-DD")
                    };
                  }

                  return {
                    quantity: "오류",
                    quantityUnit: "오류",
                    quantityUnitText: "오류",
                    scheduledDate: "오류"
                  };
                })(),
                siteID: (() => {
                  if (!item.ShipFromItemLocation) return "";
                  return item.ShipFromItemLocation.LocationID
                    ? item.ShipFromItemLocation.LocationID._value_1
                    : "";
                })(),
                siteText: (() => {
                  if (!item.ShipFromItemLocation) return "";
                  return item.ShipFromItemLocation.UsedAddress
                    ? item.ShipFromItemLocation.UsedAddress.DisplayName[0]
                        .FormattedName._value_1
                    : "";
                })()
              };
            })
            .filter(Boolean)
            .sort((aVal, bVal) => aVal.num - bVal.num)
        };

        dispatch({
          type: actions.PP_PLAN_GET_SALES_ORDER_ITEMS,
          payload
        });

        return callback
          ? callback.afterSucceed(true, { items: items, salesOrder: payload })
          : "";
      })
      .catch(error => {
        console.error(error);
        return callback ? callback.afterFailed(false, error) : "";
      });
  };
};

export const getBOMData = (params = {}, callback) => {
  return async (dispatch, getState) => {
    const { salesOrderItems } = params;
    if (!salesOrderItems) {
      throw new Error(`BOM 데이터를 조회할 판매오더가 존재하지 않습니다.`);
    }
    const bomQueryList = salesOrderItems.map(salesOrder => {
      return {
        salesOrderID: salesOrder.salesOrderID,
        product: [
          {
            InternalID: salesOrder.productID,
            SalesOrderItemID: salesOrder.num,
            SalesOrderItemQuantity: salesOrder.quantity,
            SalesOrderItemQuantityUnitCode: salesOrder.quantityUnit
          }
        ]
      };
    });

    const bomRequest = params => {
      const { salesOrderID, product } = params;
      return bsg_productionbomview
        .getProductionBillOfMaterialView({
          SalesOrderID: salesOrderID,
          Product: product,
          dispatch: dispatch
        })
        .then(async res => {
          const resData = res.data.jsonResponse.body.d.results;
          const response = Array.isArray(resData) ? resData[0] : resData;
          const items = response.Item;

          if (items.length <= 0) {
            throw new Error("BOM 데이터 하위 항목이 없습니다.");
          }

          items.forEach((item, idx) => {
            items[idx].customerName = response.SalesOrderBuyerPartyName || "";
          });

          const siteList = (
            await bsg_material.getAvailableProductionSites({
              internalIDs: [
                ...new Set(
                  items.map(val => {
                    return val.MaterialInternalID;
                  })
                )
              ]
            })
          ).data.jsonResponse.body.d.results;

          const materialList = (
            await QueryMaterialIn.findByElements({
              internalIDs: [
                ...new Set(
                  items.map(val => {
                    return val.MaterialInternalID;
                  })
                )
              ],
              excludeAttachment: true
            })
          ).data.jsonResponse.Material;

          if (materialList.length <= 0) {
            throw new Error("자재내역을 조회할 수 없습니다. ");
          }

          items.forEach(item => {
            const itemProcurement = parseInt(item.ProcurementMethodCode);

            const fItem = siteList.find(sItem => {
              return sItem.InternalID === item.MaterialInternalID;
            });

            const mItem = materialList.find(sItem => {
              return sItem.InternalID._value_1 === item.MaterialInternalID;
            });

            if (!fItem || !mItem) {
              console.error(fItem, mItem);
            }

            if (fItem && mItem) {
              // FIXED_RESOURCE (고정공급사)등록

              item.fixed_supplier = mItem.FIXED_SUPPLIER
                ? mItem.FIXED_SUPPLIER
                : "";

              // PRICE 등록 (매입기준가) , + 환율코드

              item.price = mItem.PRICE ? mItem.PRICE._value_1 : "";
              item.currencyCode = mItem.PRICE ? mItem.PRICE.currencyCode : "";

              // 생산가능 사이트 등록

              item.siteList = fItem.MaterialAvailability.map(site => {
                const siteProcurement = mItem.Planning.SupplyPlanning.find(
                  sItem => {
                    return (
                      sItem.SupplyPlanningAreaID._value_1 ===
                      site.SupplyPlanningAreaID
                    );
                  }
                );

                return {
                  key: site.SupplyPlanningAreaID,
                  text: site.SupplyPlanningAreaName,
                  enabled: (() => {
                    // 조달방식의 사이트의 조달방식과 동일한지 확인

                    if (siteProcurement) {
                      if (
                        itemProcurement ===
                        parseInt(siteProcurement.ProcurementTypeCode)
                      ) {
                        return true;
                      }
                    }

                    return false;
                  })()
                };
              });
            }
          });

          // 생산오더가 생성된 제품인 경우 생산오더 ID 조회

          const proposalIDs = items
            .filter(item => item.ProductionPlanningOrder.length > 0)
            .map(item => item.ProductionPlanningOrder[0].ID);

          if (proposalIDs.length > 0) {
            const oProdcutionOrders = (
              await productionOrderQueryByOverview({
                proposalIDs
              })
            ).data.jsonResponse.ProductionOrder;

            items.forEach(item => {
              if (item.ProductionPlanningOrder.length < 1) return;
              item.ProductionPlanningOrder.forEach(proposal => {
                const matchingOrder = oProdcutionOrders.find(
                  order =>
                    order.ProductionOrderRequestID._value_1 === proposal.ID
                );
                proposal.ProductionOrderID = {
                  ID: matchingOrder
                    ? matchingOrder.ProductionOrderID._value_1
                    : proposal.ID
                };
              });
            });
          }

          // dispatch({
          //   type: actions.PP_PLAN_GET_SALES_ORDER_BOM_DETAIL,
          //   payload: JSON.parse(JSON.stringify(items))
          // });

          if (callback) {
            return callback.afterSucceed(true, items);
          } else {
            return items;
          }
        })
        .catch(error => {
          console.error(error);
          if (callback) {
            return callback.afterFailed(false, error);
          } else {
            throw new Error(error.message);
          }
        });
    };

    try {
      byId("app").setBusy(true);

      // BOM조회
      const items = [];

      const promiseList = bomQueryList.map(query => bomRequest({ ...query }));
      const resList = await Promise.all(promiseList);
      resList.forEach(list => {
        items.push(...list);
      });

      dispatch({
        type: actions.PP_PLAN_GET_SALES_ORDER_BOM_DETAIL,
        payload: JSON.parse(JSON.stringify(items))
      });

      // 사용가능재고조회

      const availableStockQuantity = (
        await getProductAvailableStockProcess({
          productIDs: [
            ...new Set(
              items.map(item => {
                return item.MaterialInternalID;
              })
            )
          ]
        })
      ).data.jsonResponse.body.d.results;

      dispatch({
        type: actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY,
        payload: availableStockQuantity
      });

      return items;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    } finally {
      byId("app").setBusy(false);
    }
  };
};

export const getProductAvailableStock = params => {
  return (dispatch, getState) => {
    const { items } = params;
    const productIDs = [
      ...new Set(
        items.map(item => {
          return item.MaterialInternalID;
        })
      )
    ];

    return getProductAvailableStockProcess({ productIDs }).then(res => {
      const response = res.data.jsonResponse.body.d.results;
      dispatch({
        type: actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY,
        payload: response
      });
    });
  };
};

/**
 * 판매오더 ID로 검색합니다.
 * @param {object} params
 * @param {*} callback
 */

export const getPlanBySalesOrderID = (params = {}, callback) => {
  return (dispatch, getState) => {
    getSalesOrderByIDProcess(params)
      .then(res => {
        const response = res.data.jsonResponse;

        if (!response.SalesOrder || response.SalesOrder.length <= 0) {
          throw new Error("검색 결과가 없습니다.");
        }

        const salesOrder = response.SalesOrder[0];
        const items = salesOrder.Item;

        dispatch({
          type: actions.PP_PLAN_GET_SALES_ORDER_ITEMS,
          payload: {
            salesOrderID: salesOrder.ID._value_1,
            items: items
              .map(item => {
                return {
                  num: item.ID,
                  status: item.Status.FulfilmentProcessingStatusCode,
                  statusText:
                    item.Status.FulfilmentProcessingStatusName._value_1,
                  rejected: item.Status.CancellationStatusCode,
                  rejectedText: item.Status.CancellationStatusName._value_1,
                  productID: item.ItemProduct.ProductInternalID._value_1,
                  ...((ItemScheduleLine = item.ItemScheduleLine) => {
                    const scheduleByType = ItemScheduleLine.find(
                      item => parseInt(item.TypeCode) === 1
                    );

                    if (scheduleByType) {
                      return {
                        quantity: scheduleByType.Quantity._value_1,
                        quantityUnit: scheduleByType.Quantity.unitCode,
                        quantityUnitText:
                          scheduleByType.QuantityTypeName._value_1,
                        // Type Code가 1번인것만 가져와야함
                        scheduledDate: moment(
                          scheduleByType.DateTimePeriod.StartDateTime._value_1
                        ).format("YYYY-MM-DD")
                      };
                    }

                    return {
                      quantity: "오류",
                      quantityUnit: "오류",
                      quantityUnitText: "오류",
                      scheduledDate: "오류"
                    };
                  })(),
                  siteID: item.ShipFromItemLocation
                    ? item.ShipFromItemLocation.LocationID._value_1
                    : "",
                  siteText: item.ShipFromItemLocation
                    ? item.ShipFromItemLocation.UsedAddress.DisplayName[0]
                        .FormattedName._value_1
                    : ""
                };
              })
              .sort((aVal, bVal) => aVal.num - bVal.num)
          }
        });

        return postProductionBomViewRequestProcess({
          dispatch: dispatch,
          body: {
            SalesOrderID: salesOrder.ID._value_1,
            Product: items
              .filter(val => {
                return val.Status.FulfilmentProcessingStatusCode < 3;
              })
              .map(item => {
                return {
                  InternalID: item.ItemProduct.ProductInternalID._value_1,
                  SalesOrderItemID: item.ID,
                  ...((ItemScheduleLine = item.ItemScheduleLine) => {
                    const scheduleByType = ItemScheduleLine.find(
                      item => parseInt(item.TypeCode) === 1
                    );

                    if (scheduleByType) {
                      return {
                        SalesOrderItemQuantity:
                          scheduleByType.Quantity._value_1,
                        SalesOrderItemQuantityUnitCode:
                          scheduleByType.Quantity.unitCode
                      };
                    }
                  })()
                };
              })
          }
        });
      })
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;
        const objectID = response.ObjectID;
        return getProductionBomViewRequestProcess({ objectID: objectID });
      })
      .then(async res => {
        const resData = res.data.jsonResponse.body.d.results;
        const response = Array.isArray(resData) ? resData[0] : resData;
        const items = response.Item;

        items.forEach((item, idx) => {
          items[idx].customerName = response.SalesOrderBuyerPartyName || "";
        });

        if (items.length <= 0) {
          throw new Error("BOM 데이터 하위 항목이 없습니다.");
        }

        const siteList = (
          await bsg_material.getAvailableProductionSites({
            internalIDs: [
              ...new Set(
                items.map(val => {
                  return val.MaterialInternalID;
                })
              )
            ]
          })
        ).data.jsonResponse.body.d.results;

        const materialList = (
          await QueryMaterialIn.findByElements({
            internalIDs: [
              ...new Set(
                items.map(val => {
                  return val.MaterialInternalID;
                })
              )
            ]
          })
        ).data.jsonResponse.Material;

        if (materialList.length <= 0) {
          throw new Error("자재내역을 조회할 수 없습니다. ");
        }

        items.forEach(item => {
          const itemProcurement = parseInt(item.ProcurementMethodCode);

          const fItem = siteList.find(sItem => {
            return sItem.InternalID === item.MaterialInternalID;
          });

          const mItem = materialList.find(sItem => {
            return sItem.InternalID._value_1 === item.MaterialInternalID;
          });

          if (!fItem || !mItem) {
            console.error(fItem, mItem);
          }

          if (fItem && mItem) {
            // FIXED_RESOURCE (고정공급사)등록

            item.fixed_supplier = mItem.FIXED_SUPPLIER
              ? mItem.FIXED_SUPPLIER
              : "";

            // PRICE 등록 (매입기준가) , + 환율코드

            item.price = mItem.PRICE ? mItem.PRICE._value_1 : "";
            item.currencyCode = mItem.PRICE ? mItem.PRICE.currencyCode : "";

            // 생산가능 사이트 등록

            item.siteList = fItem.MaterialAvailability.map(site => {
              const siteProcurement = mItem.Planning
                ? mItem.Planning.SupplyPlanning.find(sItem => {
                    return (
                      sItem.SupplyPlanningAreaID._value_1 ===
                      site.SupplyPlanningAreaID
                    );
                  })
                : undefined;

              return {
                key: site.SupplyPlanningAreaID,
                text: site.SupplyPlanningAreaName,
                enabled: (() => {
                  // 조달방식의 사이트의 조달방식과 동일한지 확인

                  if (siteProcurement) {
                    if (
                      itemProcurement ===
                      parseInt(siteProcurement.ProcurementTypeCode)
                    ) {
                      return true;
                    }
                  }

                  return false;
                })()
              };
            });
          }
        });

        // 생산오더가 생성된 제품인 경우 생산오더 ID 조회
        const proposalIDs = items
          .filter(item => item.ProductionPlanningOrder.length > 0)
          .map(item => item.ProductionPlanningOrder[0].ID);

        if (proposalIDs.length > 0) {
          const oProdcutionOrders = (
            await productionOrderQueryByOverview({
              proposalIDs
            })
          ).data.jsonResponse.ProductionOrder;

          items.forEach(item => {
            if (item.ProductionPlanningOrder.length < 1) return;
            item.ProductionPlanningOrder.forEach(proposal => {
              const matchingOrder = oProdcutionOrders.find(
                order => order.ProductionOrderRequestID._value_1 === proposal.ID
              );
              proposal.ProductionOrderID = {
                ID: matchingOrder
                  ? matchingOrder.ProductionOrderID._value_1
                  : proposal.ID
              };
            });
          });
        }

        dispatch({
          type: actions.PP_PLAN_GET_SALES_ORDER_BOM_DETAIL,
          payload: JSON.parse(JSON.stringify(items))
        });

        return getProductAvailableStockProcess({
          productIDs: [
            ...new Set(
              items.map(item => {
                return item.MaterialInternalID;
              })
            )
          ]
        });
      })
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;

        dispatch({
          type: actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY,
          payload: response
        });

        return callback
          ? callback.afterSucceed(true, "조회가 완료되었습니다.")
          : "";
      })
      .catch(error => {
        console.error(error);
        return callback ? callback.afterFailed(false, error) : "";
      });
  };
};

/**
 * 제품 ID로 검색합니다.
 * @param {*} params
 * @param {*} callback
 */

export const getPlanByProductID = (params = {}, callback) => {
  return (dispatch, getState) => {
    bsg_productionbomview
      .getProductionBillOfMaterialView({
        ...params,
        ...{ dispatch: dispatch }
      })
      .then(async res => {
        const resData = res.data.jsonResponse.body.d.results;
        const response = Array.isArray(resData) ? resData[0] : resData;
        const items = response.Item;

        if (items.length <= 0) {
          throw new Error("BOM 데이터 하위 항목이 없습니다.");
        }

        items.forEach((item, idx) => {
          items[idx].customerName = response.SalesOrderBuyerPartyName || "";
        });

        const materialList = (
          await QueryMaterialIn.findByElements({
            internalIDs: items.map(item => {
              return item.MaterialInternalID;
            })
          })
        ).data.jsonResponse.Material;

        if (materialList.length <= 0) {
          throw new Error("자재내역을 조회할 수 없습니다. ");
        }

        const siteList = (
          await bsg_material.getAvailableProductionSites({
            internalIDs: [
              ...new Set(
                items.map(val => {
                  return val.MaterialInternalID;
                })
              )
            ]
          })
        ).data.jsonResponse.body.d.results;

        items.forEach(item => {
          const itemProcurement = parseInt(item.ProcurementMethodCode);

          const fItem = siteList.find(sItem => {
            return sItem.InternalID === item.MaterialInternalID;
          });

          const mItem = materialList.find(sItem => {
            return sItem.InternalID._value_1 === item.MaterialInternalID;
          });

          if (fItem && mItem) {
            // FIXED_RESOURCE (고정공급사)등록

            item.fixed_supplier = mItem.FIXED_SUPPLIER
              ? mItem.FIXED_SUPPLIER
              : "";

            // PRICE 등록 (매입기준가)

            item.price = mItem.PRICE ? mItem.PRICE._value_1 : "";
            item.currencyCode = mItem.PRICE ? mItem.PRICE.currencyCode : "";

            // 생산가능 사이트 등록

            item.siteList = fItem.MaterialAvailability.map(site => {
              const siteProcurement = mItem.Planning
                ? mItem.Planning.SupplyPlanning.find(sItem => {
                    return (
                      sItem.SupplyPlanningAreaID._value_1 ===
                      site.SupplyPlanningAreaID
                    );
                  })
                : undefined;

              return {
                key: site.SupplyPlanningAreaID,
                text: site.SupplyPlanningAreaName,
                enabled: (() => {
                  // 조달방식의 사이트의 조달방식과 동일한지 확인

                  if (siteProcurement) {
                    if (
                      itemProcurement ===
                      parseInt(siteProcurement.ProcurementTypeCode)
                    ) {
                      return true;
                    }
                  }

                  return false;
                })()
              };
            });
          }
        });

        // 생산오더가 생성된 제품인 경우 생산오더 ID 조회

        const proposalIDs = items
          .filter(item => item.ProductionPlanningOrder.length > 0)
          .map(item => item.ProductionPlanningOrder[0].ID);

        if (proposalIDs.length > 0) {
          const oProdcutionOrders = (
            await productionOrderQueryByOverview({
              proposalIDs
            })
          ).data.jsonResponse.ProductionOrder;

          items.forEach(item => {
            if (item.ProductionPlanningOrder.length < 1) return;
            item.ProductionPlanningOrder.forEach(proposal => {
              const matchingOrder = oProdcutionOrders.find(
                order => order.ProductionOrderRequestID._value_1 === proposal.ID
              );
              proposal.ProductionOrderID = {
                ID: matchingOrder
                  ? matchingOrder.ProductionOrderID._value_1
                  : proposal.ID
              };
            });
          });
        }

        dispatch({
          type: actions.PP_PLAN_GET_SALES_ORDER_BOM_DETAIL,
          payload: JSON.parse(JSON.stringify(items))
        });

        return getProductAvailableStockProcess({
          productIDs: [
            ...new Set(
              items.map(item => {
                return item.MaterialInternalID;
              })
            )
          ]
        });
      })
      .then(res => {
        const response = res.data.jsonResponse.body.d.results;

        dispatch({
          type: actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM_QUANTITY,
          payload: response
        });

        return callback
          ? callback.afterSucceed(true, "조회가 완료되었습니다.")
          : "";
      })
      .catch(error => {
        console.error(error);
        return callback ? callback.afterFailed(false, error) : "";
      });
  };
};

/**
 * 생산제안 생성
 * @param {*} params
 * @param {*} callback
 */

export const postProductionProposal = (params = {}, callback) => {
  return (dispatch, getState) => {
    const { salesOrderID } = params;

    const ProductionProposalID = [];

    const currentSalesOrder =
      getState().productionPlan.pp_plan.salesOrder || {};

    const toUpdateSite = [];

    // 최상위 자재의 경우 판매오더의 사이트정보를 변경해준다.

    if (currentSalesOrder.salesOrderID) {
      for (const object of params.items) {
        const fItem = currentSalesOrder.items.find(sItem => {
          return sItem.productID === object.productID;
        });

        if (fItem && fItem.siteID !== object.siteID) {
          toUpdateSite.push({
            num: fItem.num,
            siteID: object.siteID
          });
        }
      }
    }

    // 선택된 항목의 첫번째로 검색했을때, 동일 사이트 동일 자재가 있을경우 해당 항목은 삭제하고
    // 첫번째 항목의 수량에+ , 날짜는 첫번째 항목을 따라감

    const itemsCp = JSON.parse(JSON.stringify(params.items));
    for (const sel of itemsCp) {
      if (!sel) {
        continue; // 항목이 삭제되지 않았는지 확인
      }

      for (const [tIndex, tSel] of itemsCp.entries()) {
        if (!tSel || (tSel && tSel.num === sel.num)) {
          continue; // 동일항목일경우 동일자재/사이트 체크하지않음
        }

        if (sel.siteID === tSel.siteID && sel.productID === tSel.productID) {
          sel.requestQuantity =
            parseFloat(sel.requestQuantity) + parseFloat(tSel.requestQuantity);
          delete itemsCp[tIndex];
        }
      }
    }

    // 판매오더의 제품 (완제품)인 경우 사이트 변경시 판매오더의 사이트도 같이 변경해주어야함.

    return patchSalesOrderItemProcess({
      salesOrderID: currentSalesOrder.salesOrderID,
      items: toUpdateSite
    })
      .then(async res => {
        const response = res.data ? res.data.jsonResponse : [];
        const logs = response.Log ? response.Log.Item : [];

        if (logs.length > 0) {
          const errorStr = logs.map(item => {
            return item.Note;
          });
          throw new Error(errorStr.join(" , "));
        }

        // 생산제안 생성시 판매오더 확장필드에 '판매오더ID-아이템항목ID' 저장

        itemsCp.forEach(item => {
          const itemID = item._data ? item._data.SalesOrderItemID : "";
          if (!itemID) {
            item.salesOrderID = salesOrderID;
          } else {
            item.salesOrderID = `${salesOrderID}-${itemID}`;
          }
          return;
        });

        // BOM 확장필드 조회
        const materialIDs = itemsCp.map(item => item.productID);
        const oBOMData = (await queryProductionBOMByElements({ materialIDs }))
          .data.jsonResponse.ProductionBillOfMaterials;

        oBOMData.forEach((value, index) => {
          oBOMData[index] = {
            bomID: value.ProductionBillOfMaterialID
              ? value.ProductionBillOfMaterialID._value_1
              : "",
            creationDateTime: value.CreationDateTime || undefined,
            stNum: value.ST_NUM || "",
            bomFixedDate: value.BOM_FIXED_DATE || "",
            bomVersion: value.BOM_VERSION || "",
            materialID: (() => {
              try {
                // Node. CNT에서 BOM variant 는 항상 1이므로, 배열 0번 항목 참조함

                const id =
                  value.ProductionBillOfMaterialVariant[0].ProductID.ProductID
                    ._value_1;
                return id;
              } catch (error) {
                return "";
              }
            })()
          };
        });

        // 제품 ID를 기준으로 그룹화

        const keys = [...new Set(oBOMData.map(obj => obj.materialID))];
        const groups = keys.map(key => {
          const groupItems = oBOMData.filter(obj => obj.materialID === key);
          return groupItems;
        });

        const latestBOMItems = groups
          .map(group => {
            if (group.constructor === Array) {
              // 가장 최신의 BOM을 가져옴
              group.sort((itemA, itemB) => {
                if (!itemA.creationDateTime || !itemB.creationDateTime) {
                  throw new Error(
                    "BOM 확장필드를 조회할 수 없습니다. 최신 BOM을 가져오기 위한 날짜 정보를 변환할 수 없습니다."
                  );
                }
                const dateA = moment(itemA.creationDateTime);
                const dateB = moment(itemB.creationDateTime);
                if (dateA.isBefore(dateB, "seconds")) return 1;
                if (dateA.isAfter(dateB, "seconds")) return -1;
                return 0;
              });
              return group[0];
            } else if (group.constructor === Object) {
              return group;
            } else {
              return undefined;
            }
          })
          .filter(Boolean);

        itemsCp.forEach((item, idx) => {
          const mappingBOM = latestBOMItems.find(
            bom => bom.materialID === item.productID
          );
          if (!mappingBOM) return;
          itemsCp[idx].bomVersion = mappingBOM.bomVersion;
          itemsCp[idx].bomFixedDate = mappingBOM.bomFixedDate;
          itemsCp[idx].stNum = mappingBOM.stNum;
        });

        return postProductionProposalRequestProcess({
          salesOrderID: salesOrderID,
          items: itemsCp.filter(Boolean)
        });
      })
      .then(async res => {
        const response = res.data.jsonResponse;
        const successRes = response.ProductionProposalsCreated.filter(Boolean);
        const logs = response.Log ? response.Log.Item : [];
        const { items } = params;

        // 생산제안 결과값이 없을경우

        if (!successRes || successRes.length <= 0) {
          if (logs.length > 0) {
            const errorStr = logs.map(item => {
              return item.Note;
            });

            throw new Error(errorStr.join(" , "));
          } else {
            throw new Error("생산제안이 생성되지 않았습니다.");
          }
        }

        itemsCp.filter(Boolean).forEach((item, index) => {
          const proposalCreated = successRes.find(fItem => {
            return parseInt(fItem.SequenceNumber) === index + 1;
          });

          item.productionProposalID = proposalCreated.ProductionProposalID
            ? proposalCreated.ProductionProposalID._value_1
            : undefined;
        });

        // Mapping Array의 생산제안ID 변경

        const mappingArray = [];
        // const itemsToUpdateView = [];

        items.forEach(item => {
          const fItem = itemsCp.filter(Boolean).find(sItem => {
            return (
              sItem.siteID === item.siteID && sItem.productID === item.productID
            );
          });

          if (fItem.productionProposalID) {
            mappingArray.push({
              SalesOrderID: salesOrderID ? salesOrderID : "",
              SalesOrderItemID: salesOrderID ? item._data.SalesOrderItemID : "",
              ProductionBillOfMaterialHierarchyID:
                item._data.ProductionBillOfMaterialHierarchyID,
              SupplyPlanningAreaID: item.siteID,
              MaterialInternalID: item._data.MaterialInternalID,
              ProcurementMethodCode: item._data.ProcurementMethodCode,
              ProductionPlanningOrderID: fItem.productionProposalID || "",
              PurchaseRequestID: ""
            });
          }
        });

        await postProductionPlanningMappingProcess({
          items: mappingArray,
          dispatch: dispatch
        });
        // 생산제안 생성후 납품예정일 자동 변경

        return patchProductionProposalRequestProcess({
          items: itemsCp
            .filter(Boolean)
            .map((val, index) => {
              const productionProposalID = ProductionProposalID[index];
              if (!productionProposalID) {
                return undefined;
              }

              return {
                ...val,
                productionProposalID: productionProposalID
              };
            })
            .filter(Boolean)
        });
      })
      .then(() => {
        const productionProposalID = itemsCp
          .map(item => item.productionProposalID)
          .filter(Boolean);

        // 생산제안 생성시(soap) object ID를 반환하지 않음.
        // object ID 조회를 위해 생성한 생산제안을 조회
        return getProductionProposal({ productionProposalID });
      })
      .then(res => {
        const oProposal = res.data.jsonResponse.body.d.results;
        itemsCp.forEach(item => {
          const matchingProposal = oProposal.find(
            proposal => proposal.ProposalID === item.productionProposalID
          );
          item.objectID = matchingProposal
            ? matchingProposal.ObjectID
            : undefined;
        });

        return itemsCp;
      })
      .catch(error => {
        console.error(error);
        throw new Error(error);
      });
  };
};

export const releaseProductionProposal = (params, callback) => {
  return (dispatch, getState) => {
    const { createdProposals, items } = params;
    const proposalUUIDs = createdProposals.map(
      proposal => proposal.objectID || proposal._data.ObjectID
    );

    return postReleaseProductionProposal({ proposalUUIDs, dispatch })
      .then(() => {
        const proposalIDs = createdProposals.map(
          proposal => proposal.productionProposalID
        );

        // post 결과값으로 릴리즈된 생산오더 ID 반환하지 않음. 따라서 제안ID로 릴리즈된 생산오더 조회
        return productionOrderQueryByOverview({ proposalIDs });
      })
      .then(res => {
        const response = res.data.jsonResponse;
        const productionOrders = response.ProductionOrder;

        const itemsToUpdateView = [];
        items.forEach(item => {
          const fItem = createdProposals.filter(Boolean).find(sItem => {
            return (
              sItem.siteID === item.siteID && sItem.productID === item.productID
            );
          });

          const fPO = productionOrders.find(
            po =>
              po.ProductionOrderRequestID._value_1 ===
              fItem.productionProposalID
          );

          // poID랑 prID 같이 넘겨주기

          itemsToUpdateView.push({
            type: "productionOrder",
            id: item.productID,
            siteID: item.siteID,
            startDate: item.productionStartDate,
            value: fPO.ProductionOrderID._value_1 || undefined,
            productionProposalID: fItem.productionProposalID,
            requestQuantity: fItem.requestQuantity || "생성오류",
            salesOrderID: fItem.salesOrderID,
            salesOrderItemID: fItem.salesOrderID
              ? item._data.SalesOrderItemID
              : "",
            ProductionBillOfMaterialHierarchyID:
              item._data.ProductionBillOfMaterialHierarchyID
          });
        });

        return itemsToUpdateView;
      })
      .catch(error => {
        console.error(error);
        throw new Error(error);
      });
  };
};

/**
 * 구매요청 생성
 * @param {*} params
 * @param {*} callback
 */

export const postPurchaseRequest = (params = {}, callback) => {
  return (dispatch, getState) => {
    const { salesOrderID, items } = params;
    let purchaseRequestID = "";

    // 선택된 항목의 첫번째로 검색했을때, 동일 사이트 동일 자재가 있을경우 해당 항목은 삭제하고
    // 첫번째 항목의 수량에+ , 날짜는 첫번째 항목을 따라감

    const itemsCp = JSON.parse(JSON.stringify(items));
    for (const sel of itemsCp) {
      if (!sel) {
        continue; // 항목이 삭제되지 않았는지 확인
      }

      for (const [tIndex, tSel] of itemsCp.entries()) {
        if (!tSel || (tSel && tSel.num === sel.num)) {
          continue; // 동일항목일경우 동일자재/사이트 체크하지않음
        }

        if (sel.siteID === tSel.siteID && sel.productID === tSel.productID) {
          sel.requestQuantity =
            parseFloat(sel.requestQuantity) + parseFloat(tSel.requestQuantity);
          delete itemsCp[tIndex];
        }
      }
    }

    postPurchaseRequestRequestProcess({
      salesOrderID: salesOrderID,
      items: itemsCp.filter(Boolean)
    })
      .then(res => {
        const { items } = params;
        const response = res.data.jsonResponse;
        const successRes = response.PurchaseRequestResponse;

        if (!successRes) {
          throw new Error("구매요청 생성에 실패하였습니다.");
        }

        purchaseRequestID = successRes[0].PurchaseRequestID._value_1;

        const rVal = items.map((item, index) => {
          return {
            type: "purchaseRequest",
            id: item.productID,
            siteID: item.siteID,
            value: purchaseRequestID,
            salesOrderID: salesOrderID,
            salesOrderItemID: salesOrderID ? item._data.SalesOrderItemID : "",
            requestQuantity: (() => {
              return itemsCp
                .filter(Boolean)
                .find(sItem => sItem.productID === item.productID)
                .requestQuantity;
            })()
          };
        });

        dispatch({
          type: actions.PP_PLAN_UPDATE_SALES_ORDER_BOM_DETAIL_ITEM,
          payload: rVal
        });

        // Note. 구매요청은 선택항목이 묶여서 하나로 생성됩니다, BOM API 요청시 한번에 처리하면됨!

        const mappingArray = items.map(sel => {
          return {
            SalesOrderID: salesOrderID ? salesOrderID : "",
            SalesOrderItemID: sel._data.SalesOrderItemID,
            ProductionBillOfMaterialHierarchyID:
              sel._data.ProductionBillOfMaterialHierarchyID,
            SupplyPlanningAreaID: sel.siteID,
            MaterialInternalID: sel._data.MaterialInternalID,
            ProcurementMethodCode: sel._data.ProcurementMethodCode,
            ProductionPlanningOrderID: "",
            PurchaseRequestID: purchaseRequestID
          };
        });

        return postProductionPlanningMappingProcess({
          items: mappingArray,
          dispatch: dispatch
        });

        // return bsg_productionplanningmappingset
        // .postProductionPlanningMappingSet(
        //   {
        //     payload: {
        //       ProductionPlanningMapping: mappingArray
        //     },
        //     dispatch: dispatch
        //   }
        // );
      })
      .then(() => {
        return callback
          ? callback.afterSucceed(
              true,
              `구매요청 ${purchaseRequestID}이(가) 생성되었습니다.`
            )
          : "";
      })
      .catch(error => {
        console.error(error);
        return callback ? callback.afterFailed(false, error) : "";
      });
  };
};

/**
 * Store에있는 모든 생산계획 관련 데이터 삭제
 * @param {*} params
 * @param {*} callback
 */

export const clearSalesOrderAndBOM = (params = {}, callback) => {
  return (dispatch, state) => {
    dispatch({
      type: actions.PP_PLAN_CLEAR_SALES_ORDER_ITEMS_AND_BOM,
      payload: {}
    });

    return callback ? callback.afterSucceed(true, undefined) : "";
  };
};

/**
 * Store에 있는 생산계획 BOM 데이터 삭제
 * @param {*} params
 * @param {*} callback
 */

export const clearBOM = (params = {}, callback) => {
  return (dispatch, state) => {
    dispatch({
      type: actions.PP_PLAN_CLEAR_BOM,
      payload: {}
    });

    return callback ? callback.afterSucceed(true, undefined) : "";
  };
};

/**
 * 구매요청 생성 SOAP
 * @param {*} params
 */

// eslint-disable-next-line id-length
const postPurchaseRequestRequestProcess = async params => {
  if (!params.items) {
    return new Promise((resolve, reject) => {
      reject("생성가능한 생산제안 항목이 없습니다.");
    });
  }

  return newProcess("postPurchaseRequestRequestProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;
          const requester = getState().user.currentUser.employee || "";
          if (!requester)
            throw new Error("구매요청자 정보를 조회할 수 없습니다.");
          const { items, salesOrderID } = params;
          const item_payload = items.map(item => {
            return {
              actionCode: "01",
              ProductKeyItem: {
                ProductTypeCode: "1",
                ProductIdentifierTypeCode: "1",
                ProductID: item.productID
              },
              ProductDescription: {
                languageCode: "KO",
                _value_1: item.productDescription.substring(0, 40)
              },
              TypeCode: "18",

              // OpenQuantity: {
              //   unitCode: "KGM",
              //   _value_1: "1"
              // },

              Quantity: {
                unitCode: item.quantityUnit,
                _value_1: item.requestQuantity
              },
              ...((price = item.price) => {
                if (price) {
                  return {
                    ListUnitPrice: {
                      Amount: {
                        currencyCode: item.currencyCode,
                        _value_1: price
                      },
                      BaseQuantity: {
                        unitCode: item.quantityUnit,
                        _value_1: "1"
                      }
                    }
                  };
                }

                return;
              })(),
              ...((fixed_supplier = item.fixed_supplier) => {
                if (fixed_supplier) {
                  return {
                    SupplierIDParty: {
                      actionCode: "01",
                      PartyID: fixed_supplier
                    }
                  };
                }

                return;
              })(),
              CompanyIDParty: {
                actionCode: "01",
                PartyID: item.companyID
              },
              ...(() => {
                if (requester) {
                  return {
                    RequesterIDParty: {
                      actionCode: "01",
                      PartyID: requester.id
                    }
                  };
                }
                return;
              })(),
              ShipToLocationID: item.siteID,
              directMaterialIndicator: "true",
              DeliveryPeriod: {
                StartDateTime: {
                  timeZoneCode: "UTC+9",
                  _value_1: `${moment(item.purchaseConfirmDate).format(
                    "YYYY-MM-DDT00:00:00.0000000"
                  )}Z`
                }
              },
              SOID: salesOrderID || ""
            };
          });

          const api_params = {
            pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
            wsdlFile: {
              filename: `${user.pid}_ManagePurchaseRequestIn`,
              keyPrefix: "byd-wsdl/"
            },
            operationName: "MaintainBundle",
            "sap-language": "ko",
            wsdlParams: {
              BasicMessageHeader: {},
              PurchaseRequestMaintainBundle: {
                actionCode: "01",
                Item: item_payload
              }
            },
            requestXmlRaw: false
          };

          return {
            version: "20190625",
            action: "requestsoap",
            subAction: "postPurchaseRequestRequestProcess",
            description: "",
            httpMethod: "POST",
            headers: {},
            "body-json": api_params
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const results = jsonResponse.body;

          return [true, results];
        };
      }
    })
    .start();
};

/**
 * 세일즈오더 검색 SOAP
 * @param {object} params
 */

const getSalesOrderByIDProcess = params => {
  return newProcess("getSalesOrderByIDProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;

          const item_payload = {
            SalesOrderSelectionByElements: {
              SelectionByID: {
                InclusionExclusionCode: "I",
                IntervalBoundaryTypeCode: "1",
                LowerBoundaryID: params.keyword
              }
            },
            ProcessingConditions: { QueryHitsUnlimitedIndicator: "true" }
          };

          const api_params = {
            pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
            wsdlFile: {
              filename: `${user.pid}_QuerySalesOrderIn`,
              keyPrefix: "byd-wsdl/"
            },
            operationName: "FindByElements",
            "sap-language": "ko",
            wsdlParams: item_payload,
            requestXmlRaw: false
          };

          return {
            version: "20190625",
            action: "requestsoap",
            subAction: "getSalesOrderByIDProcess",
            description: "",
            httpMethod: "POST",
            headers: {},
            "body-json": api_params
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const results = jsonResponse.body;

          return [true, results];
        };
      }
    })
    .start();
};

export const postProductionPlanningMappingProcess = params => {
  const { items, dispatch } = params;

  if (items.length <= 0) {
    return new Promise(resolve => resolve());
  }

  const getToken = () => {
    return fetchToken(
      dispatch(
        convURL.custom("bsg_productionplanningorder/ProductionOrderCollection")
      )
    )();
  };

  const request = params => {
    return newProcess("postProductionPlanningMappingProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "postProductionPlanningMappingProcess",
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  [
                    "bsg_productionplanningmapping",
                    "ProductionPlanningMappingCollection"
                  ].join("/")
                )
              ),
              body: params.body
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return Promise.all(
          params.items.map(item => {
            return request({ body: item });
          })
        );
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * BOM 요청 ODATA
 * @param {object} params
 */

export const postProductionBomViewRequestProcess = params => {
  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom("bsg_productionplanningorder/ProductionOrderCollection")
      )
    )();
  };

  const request = params => {
    return newProcess("postProductionBomViewRequestProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "postProductionBomViewRequestProcess",
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionbomview/ProductionBillOfMaterialViewCollection"
                )
              ),
              body: params.body
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(() => {
        return request(params);
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * BOM 조회 ODATA
 * @param {object} params
 */

export const getProductionBomViewRequestProcess = params => {
  return newProcess("getProductionBomViewRequestProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          return {
            version: "20190625",
            action: "requestodata",
            subAction: "getProductionBomViewRequestProcess",
            description: "",
            httpMethod: "GET",
            headers: {},
            url: dispatch(
              convURL.custom(
                "bsg_productionbomview/ProductionBillOfMaterialViewCollection",
                {
                  $expand: [
                    "Product",
                    "Item",
                    "Item/PurchaseRequest",
                    "Item/ProductionPlanningOrder"
                  ].join(","),
                  $filter: [escape(`ObjectID eq '${params.objectID}'`)]
                }
              )
            )
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const body = jsonResponse.body;
          const results = body.d.results;

          return [true, results];
        };
      }
    })
    .start();
};

const getProductAvailableStockProcess = params => {
  params.dataArr = params.dataArr ? params.dataArr : [];
  params.count = params.count ? params.count : 0;

  if (params.productIDs.length <= 0) {
    return new Promise(resolve =>
      resolve({
        data: {
          jsonResponse: {
            body: {
              d: {
                results: []
              }
            }
          }
        }
      })
    );
  }

  const request = () => {
    const { count, productIDs, dataArr } = params;
    return newProcess("getProductAvailableStockProcess", {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const report = extMap(user.pid).reports
              .bsg_material_supply_and_demand_report;
            const url = report.url;
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "getProductAvailableStockProcess",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.reportANA(url, {
                  $select: [
                    "CPRODUCT",
                    "KCAVAILABLE_STK",
                    "CPLANNING_AREA",
                    "KCTOTL_SUPPLY",
                    "KCTOTL_DMND"
                  ],
                  $filter: [
                    productIDs
                      .map(item => {
                        return `(CPRODUCT eq '${item}')`;
                      })
                      .filter(Boolean)
                      .join(" or ")
                  ],
                  ...(() => {
                    if (count && count > 0) {
                      return {
                        $skip: dataArr.length
                      };
                    }
                  })()
                })
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return request()
    .then(res => {
      const response = res.data.jsonResponse.body.d.results;
      const count = res.data.jsonResponse.body.d.__count;

      const data = [...params.dataArr, ...response];
      if (data.length >= count || response.length <= 0) {
        return new Promise((resolve, reject) => {
          resolve({
            data: {
              jsonResponse: {
                body: {
                  d: {
                    results: data
                  }
                }
              }
            }
          });
        });
      } else {
        return getProductAvailableStockProcess({
          dataArr: data,
          productIDs: params.productIDs,
          count: count
        });
      }
    })
    .catch(error => {
      throw new Error(error);
    });
};

/**
 * 생산제안 생성 SOAP
 * @param {*} params
 */

// eslint-disable-next-line id-length
const postProductionProposalRequestProcess = params => {
  if (!params.items) {
    return new Promise((resolve, reject) => {
      reject("생성가능한 생산제안 항목이 없습니다.");
    });
  }

  return newProcess("postProductionProposalRequestProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;
          const { items } = params;
          const item_payload = items.map((item, index) => {
            return {
              SequenceNumber: index + 1,
              SupplyPlanningAreaID: item.siteID,
              MaterialID: item.productID,
              MaterialAvailabilityDateTime: {
                timeZoneCode: "UTC+9",
                _value_1: `${moment(item.productionStartDate).format(
                  "YYYY-MM-DDT09:00:00.0000000"
                )}Z`
              },
              Quantity: {
                unitCode: item.quantityUnit,
                _value_1: item.requestQuantity
              },
              QuantityTypeCode: UOM(item.quantityUnit),
              SOID_01: item.salesOrderID || "",
              ST_NUM_1: item.stNum || "", // 제품 표준서 번호
              BOM_FIXED_DATE_1: item.bomFixedDate, // 최종 처방 확정일
              BOM_VERSION_1: item.bomVersion, // 처방 Rev
              CUSTOMER_NAME: item.customerName // 거래처명
            };
          });

          const api_params = {
            pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
            wsdlFile: {
              filename: `${user.pid}_ManageProductionProposalIn`,
              keyPrefix: "byd-wsdl/"
            },
            operationName: "CreateBundle",
            "sap-language": "ko",
            wsdlParams: {
              CreateProductionPlanningOrder: item_payload
            },
            requestXmlRaw: false
          };

          return {
            version: "20190625",
            action: "requestsoap",
            subAction: "postProductionProposalRequestProcess",
            description: "",
            httpMethod: "POST",
            headers: {},
            "body-json": api_params
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const results = jsonResponse.body;

          return [true, results];
        };
      }
    })
    .start();
};

/**
 * 생산제안 수정 ODATA (SOAP 에서 필드를 제공하지 않아서 추가로 요청함)
 * @param {*} params
 */

// eslint-disable-next-line id-length
const patchProductionProposalRequestProcess = params => {
  return newProcess("patchProductionProposalRequestProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        const { items } = params;
        const body = {
          ProductionSchedule: Array.from(
            { length: items.length },
            (val, idx) => {
              if (items[idx].productionProposalID) {
                return {
                  ProductionPlanningOrderID: items[idx].productionProposalID,
                  PlannedStartDate: `/Date(${moment(
                    items[idx].productionStartDate,
                    "YYYY-MM-DD"
                  )
                    .add(15, "hours")
                    .valueOf()})/`
                };
              }

              return undefined;
            }
          ).filter(Boolean)
        };

        return (dispatch, getState) => {
          return {
            version: "20190625",
            action: "requestodata",
            subAction: "patchProductionProposalRequestProcess",
            description: "",
            httpMethod: "POST",
            headers: {},
            url: dispatch(
              convURL.custom(
                [
                  "bsg_productionplanningcontrol",
                  "ProductionPlanningControlCollection"
                ].join("/")
              )
            ),
            body: body
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const body = jsonResponse.body;
          const results = body.d.results;

          return [true, results];
        };
      }
    })
    .start();
};

const patchSalesOrderItemProcess = params => {
  if (!params.items || params.items.length <= 0 || !params.salesOrderID) {
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  }

  return newProcess("patchSalesOrderItemProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ params }) => {
        return (dispatch, getState) => {
          const user = getState().user.currentUser;
          const { items, salesOrderID } = params;

          const item_payload = {
            BasicMessageHeader: "",
            SalesOrder: {
              ID: salesOrderID,
              Item: items.map(item => {
                return {
                  ID: item.num,
                  ShipFromItemLocation: { LocationID: item.siteID }
                };
              })
            }
          };

          const api_params = {
            pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
            wsdlFile: {
              filename: `${user.pid}_ManageSalesOrderIn`,
              keyPrefix: "byd-wsdl/"
            },
            operationName: "MaintainBundle",
            "sap-language": "ko",
            wsdlParams: item_payload,
            requestXmlRaw: false
          };

          return {
            version: "20190625",
            action: "requestsoap",
            subAction: "patchSalesOrderItemProcess",
            description: "",
            httpMethod: "POST",
            headers: {},
            "body-json": api_params
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          const results = jsonResponse.body;

          return [true, results];
        };
      }
    })
    .start();
};
