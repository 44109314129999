/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview QueryMaterialIn SOAP Service
 * @description QueryMaterialIn SOAP 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 * @see https://help.sap.com/doc/a2b4c7ef61d64397b2b1d052082a8a08/1905/en-US/PSM_ISI_R_II_QUERY_MAT_IN.html
 */

import { newProcess } from "ws-process";

const serviceName = "QueryMaterialIn";

/**
 * 자재 검색
 * @param {Object} params
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise}
 */

export const findByElements = (params = {}, cb) => {
  const operationName = "FindByElements";

  // Empty Object Check

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = params => {
    return newProcess(`${serviceName}_${operationName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const { internalIDs, searchKeyword, lastReturnedObjectID } = params;

            const item_payload = {
              MaterialSelectionByElements: {
                SelectionByInternalID: internalIDs.map(item => {
                  return {
                    InclusionExclusionCode: "I",
                    IntervalBoundaryTypeCode: "1",
                    LowerBoundaryInternalID: item
                  };
                }),
                SelectionBySearchText: searchKeyword
              },
              ProcessingConditions: {
                QueryHitsUnlimitedIndicator: "false",
                ...(() => {
                  if (lastReturnedObjectID) {
                    return { LastReturnedObjectID: lastReturnedObjectID };
                  }
                })()
              },
              ...(() => {
                if (params.excludeAttachment) {
                  return {
                    RequestedElements: {
                      materialTransmissionRequestCode: "1",
                      Material: {
                        attachmentFolderTransmissionRequestCode: "4"
                      }
                    }
                  };
                } else return {};
              })()
            };

            const api_params = {
              pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
              wsdlFile: {
                filename: `${user.pid}_${serviceName}`,
                keyPrefix: "byd-wsdl/"
              },
              operationName: `${operationName}`,
              "sap-language": "ko",
              wsdlParams: item_payload,
              requestXmlRaw: false
            };

            return {
              version: "20190625",
              action: "requestsoap",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              "body-json": api_params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start();
  };

  // SOAP Request Block

  return new Promise(async (resolve, reject) => {
    let moreHitsAvailableIndicator = true;
    let lastReturnedObjectID = "";
    let returnData = [];

    try {
      while (moreHitsAvailableIndicator) {
        moreHitsAvailableIndicator = false;

        const response = await request({
          ...params,
          ...{ lastReturnedObjectID: lastReturnedObjectID }
        });
        const processingConditions =
          response.data.jsonResponse.ProcessingConditions;
        const responseValue = response.data.jsonResponse.Material;

        returnData = [...responseValue, ...returnData];

        if (processingConditions) {
          lastReturnedObjectID = processingConditions.LastReturnedObjectID;
          moreHitsAvailableIndicator = processingConditions.MoreHitsAvailableIndicator
            ? processingConditions.MoreHitsAvailableIndicator
            : false;
        }
      }

      resolve({
        data: {
          jsonResponse: {
            Material: returnData
          }
        }
      });
    } catch (error) {
      reject(error);
      return cb && cb.afterFailed ? cb.afterFailed(false, error) : "";
    }

    // request()
    //   .then(res => {
    //     resolve(res);
    //     return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
    //   })
    //   .catch(err => {
    //     reject(err);
    //     return cb && cb.afterFailed ? cb.afterFailed(false, err) : "";
    //   });
  });
};

export default {
  findByElements
};
