import React, { Component } from "react";
import { Timer, VBox, HBox } from "components/ui5_react";

const refTimer = React.createRef();
class Input extends Component {
  onKeyPress = event => {
    const { onSubmit } = this.props;
    console.log(event.key);
    if (event.key === "Enter") {
      onSubmit && onSubmit(this._inputTarget);
    }
  };

  onFocus = event => {
    try {
      event.target.setSelectionRange(0, event.target.valuevent.length);
    } catch (error) {
      try {
        event.target.select();
      } catch (error) {}
    }
  };

  state = {
    run_timer: false
  };

  timerDuration = 1000; // 1000ms = 1s

  setTimer = (callback, target, duration) => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.setState({ run_timer: false });
    }
    this.setState({ run_timer: true });
    this.timer = setTimeout(() => {
      this.setState({ run_timer: false });
      const { value } = this.props;
      const emptyReg = /^[ ]*$/;

      // timer ends
      value && !emptyReg.test(value) && callback && callback(target);
    }, duration);

    //    }, this.timerDuration);
  };

  render() {
    const {
      type,
      id,
      value,
      label,
      asText,
      has_info,
      placeholder,
      activateTimerOnChange = true,
      onTimerEnds,
      dataInfo,
      style,
      valueState,
      readonly,
      timer,
      disabled,
      showConfirm
    } = this.props;

    const { run_timer } = this.state;

    return (
      <VBox
        className="react-input input-container"
        {...(style && { style: { ...style } })}
      >
        {label && (
          <HBox className="input-line">
            {label && (
              <label
                className={asText && "no_timer"}
                htmlFor={id}
                tooltip={label}
              >
                {label}:
              </label>
            )}
            {run_timer &&
              document.getElementById(id) === document.activeElement && (
                <Timer
                  ref={refTimer}
                  label={label}
                  has_info={has_info}
                  // duration={this.timerDuration}

                  duration={timer}
                />
              )}
          </HBox>
        )}
        <HBox
          className="input-line input"
          {...(dataInfo !== undefined && { "data-info": dataInfo })}
        >
          <input
            type={type || "text"}
            placeholder={placeholder}
            {...(asText && {
              readOnly: "readonly",
              disabled: "disabled"
            })}
            {...(readonly && type === "checkbox" && { disabled: "disabled" })}
            {...(readonly && type !== "checkbox" && { readOnly: "readonly" })}
            name={id}
            id={id}
            {...{
              value: value || "",
              title: typeof value === "boolean" ? "" : value
            }}
            {...(type === "checkbox" && { checked: !!value })}
            onChange={event => {
              this._inputTarget = event.target;
              const regLeadingSpaces = /^[ ]+/;
              event.target.value = event.target.value.replace(
                regLeadingSpaces,
                ""
              );
              this.props.onChange(event);
              activateTimerOnChange &&
                event.target.value !== "" &&
                this.setTimer(
                  (pTarget, pTimer) => {
                    if (showConfirm) {
                      const rc = window.confirm("다음 입력창으로 진행할까요?");
                      if (rc) {
                        onTimerEnds && onTimerEnds(pTarget, pTimer);
                      } else {
                        return;
                      }
                    } else {
                      onTimerEnds && onTimerEnds(pTarget, pTimer);
                    }
                  },
                  event.target,
                  timer
                );
            }}
            onKeyPress={this.onKeyPress}
            onFocus={this.onFocus}
            style={{
              ...(valueState && {
                color: valueState.color,
                fontWeight: "bold"
              }),
              ...style
            }}
            disabled={disabled}
          />
          {has_info && <span className="btn-info">i</span>}
        </HBox>
      </VBox>
    );
  }
}

export default React.forwardRef((props, ref) => <Input {...props} ref={ref} />);
