import React, { Component } from "react";

class Timer extends Component {
  state = {
    gage: 0
  };

  times = 20;

  componentDidMount() {
    this.timer = setInterval(() => {
      const { gage } = this.state;
      if (gage === 100) {
        clearInterval(this.timer);
        return;
      }
      this.setState({ gage: gage + 100 / this.times });
    }, this.props.duration / this.times);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.gage === prevState.gage && this.state.gage > 0) {
      this.setState({ gage: 0 });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    const { label, has_info } = this.props;
    return (
      <p
        className={`react timer ${label ? "" : "no_label"} ${
          has_info ? "" : "no_info"
        }`}
        style={{ "--p": `${this.state.gage}%` }}
      />
    );
  }
}

export default React.forwardRef((props, ref) => <Timer {...props} ref={ref} />);
