import store from "@bsgp/lib-api/store";

import user from "reducers/user";
// import api from "reducers/api";
// import { SIGN_OUT } from "actions/user";

// import vendorPortal from "reducers/vendor_portal/vp_reducers";
import productionPlan from "reducers/production_plan/pp_reducers";
import transaction from "reducers/transaction";
import ui5 from "reducers/ui5";

import { init } from "ws-process";
import {
  beforeAllProcesses,
  afterAllProcesses,
  validateResponse,
  beforeEachProcess,
  afterEachProcess
} from "lib/WebSocketInterceptor";

// const initializeStateWhenSignout = reducer => (state, action) => {
//   if (action.type === SIGN_OUT) {
//     state = undefined;
//   }
//   return reducer(state, action);
// };

const reducers = {
  user,
  // vendorPortal,
  productionPlan,
  transaction,
  ui5
};
Object.keys(reducers).forEach(key => {
  store.injectReducer(key, reducers[key]);
});

const stageName = process.env.REACT_APP_API_STAGE_NAME;
const wsURL = `${process.env.REACT_APP_API_WS_PROXY_URL}/${stageName}`;

console.debug("wsURL:", wsURL);

init({
  store,
  wsURL,
  stage: stageName,
  beforeAllProcesses,
  beforeEachProcess,
  afterEachProcess,
  afterAllProcesses,
  validateResponse
});

export default store;
