/**
 * @overview 생산계획일정 프로젝트의 일정관리 리듀서.
 * @author Hanmuk OH
 */

import { actions } from "actions/production_plan/pp_schedule";

const initialState = {
  productionScheduleQuery: {},
  productionSchedule: [],
  supplyPlanningArea: [],
  productionResources: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return { ...state };
};

const actionHandlers = {
  [actions.PP_SCHEDULE_GET_SUPPLY_PLANNING_AREAS](state, payload) {
    return { ...state, supplyPlanningArea: payload };
  },
  [actions.PP_SCHEDULE_GET_PRODUCTION_PROPOSALS_AND_ORDERS](state, payload) {
    const resources = state.productionResources;
    payload.forEach((item, index) => {
      item.num = index + 1;

      const fItems = resources.filter(sItem => sItem.siteID === item.siteID);
      if (fItems.length > 0) {
        item.resourceList = fItems;
      }
    });

    return {
      ...state,
      productionSchedule: payload
    };
  },
  [actions.PP_SCHEDULE_GET_PRODUCTION_SCHEDULE_QUERY](state, payload) {
    return { ...state, productionScheduleQuery: payload };
  },
  [actions.PP_SCHEDULE_CLEAR_PRODUCTION_PROPOSALS_AND_ORDER](state, payload) {
    return { ...state, productionSchedule: [] };
  },
  [actions.PP_SCHEDULE_GET_PRODUCTION_RESOURCES](state, payload = []) {
    payload = payload
      .map(item => {
        if (item.CRES_UUID !== item.TRES_UUID) {
          return {
            resourceID: item.CRES_UUID,
            resourceText: item.TRES_UUID,
            siteID: item.CSITE_ID_CONTENT
          };
        }
        return undefined;
      })
      .filter(Boolean);

    return { ...state, productionResources: payload };
  }
};
