/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview ManageIdentifiedStocksIn SOAP Service
 * @description ManageIdentifiedStocksIn SOAP 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 * @see https://help.sap.com/doc/a2b4c7ef61d64397b2b1d052082a8a08/1905/en-US/PSM_ISI_R_II_MANAGE_ISTOCK_IN.html
 */

import { newProcess } from "ws-process";
import moment from "moment";

const serviceName = "ManageIdentifiedStocksIn";

/**
 * 동종재고를 생성하거나 수정합니다.
 * @param {Object} params 샘플 참조
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise}
 * @example
 * maintainBundle(
 * {
 *   items: [
 *   {"SOAP 요청 항목별 Payload"}
 *   ],
 *   payload: {"SOAP 요청 Payload, 링크참조"}
 * },
 * {
 *   afterSucceed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   },
 *   afterFailed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   }
 * });
 */

export const maintainBundle = (params = {}, cb) => {
  const operationName = "MaintainBundle";

  // Empty Object Check

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  // SOAP Request Block

  return new Promise((resolve, reject) => {
    newProcess(`${serviceName}_${operationName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const { items, payload } = params;

            const item_payload = payload
              ? payload
              : items.map((item, index) => {
                  return {
                    actionCode: "01",
                    activateIdentifiedStockIndicator: "true",
                    IdentifiedStockID: item.lotNumber,
                    ProductID: item.item,
                    ...(() => {
                      if (item.lotStartDate) {
                        return {
                          ProductionDateTime: `${moment(
                            item.lotStartDate
                          ).format("YYYY-MM-DDT00:00:00.0000000")}Z`
                        };
                      }
                      return;
                    })(),
                    ...(() => {
                      if (item.lotEndDate) {
                        return {
                          ExpirationDateTime: `${moment(item.lotEndDate).format(
                            "YYYY-MM-DDT00:00:00.0000000"
                          )}Z`
                        };
                      }
                      return;
                    })(),
                    ExternalIdentifiedStockID: item.lotNumberExternal,
                    SupplierID: {
                      PartyTypeCode: "147",
                      PartyID: item.vendorID
                    },
                    TextCollection: {
                      // eslint-disable-next-line id-length
                      TextListCompleteTransmissionIndicator: "true",
                      ActionCode: "01",
                      Text: {
                        ActionCode: "01",
                        TypeCode: "10011",
                        TextContent: {
                          ActionCode: "01",
                          Text: {
                            languageCode: "KO",
                            _value_1: "Created By BSG SUPPORT"
                          }
                        }
                      }
                    }
                  };
                });

            const api_params = {
              pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
              wsdlFile: {
                filename: `${user.pid}_${serviceName}`,
                keyPrefix: "byd-wsdl/"
              },
              operationName: `${operationName}`,
              wsdlParams: item_payload,
              requestXmlRaw: false
            };

            return {
              version: "20190625",
              action: "requestsoap",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              "body-json": api_params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start()
      .then(res => {
        resolve(res);
        return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
      })
      .catch(err => {
        reject(err);
        return cb && cb.afterFailed ? cb.afterFailed(false, err) : "";
      });
  });
};

export default {
  maintainBundle
};
