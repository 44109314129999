import { newProcess, fetchToken } from "ws-process";
import { convURL } from "actions/apiV2";
import { sendRequest } from "actions/vendor_portal/service/request";
import { byId } from "ui5-lib-rc";

const serviceName = "bsg_productionorder";

export const getProductionOrders = (params = {}, appIsBusy = true) => {
  const actionName = "getProductionOrders";
  const collectionName = "ProductionOrderCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            params = {
              // $skip: skip ? skip : 0,
              // $top: "200",

              $filter: [
                params.productionOrderID
                  ? `ID eq '${params.productionOrderID}'`
                  : "",
                params.salesOrderID
                  ? `substringof('${params.salesOrderID}', SOID_01_KUT)`
                  : "" // 판매오더 ID
              ]
                .filter(Boolean)
                .join(" and "),
              $expand: ["ProductionOrderMainProductOutput"],
              $inlinecount: "allpages"
            };
          }

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            // 판매오더로 조회시 substringof를 사용하기 때문에 잘못된 데이터 조회될 수 있음. 잘못된 데이터 필터링해준다.
            // 쿼리와 조회된 값은 판매오더ID가 일치하거나 판매오더ID-NNN 형태로 일치해야 함
            // ex) 1조회했는데 41까지 조회되는 경우

            if (params.searchType && parseInt(params.searchType) === 2) {
              if (results.length < 1) return;
              const querySalesOrderID = params.salesOrderID;
              const filteredItems = results.filter(item => {
                let itemSalesOrderID;

                if (!item.SOID_01_KUT) return false;

                if (item.SOID_01_KUT.includes("-")) {
                  itemSalesOrderID = item.SOID_01_KUT.split("-")[0];
                } else {
                  itemSalesOrderID = item.SOID_01_KUT;
                }
                return itemSalesOrderID === querySalesOrderID;
              });
              jsonResponse.body.d.results = filteredItems;
              jsonResponse.body.d.__count = filteredItems.length;
            }
            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await sendRequest(request, params);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchProductionOrder = (
  params = {},
  options = { appIsBusy: true, hasToken: false }
) => {
  const actionName = "patchProductionOrders";
  const collectionName = "ProductionOrderCollection";
  const objectUUID = params.objectUUID;

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("업데이트 조건이 없습니다.");
    });
  }

  if (!objectUUID) {
    return new Promise((resolve, reject) => {
      reject("업데이트할 생산오더의 UUID가 누락되었습니다.");
    });
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(convURL.custom(`${serviceName}/${collectionName}`))
    )();
  };

  const request = params => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: options.appIsBusy
    })
      .newRequest({
        requestHandler: ({ params }) => {
          const body = {
            ObjectID: params.objectUUID,
            BOM_VERSION_1_KUT: params.bomVersion || "",
            BOM_FIXED_DATE_1_KUT: params.bomFixedDate || "",
            CUSTOMER_NAME_KUT: params.customerName || "",
            ST_NUM_1_KUT: params.stNum || ""
          };

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: actionName,
              description: "",
              httpMethod: "PATCH",
              headers: {},
              url: dispatch(
                convURL.custom(
                  `${serviceName}/${collectionName}('${objectUUID}')`
                )
              ),
              body: body
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            return [true, jsonResponse];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      byId("app").setBusy(true);
      if (!options.hasToken) {
        await getToken();
      }
      const response = await request(params);
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      byId("app").setBusy(false);
    }
  });
};

export const getInputAndOutputProduct = (params = {}, appIsBusy = true) => {
  const actionName = "getInputAndOutputProduct";
  const collectionName = "ProductionLotCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $filter: [
                (() => {
                  if (!params.productionOrderID) return "";
                  if (Array.isArray(params.productionOrderID)) {
                    return params.productionOrderID
                      .map(id => `(ID eq '${id}')`)
                      .join(" or ");
                  }
                  return `ID eq '${params.productionOrderID}'`;
                })()
              ]
                .filter(Boolean)
                .join(" and "),
              $expand: [
                "ProductionLotMaterialInput",
                "ProductionLotMaterialOutput"
              ],
              $inlinecount: "allpages"
            };
          }

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;
            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await sendRequest(request, params);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  getProductionOrders
};
