import { newProcess } from "ws-process";

const serviceName = "QueryProductionBillofMaterialsIn";

export const queryProductionBOMByElements = (params = {}, cb) => {
  const operationName = "QueryProductionBillOfMaterialByElements";

  // Empty Object Check

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = params => {
    return newProcess(`${serviceName}_${operationName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const { materialIDs, lastReturnedObjectID } = params;

            const item_payload = {
              ProductionBillOfMaterials: {
                // eslint-disable-next-line
                SelectionByOutputProductID: materialIDs.map(item => {
                  return {
                    InclusionExclusionCode: "I",
                    IntervalBoundaryTypeCode: "1",
                    LowerBoundaryIdentifier: item
                  };
                }),
                SelectionByConsistencyStatusCode: {
                  InclusionExclusionCode: "I",
                  IntervalBoundaryTypeCode: "1",
                  LowerBoundaryCode: "3" // 일치
                }
              },
              ProcessingConditions: {
                QueryHitsUnlimitedIndicator: "false",
                ...(() => {
                  if (lastReturnedObjectID) {
                    return { LastReturnedObjectID: lastReturnedObjectID };
                  }
                })()
              }
            };

            const api_params = {
              pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
              wsdlFile: {
                filename: `${user.pid}_${serviceName}`,
                keyPrefix: "byd-wsdl/"
              },
              operationName: `${operationName}`,
              "sap-language": "ko",
              wsdlParams: item_payload,
              requestXmlRaw: false
            };

            return {
              version: "20190625",
              action: "requestsoap",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              "body-json": api_params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start();
  };

  // SOAP Request Block

  return new Promise(async (resolve, reject) => {
    let moreHitsAvailableIndicator = true;
    let lastReturnedObjectID = "";
    let returnData = [];

    try {
      while (moreHitsAvailableIndicator) {
        moreHitsAvailableIndicator = false;

        const response = await request({
          ...params,
          ...{ lastReturnedObjectID: lastReturnedObjectID }
        });
        const processingConditions =
          response.data.jsonResponse.ProcessingConditions;
        const responseValue =
          response.data.jsonResponse.ProductionBillOfMaterials;

        returnData = [...responseValue, ...returnData];

        if (processingConditions) {
          lastReturnedObjectID = processingConditions.LastReturnedObjectID;
          // prettier-ignore
          moreHitsAvailableIndicator = processingConditions
            .MoreHitsAvailableIndicator
            ? processingConditions.MoreHitsAvailableIndicator
            : false;
        }
      }

      resolve({
        data: {
          jsonResponse: {
            ProductionBillOfMaterials: returnData
          }
        }
      });
    } catch (error) {
      reject(error);
      return cb && cb.afterFailed ? cb.afterFailed(false, error) : "";
    }

    // request()
    //   .then(res => {
    //     resolve(res);
    //     return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
    //   })
    //   .catch(err => {
    //     reject(err);
    //     return cb && cb.afterFailed ? cb.afterFailed(false, err) : "";
    //   });
  });
};

export default {
  queryProductionBOMByElements
};
