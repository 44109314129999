import { newProcess } from "ws-process";
import { convURL } from "actions/apiV2";
import {
  sendRequest,
  chunkAndSendRequest
} from "actions/vendor_portal/service/request";
import { byId } from "ui5-lib-rc";

const serviceName = "bsg_salesorder";

export const getSalesOrders = (params = {}, cb) => {
  const actionName = "getSalesOrders";
  const collectionName = "SalesOrderCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess("getSalesOrders", {
      appIsBusy: false
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $inlinecount: "allpages",
              $expand: params.expand || ["SalesOrderSalesItem"],
              $filter: [
                (() => {
                  if (!params.salesOrderID) return "";
                  if (Array.isArray(params.salesOrderID)) {
                    return `(${params.salesOrderID
                      .map(id => `(ID eq '${id}')`)
                      .join(" or ")})`;
                  } else {
                    return `(ID eq '${params.salesOrderID}')`;
                  }
                })()
              ]
                .filter(Boolean)
                .join(" and ")
            };
          }
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: actionName,
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      byId("app").setBusy(true);
      let response;
      if (params.salesOrderID && params.salesOrderID.length > 0) {
        response = await chunkAndSendRequest(request, params, "salesOrderID");
      } else {
        response = await sendRequest(request, params);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      byId("app").setBusy(false);
    }
  });
};

export const getBuyersBySalesOrderID = (params = {}, cb) => {
  const actionName = "getBuyers";
  const collectionName = "SalesOrderCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: false
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $inlinecount: "allpages",
              $expand: ["SalesOrderBuyerParty/SalesOrderBuyerPartyName"],
              $filter: [
                (() => {
                  if (!params.salesOrderID) {
                    return "";
                  } else if (Array.isArray(params.salesOrderID)) {
                    return `(${params.salesOrderID
                      .map(id => `(ID eq '${id}')`)
                      .join(" or ")})`;
                  } else {
                    return `(ID eq '${params.salesOrderID}')`;
                  }
                })()
              ]
                .filter(Boolean)
                .join(" and ")
            };

            return (dispatch, getState) => {
              return {
                version: "20190625",
                action: "requestodata",
                subAction: actionName,
                description: "",
                httpMethod: "GET",
                headers: {},
                url: dispatch(
                  convURL.custom(`${serviceName}/${collectionName}`, params)
                )
              };
            };
          }
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      byId("app").setBusy(true);
      let response;
      if (params.salesOrderID && params.salesOrderID.length > 20) {
        response = await chunkAndSendRequest(request, params, "salesOrderID");
      } else {
        response = await sendRequest(request, params);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      byId("app").setBusy(false);
    }
  });
};

export default {
  getSalesOrders
};
