import moment from "moment";
import { convURL } from "actions/apiV2";
import { newProcess } from "ws-process";

export const stockPrefix = () => {
  const prefixYear = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const prefixMonth = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L"
  ];

  const currentDate = moment().format("YY-MM");
  const yymm = currentDate.split("-");

  return `${prefixYear[yymm[0] % 10]}${prefixMonth[Number(yymm[1] - 1)]}`;
};

/**
 * Convert intValue to Hexadecimal
 * This pads numbers to four and leading with zero.
 * @param {Number} intValue
 * @return {String}
 */

export const stockNumber = intValue => {
  if (intValue > 65535) {
    // Max number of of four digits Hexadecimal

    throw new Error("stockNumber exceed max value of four digits hexadecimal");
  }

  const hexNumber = intValue.toString(16).toUpperCase();
  return stockPrefix() + ("0000" + hexNumber).substr(-4, 4);
};

export const queryIsExistStockIDProcess = params => {
  return newProcess("queryIsExistStockIDProcess", {
    appIsBusy: true
  })
    .newRequest({
      requestHandler: ({ payload }) => {
        return (dispatch, getState) => {
          // const prefix = `${stockPrefix()}${"*"}`;

          const prefix = `${stockPrefix()}????`;

          return {
            version: "20190625",
            action: "requestodata",
            subAction: "queryIsExistProcess",
            description: "",
            httpMethod: "GET",
            headers: {},
            url: dispatch(
              convURL.custom(
                "bsg_inbounddeliveryrequest/IdentifiedStockCollection",
                {
                  $filter: `(IdentifiedStockID eq '${prefix}')`,
                  $orderby: "IdentifiedStockID desc",
                  $top: 1
                }
              )
            )
          };
        };
      },
      params,
      responseHandler: ({ isLast, jsonResponse }) => {
        return (dispatch, getState) => {
          return [true];
        };
      }
    })
    .start();
};
