/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview bsg_material oData Service
 * @description bsg_material oData 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 */

import { newProcess } from "ws-process";
import { convURL } from "actions/apiV2";
import {
  sendRequest,
  chunkAndSendRequest
} from "actions/vendor_portal/service/request";
import { byId } from "ui5-lib-rc";

const serviceName = "bsg_material";

export const getMaterials = (params = {}, cb) => {
  const actionName = "getMaterials";
  const collectionName = "MaterialCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            const { internalIDs } = params;
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $filter: internalIDs
                .map(item => {
                  return `InternalID eq '${item}'`;
                })
                .join(" or "),
              $inlinecount: "allpages",
              $expand: ["MaterialAvailability", "MaterialAttachmentFolder"]
            };
          }

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            // if (results) {
            //   returnData = [...returnData, ...results];
            // }

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      let response;
      if (params.internalIDs && params.internalIDs.length > 0) {
        response = await chunkAndSendRequest(
          request,
          params,
          "internalIDs",
          25
        );
      } else {
        response = await sendRequest(request, params);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * MaterialID(자재ID)로 생산 가능 사이트 내역을 조회합니다.
 * @param {Object} params {internalIDs : [MaterialID]}
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise} 반환 데이터 형식 =>  data: { jsonResponse: { body: { d: { results: returnData } } } }
 * @example
 * getAvailableProductionSites(
 * {
 *   internalIDs: ["ITEM01","ITEM02","ITEM03"]
 * },
 * {
 *   afterSucceed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   },
 *   afterFailed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   }
 * });
 */

export const getAvailableProductionSites = (params = {}, cb) => {
  const actionName = "getAvailableProductionSites";
  const collectionName = "MaterialCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`${serviceName}_${actionName}`, {
      appIsBusy: false
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            const { internalIDs } = params;
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $filter: internalIDs
                .map(item => {
                  return `InternalID eq '${item}'`;
                })
                .join(" or "),
              $inlinecount: "allpages",
              $expand: params.expand || [
                "MaterialAvailability"
                // "MaterialAttachmentFolder"
              ]
            };
          }

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: "",
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            // if (results) {
            //   returnData = [...returnData, ...results];
            // }

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      byId("app").setBusy(true);
      let response;
      if (params.internalIDs && params.internalIDs.length > 0) {
        response = await chunkAndSendRequest(
          request,
          params,
          "internalIDs",
          10
        );
      } else {
        response = await sendRequest(request, params);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      byId("app").setBusy(false);
    }
  });

  // return new Promise((resolve, reject) => {
  //   return request(params)
  //     .then(() => {
  //       resolve({
  //         data: {
  //           jsonResponse: {
  //             body: {
  //               d: {
  //                 results: returnData
  //               }
  //             }
  //           }
  //         }
  //       });
  //       return cb && cb.afterSucceed ? cb.afterSucceed(true, returnData) : "";
  //     })
  //     .catch(error => {
  //       reject(error);
  //       console.error(error);
  //       return cb && cb.afterFailed ? cb.afterFailed(false, error) : "";
  //     });
  // });
};

export default {
  getMaterials,
  getAvailableProductionSites
};
