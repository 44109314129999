/* eslint-disable max-len */
/* eslint-disable id-length */

/**
 * @overview ManageLogisticsLayoutExecutionViewIn SOAP Service
 * @description ManageLogisticsLayoutExecutionViewIn SOAP 서비스를 이용한 API 호출 모듈
 * @author Hanmuk OH
 * @see https://help.sap.com/doc/a2b4c7ef61d64397b2b1d052082a8a08/1905/en-US/PSM_ISI_R_II_MANAGE_LLEV_IN.html
 */

import { newProcess } from "ws-process";

const serviceName = "ManageLogisticsLayoutExecutionViewIn";

/**
 * 보충태스크를 관리합니다.
 * @param {Object} params 샘플 참조
 * @param {Object} cb optional {afterSucceed : fn(status,message), afterFailed : fn(status,message)}
 * @returns {Promise}
 * @example
 * maintainBundle(
 * {
 *   productionOrderIDs: [
 *   "생산오더ID"
 *   ],
 *   payload: {"SOAP 요청 Payload, 링크참조"}
 * },
 * {
 *   afterSucceed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   },
 *   afterFailed: (status, message) => {
 *     return window.sap.m.MessageToast.show(message);
 *   }
 * });
 */

export const maintainBundle_V1 = (params = {}, cb) => {
  const operationName = "MaintainBundle_V1";

  // Empty Object Check

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  // SOAP Request Block

  return new Promise((resolve, reject) => {
    newProcess(`${serviceName}_${operationName}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            const user = getState().user.currentUser;
            const { productionOrderIDs, payload } = params;

            const item_payload = payload
              ? payload
              : {
                  BasicMessageHeader: {},
                  LogisticsLayoutExecutionView: productionOrderIDs.map(item => {
                    return {
                      ProductionOrderID: item,
                      ProcessType: "1"
                    };
                  })
                };

            const api_params = {
              pkcs12: { p12File: `${user.pid}.p12`, password: "Welcome@123" },
              wsdlFile: {
                filename: `${user.pid}_${serviceName}`,
                keyPrefix: "byd-wsdl/"
              },
              operationName: `${operationName}`,
              wsdlParams: item_payload,
              requestXmlRaw: false
            };

            return {
              version: "20190625",
              action: "requestsoap",
              subAction: "",
              description: "",
              httpMethod: "POST",
              headers: {},
              "body-json": api_params
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const results = jsonResponse.body;

            return [true, results];
          };
        }
      })
      .start()
      .then(res => {
        resolve(res);
        return cb && cb.afterSucceed ? cb.afterSucceed(true, res) : "";
      })
      .catch(err => {
        reject(err);
        return cb && cb.afterFailed ? cb.afterFailed(false, err) : "";
      });
  });
};

export default {
  maintainBundle_V1
};
