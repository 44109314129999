import React, { Component } from "react";

class Button extends Component {
  render() {
    const { label, onClick, barcode, disabled, style } = this.props;
    return (
      <button
        disabled={disabled}
        className="react"
        onClick={onClick}
        style={style}
      >
        <p className="label">{label}</p>
        {barcode && <img alt={label} src={barcode.imgString} />}
      </button>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Button {...props} ref={ref} />
));
