import { newProcess, fetchToken } from "ws-process";
import { convURL } from "actions/apiV2";
import { sendRequest } from "actions/vendor_portal/service/request";
import { byId } from "ui5-lib-rc";

const serviceName = "bsg_productionplanningorder";

/**
 * 생산제안 조회
 * @param {*} params
 */

export const getProductionProposal = (params = {}) => {
  const actionName = "getProductionProposal";
  const collectionName = "ProductionPlanningOrderByProductOutputCollection";

  if (Object.entries(params).length === 0 && params.constructor === Object) {
    return new Promise((resolve, reject) => {
      reject("검색 조건이 없습니다.");
    });
  }

  const request = (params, skip = 0) => {
    return newProcess(`getProductionProposal`, {
      appIsBusy: false
    })
      .newRequest({
        requestHandler: ({ params }) => {
          if (params.payload) {
            params = params.payload;
          } else {
            params = {
              $skip: skip ? skip : 0,
              $top: "200",
              $inlinecount: "allpages",
              $orderby: "StartDateTime asc",
              $expand: params.expand || [],
              $filter: [
                (() => {
                  if (!params.productionProposalID) return "";
                  if (Array.isArray(params.productionProposalID)) {
                    return `(${params.productionProposalID
                      .map(id => `(ProposalID eq '${id}')`)
                      .join(" or ")})`;
                  } else {
                    return `(ProposalID eq '${params.productionProposalID}')`;
                  }
                })()
              ]
                .filter(Boolean)
                .join(" and ")
            };
          }

          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: actionName,
              description: "",
              httpMethod: "GET",
              headers: {},
              url: dispatch(
                convURL.custom(`${serviceName}/${collectionName}`, params)
              )
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            // 판매오더로 조회시 substringof를 사용하기 때문에 잘못된 데이터 조회될 수 있음. 잘못된 데이터 필터링
            // 쿼리와 조회된 값은 판매오더ID가 일치하거나 판매오더ID-NNN 형태로 일치해야 함
            // ex) 1조회했는데 41까지 조회되는 경우

            if (params.searchType && parseInt(params.searchType) === 2) {
              if (results.length < 1) return;
              const querySalesOrderID = params.keyword;
              const filteredItems = results.filter(item => {
                let itemSalesOrderID;

                if (!item.SOID_01_KUT) return false;

                if (item.SOID_01_KUT.includes("-")) {
                  itemSalesOrderID = item.SOID_01_KUT.split("-")[0];
                } else {
                  itemSalesOrderID = item.SOID_01_KUT;
                }
                return itemSalesOrderID === querySalesOrderID;
              });

              jsonResponse.body.d.results = filteredItems;
              jsonResponse.body.d.__count = filteredItems.length;
            }

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise(async (resolve, reject) => {
    try {
      byId("app").setBusy(true);
      const response = await sendRequest(request, params);
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      byId("app").setBusy(false);
    }
  });
};

/**
 * 생산오더 릴리즈 요청 ODATA (StandarodSOAP 생산오더 릴리즈 기능 누락)
 * @param {*} params
 */

export const postReleaseProductionProposal = params => {
  if (params.proposalUUIDs.length <= 0) {
    return new Promise((resolve, reject) =>
      reject("릴리즈할 생산제안이 하나도 없습니다!")
    );
  }

  const getToken = () => {
    return fetchToken(
      params.dispatch(
        convURL.custom(
          [
            "bsg_productionplanningorder",
            "ProductionPlanningOrderByProductOutputCollection"
          ].join("/")
        )
      )
    )();
  };

  const request = proposalUUID => {
    return newProcess(`postReleaseProductionProposalProcess__${proposalUUID}`, {
      appIsBusy: true
    })
      .newRequest({
        requestHandler: ({ params }) => {
          return (dispatch, getState) => {
            return {
              version: "20190625",
              action: "requestodata",
              subAction: [
                "postReleaseProductionProposalProcess__",
                proposalUUID
              ].join(""),
              description: "",
              httpMethod: "POST",
              headers: {},
              url: dispatch(
                convURL.custom(
                  "bsg_productionplanningorder/RequestProduction",
                  {
                    ObjectID: `'${proposalUUID}'`
                  }
                )
              ),
              body: {}
            };
          };
        },
        params,
        responseHandler: ({ isLast, jsonResponse }) => {
          return (dispatch, getState) => {
            const body = jsonResponse.body;
            const results = body.d.results;

            return [true, results];
          };
        }
      })
      .start();
  };

  return new Promise((resolve, reject) => {
    getToken()
      .then(async () => {
        const outputData = [];

        for (const item of params.proposalUUIDs) {
          const response = await request(item);
          outputData.push(response);
        }

        return outputData;

        // return Promise.all(
        //   params.proposalUUIDs.map(item => {
        //     return request(item);
        //   })
        // );
      })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        try {
          const errorMessage = JSON.parse(error.data.body.ResponseError).error
            .message.value;
          reject(errorMessage);
        } catch (tryErr) {
          reject(error);
        }
      });
  });
};
